import React from 'react';
import { Layout } from '../components/Layout';
import { useNavigate, useParams } from 'react-router-dom';
import { confirmEmail } from '../api/confirmEmail';
import { Button } from 'components/Elements';

export const ConfirmEmail = () => {
  const navigate = useNavigate();
  const { code } = useParams();
  const [isLoading, setIsLoading] = React.useState(false);

  const params = new URLSearchParams(window.location.search);

  return (
    <Layout title="Please confirm your Email Address">
      <div className="mt-4 text-center">
        Please click the button below to verify your email address.
      </div>
      <Button
        onClick={async () => {
          setIsLoading(true);
          await confirmEmail({ code });
          setIsLoading(false);
          navigate('/auth/login');
        }}
        isLoading={isLoading}
        className="mt-4 mx-auto"
      >
        Verify Email Address
      </Button>
    </Layout>
  );
};

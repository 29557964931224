import { Button, Dialog, DialogDescription, DialogTitle } from 'components/Elements';
import React from 'react';
import { useLendNow } from '../api/lendNow';
import { Form } from 'components/Form';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'lib/auth';

export function LendNow({ id, amount, term, interest, monthlyPayment }) {
  const [show, setShow] = React.useState(false);
  const [showOpenLend, setShowOpenLend] = React.useState(false);

  const lendNowMutation = useLendNow();

  const navigate = useNavigate();

  const { user } = useAuth();

  const handleClose = () => {
    setShow(false);
    setShowOpenLend(false);
  };

  const handleOpen = () => {
    if (user.role !== 'LENDER') {
      setShowOpenLend(true);
    } else {
      setShow(true);
    }
  };

  return (
    <div>
      <Button size="sm" onClick={handleOpen} variant="inverseSecondary">
        Lend Now
      </Button>
      <Dialog isOpen={show} onClose={handleClose} initialFocus={null}>
        <div className="inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <Form
            id="lend-now"
            onSubmit={async () => {
              await lendNowMutation.mutateAsync({
                data: {
                  id,
                },
              });
              navigate('/app/lend-money/offer-submitted');
            }}
          >
            {({ register, formState, watch, setValue }) => (
              <>
                <DialogTitle className="text-xl text-center font-bold mb-4">Lend Now</DialogTitle>
                <DialogDescription>
                  You are accepting to lend {amount?.toLocaleString()} UGX at an interest of{' '}
                  {interest}% per month. Borrower will repay back UGX{' '}
                  {monthlyPayment?.toLocaleString()} monthly for {term} months.
                </DialogDescription>
                <div className="flex flex-row items-center space-x-2">
                  <Button
                    onClick={handleClose}
                    type="button"
                    className="w-full"
                    variant="inverseSecondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    isLoading={lendNowMutation.isLoading}
                    className="w-full"
                    variant="secondary"
                  >
                    Submit Offer
                  </Button>
                </div>
              </>
            )}
          </Form>
        </div>
      </Dialog>

      <Dialog isOpen={showOpenLend} onClose={handleClose} initialFocus={null}>
        <div className="inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <DialogTitle className="text-xl text-center font-bold mb-4">Become a lender</DialogTitle>
          <DialogDescription>
            You need to create a lender profile before you can lend money.
          </DialogDescription>
          <div className="flex flex-row items-center justify-end space-x-2">
            <Button variant="outlineSecondary" onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button
              variant="secondary"
              onClick={() => navigate('/app/lend-money/complete-lender-profile')}
            >
              Create Profile
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

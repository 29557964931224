import { Button } from 'components/Elements';
import { Form } from 'components/Form';
import React, { useState } from 'react';

export const VehiclePicturesForm = ({ onSuccess, onBack, setSelectedImages }) => {
  const [assetImages, setAssetImages] = useState(Array(8).fill(null));

  const handleImageChange = (index, event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const reader = new FileReader();
        reader.onload = () => {
          const newImages = [...assetImages];
          newImages[index] = reader.result;
          setAssetImages(newImages);
          setSelectedImages(newImages);
        };
        reader.readAsDataURL(file);
      } catch (error) {
        console.log('image upload error', error);
      }
    }
  };

  return (
    <Form
      id="vehicle-pictures"
      onSubmit={async (values) => {
        onSuccess();
      }}
    >
      {({ register, formState, watch, setValue }) => (
        <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
          <div className="mb-4">Pictures</div>
          <div className="mb-2">
            Please ensure the uploaded vehicle images are clear and follow the guidelines on what
            picture angles to take.
          </div>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            {[...Array(12).keys()].map((index) => (
              <div key={index} className="relative">
                <input
                  type="file"
                  id={`image-input-${index}`}
                  accept="image/*"
                  className="hidden"
                  name={`imageinput${index}`}
                  {...register(`imageinput${index}`)}
                  onChange={(event) => {
                    formState.errors[`imageinput${index}`] = '';
                    handleImageChange(index, event);
                    setValue(`imageinput${index}`, 'file set');
                  }}
                />
                <label htmlFor={`image-input-${index}`} className="cursor-pointer">
                  <div className="w-full h-40 bg-gray-200 flex justify-center items-center rounded-lg border border-dashed border-gray-400">
                    {assetImages[index] ? (
                      <img
                        src={assetImages[index]}
                        alt={`Uploaded Image ${index + 1}`}
                        className="max-w-full max-h-full rounded-lg"
                      />
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-12 w-12 text-gray-500"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M13.667 3H6.333c-1.335 0-2.45.935-2.75 2.186l-.003.017-.016.08a3.2 3.2 0 0 0-.08.461V14.75c0 1.333 1.083 2.417 2.417 2.417h7.334c1.334 0 2.417-1.084 2.417-2.418V5.417C16.084 4.083 15 3 13.667 3zM10 6.25a2.75 2.75 0 1 1 0 5.5 2.75 2.75 0 0 1 0-5.5zM8.75 9A1.25 1.25 0 0 1 10 7.75h.002A1.25 1.25 0 0 1 11.25 9 1.25 1.25 0 0 1 10 10.25H9.998A1.25 1.25 0 0 1 8.75 9zM10 11.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5z"
                          clipRule="evenodd"
                        />
                      </svg>
                    )}
                    {formState.errors[`imageinput${index}`]?.message && (
                      <div
                        role="alert"
                        aria-label={formState.errors[`imageinput${index}`].message}
                        className="text-sm font-semibold text-red-500"
                      >
                        {formState.errors[`imageinput${index}`].message}
                      </div>
                    )}
                  </div>
                  <span className="block text-center mt-2 text-gray-500">
                    {index === 0 && 'Front view (show number plate)'}
                    {index === 1 && 'Side view (both sides)'}
                    {index === 2 && 'Back view'}
                    {index === 3 && 'Close-ups of any existing damage or unique features'}
                    {index === 4 && "Driver's seat area"}
                    {index === 5 && 'Passenger seating area'}
                    {index === 6 && 'Dashboard and control panel'}
                    {index === 7 && 'Close-ups of odometer, indicating mileage'}
                    {index === 8 && 'Underside of vehicle'}
                    {index === 9 && 'Close-up of Wheels and Tyres'}
                    {index === 10 && 'Engine'}
                    {index === 11 && 'Logbook'}
                  </span>
                </label>
              </div>
            ))}
          </div>
          <div className="flex flex-row space-x-2 justify-end mt-4">
            <Button
              variant="outline"
              onClick={() => {
                onBack();
              }}
            >
              Back
            </Button>
            <Button type="submit">Next</Button>
          </div>
        </div>
      )}
    </Form>
  );
};

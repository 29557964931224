import clsx from 'clsx';
import * as Emoji from 'quill-emoji';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';
import { FieldWrapper } from './FieldWrapper';
import React from 'react';

Quill.register('modules/emoji', Emoji);

export const TextAreaField = React.forwardRef((props, ref) => {
  const {
    label,
    className,
    value,
    placeholder,
    error,
    setValue,
    editorContent,
    modules = {},
    formats = [],
    toolbarOptions = [],
    theme = 'snow',
  } = props;

  const onEditorStateChange = (editorState) => {
    setValue(value, editorState);
  };

  return (
    <FieldWrapper label={label} error={error}>
      <ReactQuill
        ref={ref}
        theme={theme}
        placeholder={placeholder}
        style={{
          minHeight: '40vh',
        }}
        className={clsx(
          'appearance-none block w-full border border-none rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm',
          className
        )}
        modules={{
          toolbar:
            toolbarOptions.length > 0
              ? toolbarOptions
              : [
                  [{ font: [] }],
                  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                  ['blockquote', 'code-block'],
                  [{ header: 1 }, { header: 2 }], // custom button values
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
                  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                  [{ direction: 'rtl' }], // text direction
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                  [{ align: [] }],
                  ['clean'],
                ],
          'emoji-toolbar': true,
          'emoji-textarea': false,
          'emoji-shortname': true,
          ...modules,
        }}
        formats={formats}
        value={editorContent || ''}
        onChange={onEditorStateChange}
      />
    </FieldWrapper>
  );
});

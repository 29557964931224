import React, { useState, useEffect } from 'react';
import * as z from 'zod';
import { Button, FullScreenSpinner, Spinner } from 'components/Elements';
import { Form, InputField, InputFieldWithInfo } from 'components/Form';
import storage from 'utils/storage';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useCreateGuarantor } from '../../api/createGuarantor';

import { useVerifyGuarantor } from 'features/pigeonLoans/api/verifyGuarantor';

const schema = z.object({
  guarantorName: z
    .string({
      required_error: 'Guarantor Full name is required',
      invalid_type_error: 'Guarantor Full name must be a string',
    })
    .min(1, { message: 'Guarantor Full name cannot be empty' }),
  guarantorEmail: z
    .string({
      required_error: 'Guarantor Email is required',
      invalid_type_error: 'Guarantor Email must be a string',
    })
    .email({ message: 'Invalid email address' }),
  guarantorPhone: z
    .string({
      required_error: 'Guarantor Phone number is required',
      invalid_type_error: 'Guarantor Phone number must be a string',
    })
    .min(1, { message: 'Guarantor Phone number cannot be empty' }),
});

export const Guarantor = ({ nextStep, prevStep }) => {
  const [guarantorName, setGuarantorName] = useState('');
  const [guarantorPhoneNumber, setGuarantorPhoneNumber] = useState('');
  const [guarantorEmail, setGuarantorEmail] = useState('');

  const [code, setCode] = useState();
  const [errMsg, setErrMsg] = useState('');

  const createGuarantorMutation = useCreateGuarantor();

  const verifyMutation = useVerifyGuarantor();

  useEffect(() => {
    //console.log('loans details loaded', paymentMethod);
    const getData = async () => {
      // localStorage.removeItem('msw-dbformValues')
      //   localStorage.removeItem('pigeonLoansCurrentStep')
    };
    // getData()
  }, []);

  if (createGuarantorMutation.isLoading)
    return (
      <div className="flex flex-row items-center justify-center my-4">
        <Spinner />
      </div>
    );

  return (
    <Form
      onSubmit={async () => {
        if (guarantorPhoneNumber.length < 12) {
          seterrMsg('Invalid Phone Number');
          console.log('invalid phone');
          return;
        }
        // const vls = JSON.parse(localStorage.getItem(`msw-dbformValues`))
        // delete vls.guarantorEmail
        // delete vls.guarantorName
        // delete vls.guarantorPhone
        // console.log('persons', vls)
        // localStorage.setItem('msw-dbformValues', JSON.stringify(vls))
        // localStorage.removeItem('msw-dbformValues')
        // localStorage.removeItem('pigeonLoansCurrentStep')
        setErrMsg('');
        const guarantor = {
          name: guarantorName,
          email: guarantorEmail,
          phoneNumber: guarantorPhoneNumber,
        };
        console.log('guarantorData', guarantor);
        localStorage.setItem('guarantorData', JSON.stringify(guarantor));
        await createGuarantorMutation
          .mutateAsync({ data: guarantor })
          .then((data) => {
            console.log('guarantor', data.guarantor);
            storage.setFormValues({
              guarantor: data.guarantor._id,
            });
            nextStep();
          })
          .catch((err) => {
            console.log('guarantor error', err);
            setErrMsg(`${err.message}. Try again.`);
          });
      }}
      options={{
        defaultValues: {
          guarantorName: guarantorName,
          guarantorEmail: guarantorEmail,
          guarantorPhoneNumber: guarantorPhoneNumber,
        },
      }}
      schema={schema}
    >
      {({ register, formState, setValue }) => (
        <div className="px-12 md:px-24 pt-3">
          <div className="text-lg mb-6">Provide your guarantor's details</div>
          <InputFieldWithInfo
            type="text"
            label="Full Name"
            error={formState.errors['guarantorName']}
            registration={register('guarantorName')}
            name="guarantorName"
            value={guarantorName}
            className="mb-2"
            onChange={(event) => {
              formState.errors['guarantorName'] = '';
              setValue('guarantorName', event.target.value);
              setGuarantorName(event.target.value);
              // storage.setFormValues({
              //   guarantorName: event.target.value,
              // });
            }}
          />
          <InputFieldWithInfo
            type="email"
            label="Email"
            infoTitle="Guarantor Email"
            infoDescription="Your guarantor will recieve an email from us to consent to Finablr's terms and conditions"
            error={formState.errors['guarantorEmail']}
            registration={register('guarantorEmail')}
            name="guarantorEmail"
            value={guarantorEmail}
            className="mb-2"
            onChange={(event) => {
              formState.errors['guarantorEmail'] = '';
              setValue('guarantorEmail', event.target.value);
              setGuarantorEmail(event.target.value);
              // storage.setFormValues({
              //   guarantorEmail: event.target.value,
              // });
            }}
          />
          <div className="mb-3">
            <div className="block text-sm font-medium text-gray-700 dark:text-gray-400 mt-3">
              Phone Number
            </div>
            <PhoneInput
              country={'ug'}
              countryCodeEditable={false}
              masks={{ ug: '... ... ...' }}
              name="guarantorPhone"
              value={guarantorPhoneNumber}
              // registration={register('guarantorPhone')}
              inputStyle={{
                width: '100%',
                height: '50px',
                fontSize: '1rem',
                border: '1px solid #e5e5e5',
                borderRadius: '8px',
              }}
              containerClass="mt-2 mb-2"
              onChange={(phone) => {
                formState.errors['guarantorPhone'] = '';
                setValue('guarantorPhone', `+${phone}`);
                setGuarantorPhoneNumber(`+${phone}`);
                storage.setFormValues({
                  phoneNumber: `+${phone}`,
                });
                console.log('guarantorPhone', `+${phone}`);
              }}
            />
          </div>
          {formState.errors['guarantorPhone']?.message && (
            <div
              role="alert"
              aria-label={formState.errors['guarantorPhone']?.message}
              className="text-sm font-semibold text-red-500"
            >
              {formState.errors['guarantorPhone']?.message}
            </div>
          )}

          {errMsg && (
            <div role="alert" aria-label={errMsg} className="text-sm font-semibold text-red-500">
              {errMsg}
            </div>
          )}
          <div className="mx-0 md:mx-32 flex flex-col mt-4 space-y-3">
            <div className="flex flex-row items-center space-x-2">
              <Button variant="outline" onClick={() => prevStep()} className="w-full">
                Previous
              </Button>
              <Button variant="dark" type="submit" className="w-full">
                Next
              </Button>
            </div>
          </div>
        </div>
      )}
    </Form>
  );
};

import { Button, Spinner } from 'components/Elements';
import { Form, InputFieldWithInfo, RegularTextAreaField } from 'components/Form';
import { ReactSelectField2 } from 'components/Pigeon';
import { otherElectronicsSchema } from 'features/assets/schemas';
import { useProducts } from 'features/products';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import storage from 'utils/storage';
import { COLLATERAL } from 'variables';
import { defaults } from 'features/assets/data/defaults';

export const OtherElectronicsAssetDetailsForm = ({ onSuccess }) => {
  const [otherElectronicsAssetData, setOtherElectronicsAssetData] = useState({
    title: '',
    product: '',
    selectedProduct: '',
    nature_of_electronic: '',
    selectednature_of_electronic: '',
    specify_others: '',
    model_type: '',
    description: '',
  });

  const productsQuery = useProducts();

  useEffect(() => {
    const getData = async () => {
      const assetCategory = localStorage.getItem('assetCategory');
      if (assetCategory) {
        if (assetCategory === COLLATERAL.CATEGORIES.OTHER_ELECTRONICS) {
          const result = storage.getOtherValues('otherElectronicsAssetData');
          setOtherElectronicsAssetData(result);
        }
      }
    };
    getData();
  }, []);

  if (productsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="md" />
      </div>
    );
  }

  if (!productsQuery?.data) return null;

  return (
    <Form
      id="create-post"
      onSubmit={async (values) => {
        onSuccess();
      }}
      defaultValues={{
        title: otherElectronicsAssetData.title,
        product: otherElectronicsAssetData.product,
        nature_of_electronic: otherElectronicsAssetData.nature_of_electronic,
        specify_others: otherElectronicsAssetData.specify_others,
        model_type: otherElectronicsAssetData.model_type,
        description: otherElectronicsAssetData.description,
      }}
      schema={otherElectronicsSchema}
    >
      {({ register, formState, watch, setValue }) => (
        <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
          <div className="mb-4">Asset Details for other electronics</div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="space-y-5">
              <InputFieldWithInfo
                label="Title of Asset"
                error={formState.errors['title']}
                registration={register('title')}
                caption="Enter a concise title for your asset. This will help us identify it easily and efficiently."
                onChange={(e) => {
                  formState.errors['title'] = '';
                  setValue('title', e.target.value);
                  // console.log('title data', { ...vehicleAssetData, title: e.target.value })
                  const dt = { ...otherElectronicsAssetData, title: e.target.value };
                  setOtherElectronicsAssetData(dt);
                  storage.setAnyFormValues('otherElectronicsAssetData', dt);
                }}
              />
              <ReactSelectField2
                label="Please select Product"
                type="text"
                editorContent={watch('product')}
                name="product"
                defaultValue={otherElectronicsAssetData.selectedProduct}
                error={formState.errors['product']}
                registration={register('product')}
                options={productsQuery.data?.map((p) => ({
                  label: p.product_title,
                  value: p._id,
                }))}
                onChange={async (e) => {
                  const selectedProduct = await productsQuery.data
                    ?.map((p) => ({
                      label: p.product_title,
                      value: p._id,
                    }))
                    ?.find((p) => p.value === e.value);
                  setValue('product', e.value);
                  formState.errors['product'] = '';
                  setOtherElectronicsAssetData({
                    ...otherElectronicsAssetData,
                    product: e.value,
                    selectedProduct: selectedProduct,
                  });
                  storage.setAnyFormValues('otherElectronicsAssetData', {
                    product: e.value,
                    selectedProduct: selectedProduct,
                  });
                }}
              />
              <ReactSelectField2
                label="Select Electronic"
                type="text"
                editorContent={watch('nature_of_electronic')}
                name="nature_of_electronic"
                defaultValue={otherElectronicsAssetData.selectednature_of_electronic}
                error={formState.errors['nature_of_electronic']}
                registration={register('nature_of_electronic')}
                options={defaults.electronics}
                onChange={async (e) => {
                  const selectedProduct = defaults.electronics.find((p) => p.value === e.value);
                  setValue('nature_of_electronic', e.value);
                  formState.errors['nature_of_electronic'] = '';
                  setOtherElectronicsAssetData({
                    ...otherElectronicsAssetData,
                    nature_of_electronic: e.value,
                    selectednature_of_electronic: selectedProduct,
                  });
                  storage.setAnyFormValues('otherElectronicsAssetData', {
                    nature_of_electronic: e.value,
                    selectednature_of_electronic: selectedProduct,
                  });
                }}
              />
              {otherElectronicsAssetData.nature_of_electronic === 'OTHER' && (
                <InputFieldWithInfo
                  label="Specify Other Brand"
                  error={formState.errors['specify_others']}
                  registration={register('specify_others')}
                  caption="Specify a brand in case you can't find yours in the list"
                  onChange={(e) => {
                    formState.errors['specify_others'] = '';
                    setValue('specify_others', e.target.value);
                    // console.log('specify_others data', { ...vehicleAssetData, specify_others: e.target.value })
                    const dt = {
                      ...otherElectronicsAssetData,
                      specify_others: e.target.value,
                    };
                    setOtherElectronicsAssetData(dt);
                    storage.setAnyFormValues('otherElectronicsAssetData', dt);
                  }}
                />
              )}
              <InputFieldWithInfo
                label="Model Type"
                error={formState.errors['model_type']}
                registration={register('model_type')}
                caption="Specify a brand in case you can't find yours in the list"
                onChange={(e) => {
                  formState.errors['model_type'] = '';
                  setValue('model_type', e.target.value);
                  // console.log('model_type data', { ...vehicleAssetData, model_type: e.target.value })
                  const dt = {
                    ...otherElectronicsAssetData,
                    model_type: e.target.value,
                  };
                  setOtherElectronicsAssetData(dt);
                  storage.setAnyFormValues('otherElectronicsAssetData', dt);
                }}
              />
            </div>
            <div className="space-y-5">
              <RegularTextAreaField
                label="Asset Description"
                error={formState.errors['description']}
                registration={register('description')}
                caption="Any extra information you’d like us to know about your computer. This will
                guide us on serving you better."
                value={otherElectronicsAssetData.description}
                onChange={(e) => {
                  formState.errors['description'] = '';
                  setValue('description', e.target.value);
                  setOtherElectronicsAssetData({
                    ...otherElectronicsAssetData,
                    description: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex flex-row space-x-2 justify-end mt-4">
            <Button type="submit">Next</Button>
          </div>
        </div>
      )}
    </Form>
  );
};

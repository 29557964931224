import { Button } from 'components/Elements';
import React from 'react';
import { MdClose } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

export const KYCNotice = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="bg-secondary-100 p-3 flex flex-row justify-between md:px-12">
        <div className="text-white font-semibold text-md md:text-xl">KYC Notice</div>
        <button>
          <MdClose className="text-white" height={8} />
        </button>
      </div>
      <div className="p-3 border-b border-gray-200">
        Please provide the following KYC information in order to complete your loan application.
      </div>

      <div className="p-3">
        <Button onClick={() => navigate('/app')} variant="secondary" className="w-full md:w-fit">
          Close
        </Button>
      </div>
    </div>
  );
};

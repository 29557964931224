import Select from 'react-select';
import { FieldWrapper } from './FieldWrapper';

export const ReactSelectField = (props) => {
  const {
    label,
    options,
    error,
    setValue,
    name,
    onInputChange,
    required,
    defaultValue,
    onChange,
    onMenuClose,
    isMulti = false,
  } = props;

  // Function to handle select state change
  const onSelectStateChange = (selectState) => {
    setValue(name, selectState ? selectState.value : null);
    // If onChange is provided, call it with the updated value
    if (onChange) {
      onChange(selectState);
    }
  };

  return (
    <FieldWrapper label={label} error={error}>
      <Select
        className="basic-single"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        onMenuClose={onMenuClose}
        name={name}
        required={required}
        isMulti={isMulti}
        onInputChange={onInputChange}
        defaultValue={defaultValue ? options.find((opt) => opt.value === defaultValue) : null}
        options={options}
        onChange={onSelectStateChange}
        maxMenuHeight={100}
      />
    </FieldWrapper>
  );
};

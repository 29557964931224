import { Button } from 'components/Elements';
import { Form } from 'components/Form';
import { useCreateLoan } from '../../api/createLoan';
import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import storage from 'utils/storage';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'lib/auth';

export const Signature = ({ prevStep }) => {
  const [trimmedDataURL, setTrimmedDataURL] = useState(null);
  const sigPadRef = useRef(null);

  const { user } = useAuth();
  const navigate = useNavigate();
  const createLoanMutation = useCreateLoan();

  const clear = () => {
    sigPadRef.current.clear();
  };

  const trim = () => {
    const trimmedData = sigPadRef.current.getTrimmedCanvas().toDataURL('image/png');

    setTrimmedDataURL(trimmedData);
  };

  const values = storage.getFormValues();

  return (
    <div className="mx-4 md:mx-12">
      <Form
        id="create-loan"
        onSubmit={async () => {
          storage.setFormValues({
            signature: trimmedDataURL,
          });

          if (user.authentication?.username) {
            await createLoanMutation.mutateAsync({
              data: {
                ...values,
                signature: trimmedDataURL,
              },
            });
            navigate('/app/pigeon-loans/success');
          } else {
            navigate(
              '/app/pigeon-loans/kyc-info-required?loan_application=true&application_level=7'
            );
          }
        }}
      >
        {({ formState, register }) => (
          <div className="">
            <div className="mb-4">
              <div className="p-4 mx-auto flex flex-col items-center justify-center bg-gray-50 rounded-xl shadow-lg max-w-4xl">
                <h2 className="text-xl font-semibold text-gray-700 mb-4">
                  Sign Your Loan Agreement
                </h2>
                <p className="text-md text-gray-600 mb-6 text-start">
                  By signing below, you confirm your agreement to the terms and conditions of the
                  loan as outlined in the contract that will be generated once your loan application
                  has been approved. Your digital signature is legally binding under the{' '}
                  <span className="font-semibold italic text-primary-100">
                    Electronic Transactions Act, 2011.
                  </span>
                  <br />
                  <br />
                  <span>
                    Ensure all provided information is accurate and complete before signing.
                  </span>
                </p>
                <div className="overflow-hidden flex flex-row items-center justify-center">
                  <SignatureCanvas
                    penColor="darkblue"
                    canvasProps={{
                      // width: '800vw',
                      // height: 200,
                      className:
                        'sigCanvas w-[40vw] h-[20vh] border border-gray-200 rounded-2xl shadow bg-white mb-4',
                    }}
                    ref={sigPadRef}
                  />
                </div>
                <button
                  type="button"
                  onClick={clear}
                  className="px-4 py-2 mt-4 text-sm font-medium text-red-600 bg-red-100 rounded-lg hover:bg-red-200"
                >
                  Clear Signature
                </button>
                <p className="text-md text-gray-500 mt-6 text-center">
                  Your signature will be securely stored in compliance with the{' '}
                  <span className="font-semibold italic text-primary-100">
                    Data Protection and Privacy Act, 2019
                  </span>
                  . We take your privacy seriously and ensure all data is protected from
                  unauthorized access.
                </p>
              </div>
              {!trimmedDataURL && (
                <div className="flex flex-row justify-center items-center space-x-2 mt-6">
                  <Button size="sm" type="button" onClick={trim}>
                    Confirm Signature
                  </Button>
                </div>
              )}
            </div>
            {trimmedDataURL && (
              <div className="mx-8 md:mx-56 flex flex-col space-y-3">
                <div className="flex flex-row items-center space-x-2">
                  <Button variant="outline" onClick={() => prevStep()} className="w-full">
                    Previous
                  </Button>
                  <Button
                    isLoading={createLoanMutation.isLoading}
                    variant="dark"
                    type="submit"
                    className="w-full"
                  >
                    Finish
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
      </Form>
    </div>
  );
};

import { Button, Dialog, DialogTitle, Spinner } from 'components/Elements';
import { UPLOADS_URL } from 'config';
import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { MdClose, MdWarning } from 'react-icons/md';
import { Form } from 'components/Form';
import { useApproveBorrowerSignature } from '../api/approveBorrowerSignature';
import { Info } from 'react-feather';

export function SubmitBorrowerSignature({ id, location }) {
  const [show, setShow] = useState(false);
  const [trimmedDataURL, setTrimmedDataURL] = useState(null);
  const [updateSignature, setUpdateSignature] = useState(false);
  const sigPadRef = useRef(null);

  const confirmBorrowerSignatureMutation = useApproveBorrowerSignature();

  const clear = () => {
    sigPadRef.current?.clear();
  };

  const trim = () => {
    const trimmedData = sigPadRef.current.getTrimmedCanvas().toDataURL('image/png');
    setTrimmedDataURL(trimmedData);
    setFinish(true);
  };

  const handleOpen = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setUpdateSignature(false);
    setTrimmedDataURL(null);
    clear();
  };

  return (
    <div>
      <button className="text-red-500 underline" onClick={handleOpen}>
        Pending Signature
      </button>
      <Dialog isOpen={show} onClose={handleClose} initialFocus={null}>
        <div className="inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <div className="flex flex-row items-center justify-between mb-4">
            <DialogTitle className="text-xl text-start font-bold">Confirm Signature</DialogTitle>
            <button onClick={handleClose}>
              <MdClose size={28} />
            </button>
          </div>
          <>
            <div className="text-red-500">
              You are about to confirm your signature on the loan contract. Please note that you can
              not reverse this action.
            </div>
            <br />
            <>
              <div className="flex flex-col items-center justify-center p-3 rounded-2xl mb-3">
                <img
                  src={UPLOADS_URL + '/signatures/' + location}
                  className="h-16"
                  alt="Signature"
                />
                <div className="text-xs flex flex-row items-center mt-2">
                  <div className="mr-2">
                    <Info size={12} />
                  </div>
                  <span>Submitted at loan application</span>
                </div>
              </div>
              <div className="flex flex-row items-center justify-center space-x-2 mb-3">
                <Button
                  size="sm"
                  isLoading={confirmBorrowerSignatureMutation.isLoading}
                  onClick={async () =>
                    await confirmBorrowerSignatureMutation.mutateAsync({ id: id })
                  }
                >
                  <span className="font-bold">Proceed & Confirm Signature</span>
                  <br />
                  <span className="text-xs">This action is not reversible</span>
                </Button>
              </div>
            </>
          </>
        </div>
      </Dialog>
    </div>
  );
}

export const reverseGeocode = async ({ lng, lat }) => {
  console.log('reverse geocode');
  console.log(lat, lng);
  const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch address from coordinates.');
    }

    const data = await response.json();

    // Check if the API returned any valid address
    if (data && data.address) {
      return data.display_name; // or use any other address component from data.address
    } else {
      throw new Error('No address found for the provided coordinates.');
    }
  } catch (error) {
    console.error('Error during reverse geocoding:', error.message);
    return null;
  }
};

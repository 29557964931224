import { Spinner } from 'components/Elements';
import { loginWithPhoneAndPassword, getUser, registerWithPhoneAndPassword } from 'features/auth';
import storage from 'utils/storage';
import { initReactQueryAuth } from 'react-query-auth';
import React, { useState, useEffect } from 'react';
import { loginWithGoogle } from 'features/auth/api/loginWithGoogle';

export async function handleUserResponse(data) {
  const { token, user, company, assets } = data;
  storage.setToken(token);
  let companyUser;
  if (company) {
    companyUser = { ...user, company, assets };
    return companyUser;
  }
  const userData = { ...user, assets };
  return userData;
}

async function loadUser() {
  if (storage.getToken()) {
    const { user, company, assets } = await getUser();

    if (company) {
      const loadedUser = { ...user, company, assets };

      return loadedUser;
    }

    const userData = { ...user, assets };

    return userData;
  }
  return null;
}

async function loginFn(data) {
  const response = await loginWithPhoneAndPassword(data);
  const user = await handleUserResponse(response);
  return user;
}

export async function loginWithGoogleAuth(data) {
  const response = await loginWithGoogle(data);
  const user = await handleUserResponse(response);
  return user;
}

async function registerFn(data) {
  const response = await registerWithPhoneAndPassword(data);
  return response;
}

async function logoutFn() {
  storage.clearRedirect();
  storage.clearToken();
  window.location.assign(window.location.origin);
}

const authConfig = {
  loadUser,
  loginFn,
  registerFn,
  logoutFn,
  LoaderComponent() {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Spinner size="xl" />
      </div>
    );
  },
};

const { AuthProvider, useAuth: baseUseAuth } = initReactQueryAuth(authConfig);

export const useAuth = () => {
  const auth = baseUseAuth();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (auth.user !== undefined) {
      setIsLoading(false);
    }
  }, [auth.user]);

  return { ...auth, isLoading };
};

export { AuthProvider };

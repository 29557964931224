import React, { useState, useEffect } from 'react';
import { Button } from 'components/Elements';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { ContentLayout } from 'components/Layout';
import storage from 'utils/storage';
import { FaHome } from 'react-icons/fa';
import { RealEstateAssetDetailsForm } from '../components/assetDetails/RealEstateAssetDetailsForm';
import { RealEstatePicturesForm } from '../components/pictures/RealEstatePicturesForm';
import { Legal } from '../components/Legal';
import { RealEstateValuationForm } from '../components/valuation/RealEstateValuationForm';
import { VehicleAssetDetailsForm } from '../components/assetDetails/VehicleAssetDetailsForm';
import { VehiclePicturesForm } from '../components/pictures/VehiclePicturesForm';
import { VehicleValuationForm } from '../components/valuation/VehicleValuationForm';
import { SmartPhoneAssetDetailsForm } from '../components/assetDetails/SmartPhoneAssetDetailsForm';
import { SmartPhonePicturesForm } from '../components/pictures/SmartPhonePicturesForm';
import { SmartPhoneValuationForm } from '../components/valuation/SmartPhoneValuationForm';
import { TelevisionAssetDetailsForm } from '../components/assetDetails/TelevisionAssetDetailsForm';
import { TelevisionPicturesForm } from '../components/pictures/TelevisionPicturesForm';
import { TelevisionValuationForm } from '../components/valuation/TelevisionValuationForm';
import { ComputerAssetDetailsForm } from '../components/assetDetails/ComputerAssetDetailsForm';
import { ComputerPicturesForm } from '../components/pictures/ComputerPicturesForm';
import { ComputerValuationForm } from '../components/valuation/ComputerValuationForm';
import { OtherElectronicsAssetDetailsForm } from '../components/assetDetails/OtherElectronicsAssetDetailsForm';
import { OtherElectronicsPicturesForm } from '../components/pictures/OtherElectronicsPicturesForm';
import { OtherElectronicsValuationForm } from '../components/valuation/OtherElectronicsValuationForm';
import { COLLATERAL } from 'variables';

export const CreateAsset = () => {
  const [category, setCategory] = useState('');
  const [assetDetails, setAssetDetails] = useState(false);
  const [pictures, setPictures] = useState(false);
  const [valuation, setValuation] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    storage.clearRedirect();
    const getData = async () => {
      // const assetCategory = localStorage.getItem('assetCategory');
      // if (assetCategory) {
      //   const formStep = localStorage.getItem('formStep');
      //   if (formStep) {
      //     if (formStep === 'assetDetails') {
      //       setAssetDetails(true);
      //     }
      //     if (formStep === 'pictures') {
      //       setPictures(true);
      //     }
      //     if (formStep === 'valuation') {
      //       setValuation(true);
      //     }
      //   } else {
      //     setAssetDetails(true);
      //   }
      // }
    };
    getData();
  }, []);

  const params = new URLSearchParams(window.location.search);
  const loanApplication = params.get('loan_application');

  return (
    <ContentLayout
      title="New Collateral Asset"
      subtitle="Add your collateral assets to your listings."
      end={
        <Button
          variant="dark"
          onClick={() => {
            Swal.fire({
              title: 'Are you sure you want to close?',
              text: "If you close this window you'll lose your progress with your new collateral asset.",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#FEB11B',
              cancelButtonColor: '#181818',
              confirmButtonText: 'Continue',
            }).then((result) => {
              if (result.isConfirmed) {
                localStorage.removeItem('assetCategory');
                localStorage.removeItem('formStep');
                navigate('/app/assets');
              }
            });
          }}
          startIcon={<FaHome />}
        >
          Home
        </Button>
      }
    >
      <div className="mt-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 items-start">
          <div className="bg-white rounded-2xl shadow p-6 flex flex-row md:flex-col w-full">
            <div className="grid grid-cols-1 gap-2">
              <button
                disabled={!category}
                onClick={() => {
                  Swal.fire({
                    title: 'Are you sure?',
                    text: 'You will lose all your progress!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, close it!',
                    cancelButtonText: 'No, keep it',
                  }).then((result) => {
                    if (result.isConfirmed) {
                      setCategory('');
                      setPictures(false);
                      setValuation(false);
                      setAssetDetails(false);
                    }
                  });
                }}
                className={`${!category && `bg-gray-100`} text-primary-100 p-4 rounded-2xl`}
              >
                <span className="block md:hidden">1</span>
                <span className="hidden md:block text-start">Select Collateral Category</span>
              </button>
            </div>
            <div className="grid grid-cols-1 gap-2">
              <button
                disabled={!category}
                onClick={() => {
                  setPictures(false);
                  setValuation(false);
                  setAssetDetails(true);
                  localStorage.setItem('formStep', 'assetDetails');
                }}
                className={`${assetDetails && `bg-gray-100`} text-gray-500 p-4 rounded-2xl`}
              >
                <span className="block md:hidden">2</span>
                <span className="hidden md:block text-start">Provide Asset Details</span>
              </button>
            </div>
            <div className="grid grid-cols-1 gap-2">
              <button
                disabled={!category}
                onClick={() => {
                  setAssetDetails(false);
                  setValuation(false);
                  setPictures(true);
                  localStorage.setItem('formStep', 'pictures');
                }}
                className={`${pictures && `bg-gray-100`} text-gray-500 p-4 rounded-2xl`}
              >
                <span className="block md:hidden">3</span>
                <span className="hidden md:block text-start">Upload Collateral Images</span>
              </button>
            </div>
            <div className="grid grid-cols-1 gap-2">
              <button
                disabled={!category}
                onClick={() => {
                  setAssetDetails(false);
                  setValuation(true);
                  setPictures(false);
                  localStorage.setItem('formStep', 'valuation');
                }}
                className={`${valuation && `bg-gray-100`} text-gray-500 p-4 rounded-2xl`}
              >
                <span className="block md:hidden">4</span>
                <span className="hidden md:block text-start">Provide Collateral Valuation</span>
              </button>
            </div>
          </div>
          <div className="md:col-span-3">
            {!category && (
              <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                {/* Legal Information */}
                <Legal />

                {/* Choose Category */}
                <div className="mb-4 text-lg font-semibold text-gray-800">Choose Category</div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <button
                    onClick={() => {
                      setCategory(COLLATERAL.CATEGORIES.REAL_ESTATE_WITH_LAND_TITLE);
                      setAssetDetails(true);
                      localStorage.setItem(
                        'assetCategory',
                        COLLATERAL.CATEGORIES.REAL_ESTATE_WITH_LAND_TITLE
                      );
                    }}
                    className="border border-gray-200 rounded-md p-5 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    Real Estate With Land Title
                  </button>
                  <button
                    onClick={() => {
                      setCategory(COLLATERAL.CATEGORIES.VEHICLES);
                      setAssetDetails(true);
                      localStorage.setItem('assetCategory', COLLATERAL.CATEGORIES.VEHICLES);
                    }}
                    className="border border-gray-200 rounded-md p-5 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    Vehicles
                  </button>
                  <button
                    onClick={() => {
                      setCategory(COLLATERAL.CATEGORIES.TELEVISION);
                      setAssetDetails(true);
                      localStorage.setItem('assetCategory', COLLATERAL.CATEGORIES.TELEVISION);
                    }}
                    className="border border-gray-200 rounded-md p-5 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    Television
                  </button>
                  <button
                    onClick={() => {
                      setCategory(COLLATERAL.CATEGORIES.COMPUTERS);
                      setAssetDetails(true);
                      localStorage.setItem('assetCategory', COLLATERAL.CATEGORIES.COMPUTERS);
                    }}
                    className="border border-gray-200 rounded-md p-5 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    Computers
                  </button>
                  <button
                    onClick={() => {
                      setCategory(COLLATERAL.CATEGORIES.SMART_PHONES);
                      setAssetDetails(true);
                      localStorage.setItem('assetCategory', COLLATERAL.CATEGORIES.SMART_PHONES);
                    }}
                    className="border border-gray-200 rounded-md p-5 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    Smart Phones
                  </button>
                  <button
                    onClick={() => {
                      setCategory(COLLATERAL.CATEGORIES.OTHER_ELECTRONICS);
                      setAssetDetails(true);
                      localStorage.setItem(
                        'assetCategory',
                        COLLATERAL.CATEGORIES.OTHER_ELECTRONICS
                      );
                    }}
                    className="border border-gray-200 rounded-md p-5 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    Other Electronics
                  </button>
                </div>
              </div>
            )}

            {category === COLLATERAL.CATEGORIES.REAL_ESTATE_WITH_LAND_TITLE && (
              <>
                {assetDetails && (
                  <RealEstateAssetDetailsForm
                    onSuccess={() => {
                      setAssetDetails(false);
                      setPictures(true);
                    }}
                  />
                )}
                {pictures && (
                  <RealEstatePicturesForm
                    onSuccess={() => {
                      setPictures(false);
                      setValuation(true);
                    }}
                    onBack={() => {
                      setAssetDetails(true);
                      setPictures(false);
                    }}
                    setSelectedImages={setSelectedImages}
                  />
                )}
                {valuation && (
                  <RealEstateValuationForm
                    onSuccess={() => {
                      setValuation(false);
                      if (loanApplication === true) {
                        navigate('/app/assets/create/success?application_level=4');
                      } else {
                        navigate('/app/assets/create/success');
                      }
                    }}
                    selectedImages={selectedImages}
                  />
                )}
              </>
            )}
            {category === COLLATERAL.CATEGORIES.VEHICLES && (
              <>
                {assetDetails && (
                  <VehicleAssetDetailsForm
                    onSuccess={() => {
                      setAssetDetails(false);
                      setPictures(true);
                    }}
                  />
                )}
                {pictures && (
                  <VehiclePicturesForm
                    onSuccess={() => {
                      setPictures(false);
                      setValuation(true);
                    }}
                    onBack={() => {
                      setAssetDetails(true);
                      setPictures(false);
                    }}
                    setSelectedImages={setSelectedImages}
                  />
                )}
                {valuation && (
                  <VehicleValuationForm
                    onSuccess={() => {
                      setValuation(false);
                      if (loanApplication === true) {
                        navigate('/app/assets/create/success?application_level=4');
                      } else {
                        navigate('/app/assets/create/success');
                      }
                    }}
                    selectedImages={selectedImages}
                  />
                )}
              </>
            )}
            {category === COLLATERAL.CATEGORIES.SMART_PHONES && (
              <>
                {assetDetails && (
                  <SmartPhoneAssetDetailsForm
                    onSuccess={() => {
                      setAssetDetails(false);
                      setPictures(true);
                    }}
                  />
                )}
                {pictures && (
                  <SmartPhonePicturesForm
                    onSuccess={() => {
                      setPictures(false);
                      setValuation(true);
                    }}
                    onBack={() => {
                      setAssetDetails(true);
                      setPictures(false);
                    }}
                    setSelectedImages={setSelectedImages}
                  />
                )}

                {valuation && (
                  <SmartPhoneValuationForm
                    onSuccess={() => {
                      setValuation(false);
                      if (loanApplication === true) {
                        navigate('/app/assets/create/success?application_level=4');
                      } else {
                        navigate('/app/assets/create/success');
                      }
                    }}
                    selectedImages={selectedImages}
                  />
                )}
              </>
            )}
            {category === COLLATERAL.CATEGORIES.TELEVISION && (
              <>
                {assetDetails && (
                  <TelevisionAssetDetailsForm
                    onSuccess={() => {
                      setAssetDetails(false);
                      setPictures(true);
                    }}
                  />
                )}
                {pictures && (
                  <TelevisionPicturesForm
                    onSuccess={() => {
                      setPictures(false);
                      setValuation(true);
                    }}
                    onBack={() => {
                      setAssetDetails(true);
                      setPictures(false);
                    }}
                    setSelectedImages={setSelectedImages}
                  />
                )}

                {valuation && (
                  <TelevisionValuationForm
                    onSuccess={() => {
                      setValuation(false);
                      if (loanApplication === true) {
                        navigate('/app/assets/create/success?application_level=4');
                      } else {
                        navigate('/app/assets/create/success');
                      }
                    }}
                    selectedImages={selectedImages}
                  />
                )}
              </>
            )}
            {category === COLLATERAL.CATEGORIES.COMPUTERS && (
              <>
                {assetDetails && (
                  <ComputerAssetDetailsForm
                    onSuccess={() => {
                      setAssetDetails(false);
                      setPictures(true);
                    }}
                  />
                )}
                {pictures && (
                  <ComputerPicturesForm
                    onSuccess={() => {
                      setPictures(false);
                      setValuation(true);
                    }}
                    setSelectedImages={setSelectedImages}
                    onBack={() => {
                      setAssetDetails(true);
                      setPictures(false);
                    }}
                  />
                )}
                {valuation && (
                  <ComputerValuationForm
                    onSuccess={() => {
                      setValuation(false);
                      if (loanApplication === true) {
                        navigate('/app/assets/create/success?application_level=4');
                      } else {
                        navigate('/app/assets/create/success');
                      }
                    }}
                    onBack={() => {
                      setValuation(false);
                      setPictures(true);
                    }}
                    selectedImages={selectedImages}
                  />
                )}
              </>
            )}
            {category === COLLATERAL.CATEGORIES.OTHER_ELECTRONICS && (
              <>
                {assetDetails && (
                  <OtherElectronicsAssetDetailsForm
                    onSuccess={() => {
                      setAssetDetails(false);
                      setPictures(true);
                    }}
                  />
                )}
                {pictures && (
                  <OtherElectronicsPicturesForm
                    onSuccess={() => {
                      setPictures(false);
                      setValuation(true);
                    }}
                    onBack={() => {
                      setAssetDetails(true);
                      setPictures(false);
                    }}
                    setSelectedImages={setSelectedImages}
                  />
                )}
                {valuation && (
                  <OtherElectronicsValuationForm
                    onSuccess={() => {
                      setPictures(false);
                      setValuation(false);
                      setAssetDetails(false);
                      if (loanApplication === true) {
                        navigate('/app/assets/create/success?application_level=4');
                      } else {
                        navigate('/app/assets/create/success');
                      }
                    }}
                    onBack={() => {
                      setValuation(false);
                      setPictures(true);
                    }}
                    selectedImages={selectedImages}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </ContentLayout>
  );
};

import { useState } from 'react'; // Import useState hook
import clsx from 'clsx';
import { Eye, EyeOff } from 'react-feather';
import { Purpose } from 'features/pigeonLoans/tips/Purpose';

export const InputFieldWithInfo = (props) => {
  const {
    id,
    type,
    label,
    className,
    onChange,
    maxLength,
    minLength,
    caption,
    // registration,
    placeholder,
    passwordField,
    error,
    required,
    name,
    infoTitle,
    infoDescription,
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  const handleKeyPress = (e) => {
    if (type === 'number' && !/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <div>
      <div className="flex flex-row items-center justify-between mb-1">
        <div className="text-sm font-medium text-gray-700">{label}</div>
        {infoTitle && <Purpose title={infoTitle} description={infoDescription} />}
      </div>
      <div className="">
        {caption && <div className="my-1 text-xs text-gray-500 flex justify-start">{caption}</div>}
        <input
          id={id}
          name={name}
          type={showPassword ? 'text' : type}
          maxLength={maxLength}
          minLength={minLength}
          className={clsx(
            'appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm',
            className
          )}
          required={required}
          placeholder={placeholder}
          onChange={onChange}
          onKeyPress={handleKeyPress}
          // {...registration}
        />
        {passwordField && (
          <button
            type="button"
            className="absolute top-1/2 right-3 transform -translate-y-1/2 text-sm text-gray-500"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <EyeOff className="h-4 w-4" /> : <Eye className="h-4 w-4" />}
          </button>
        )}
      </div>

      {error?.message && (
        <div role="alert" aria-label={error.message} className="text-sm font-semibold text-red-500">
          {error.message}
        </div>
      )}
    </div>
  );
};

import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';

import { Button, Spinner } from 'components/Elements';
import { useNavigate, useParams } from 'react-router-dom';
import { useAsset } from '../api/getAsset';
import { formatDate } from 'utils/format';
import { UPLOADS_URL } from 'config';
import { formatText } from 'utils/formatText';
import { UpdateAsset } from '../components/UpdateAsset';

export const Asset = () => {
  const { id } = useParams();

  const assetQuery = useAsset({ id });

  const navigate = useNavigate();

  if (assetQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!assetQuery.data) return null;

  const routes = [
    {
      name: 'Assets',
      path: '/app/assets',
    },
    {
      name: assetQuery.data?.title,
      path: `/app/assets/${id}`,
    },
  ];

  return (
    <ContentLayout title={assetQuery.data?.title} end={<UpdateAsset id={id} />}>
      <Breadcrumbs routes={routes} />
      <div className="mt-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <div className="col-span-4">
            {assetQuery.data?.vehicles && (
              <div className="bg-white rounded-2xl shadow mb-4">
                <div className="grid grid-cols-3 gap-8 p-6">
                  <div className="text-gray-600 text-sm">Make/Brand</div>
                  <div className="font-semibold text-md col-span-2">
                    {formatText(assetQuery.data?.vehicles?.brand)}
                  </div>
                </div>
                <hr />
                <div className="grid grid-cols-3 gap-8 p-6">
                  <div className="text-gray-600 text-sm">Engine Number</div>
                  <div className="font-semibold text-md col-span-2">
                    {assetQuery.data.vehicles?.engine_no}
                  </div>
                </div>
                <hr />
                <div className="grid grid-cols-3 gap-8 p-6">
                  <div className="text-gray-600 text-sm">Chasis Number</div>
                  <div className="font-semibold text-md col-span-2">
                    {assetQuery.data.vehicles?.chasis_no}
                  </div>
                </div>
                <hr />
                <div className="grid grid-cols-3 gap-8 p-6">
                  <div className="text-gray-600 text-sm">Color</div>
                  <div className="font-semibold text-md col-span-2">
                    {assetQuery.data.vehicles?.color}
                  </div>
                </div>
                <hr />
                <div className="grid grid-cols-3 gap-8 p-6">
                  <div className="text-gray-600 text-sm">Mileage</div>
                  <div className="font-semibold text-md col-span-2">
                    {assetQuery.data?.vehicles?.mileage}
                  </div>
                </div>
                <hr />
                <div className="grid grid-cols-3 gap-8 p-6">
                  <div className="text-gray-600 text-sm">Model</div>
                  <div className="font-semibold text-md col-span-2">
                    {formatText(assetQuery.data?.vehicles?.model)}
                  </div>
                </div>
                <hr />
                <div className="grid grid-cols-3 gap-8 p-6">
                  <div className="text-gray-600 text-sm">Year of Manufacture</div>
                  <div className="font-semibold text-md col-span-2">
                    {assetQuery.data?.vehicles?.year_of_manufacture}
                  </div>
                </div>
                <hr />
                <div className="grid grid-cols-3 gap-8 p-6">
                  <div className="text-gray-600 text-sm">Parking Status</div>
                  <div className="font-semibold text-md col-span-2">
                    {formatText(assetQuery.data?.vehicles?.parking_status)}
                  </div>
                </div>
                <hr />
              </div>
            )}
            {assetQuery.data?.real_estate && (
              <div className="bg-white rounded-2xl shadow mb-4">
                <div className="grid grid-cols-3 gap-8 p-6">
                  <div className="text-gray-600 text-sm">Size of Land</div>
                  <div className="font-semibold text-md col-span-2">
                    {formatText(assetQuery.data?.real_estate?.size_of_land)}
                  </div>
                </div>
                <hr />

                <div className="grid grid-cols-3 gap-8 p-6">
                  <div className="text-gray-600 text-sm">Whats on Land</div>
                  <div className="font-semibold text-md col-span-2">
                    {formatText(assetQuery.data?.real_estate?.whats_on_land)}
                  </div>
                </div>
                <hr />
                <div className="grid grid-cols-3 gap-8 p-6">
                  <div className="text-gray-600 text-sm">Land Location</div>
                  <div className="font-semibold text-md col-span-2">
                    {assetQuery.data?.real_estate?.land_location}
                  </div>
                </div>
                <hr />
                <div className="grid grid-cols-3 gap-8 p-6">
                  <div className="text-gray-600 text-sm">Plot Number</div>
                  <div className="font-semibold text-md col-span-2">
                    {assetQuery.data?.real_estate?.plot_no}
                  </div>
                </div>
                <hr />
                <div className="grid grid-cols-3 gap-8 p-6">
                  <div className="text-gray-600 text-sm">Block Number</div>
                  <div className="font-semibold text-md col-span-2">
                    {assetQuery.data?.real_estate?.block_no}
                  </div>
                </div>
                {assetQuery.data?.real_estate?.specify_others !== 'undefined' && (
                  <>
                    <hr />
                    <div className="grid grid-cols-3 gap-8 p-6">
                      <div className="text-gray-600 text-sm">Other Details</div>
                      <div className="font-semibold text-md col-span-2">
                        {assetQuery.data?.real_estate?.specify_others}
                      </div>
                    </div>
                  </>
                )}
                <hr />
              </div>
            )}
            {assetQuery.data?.computers && (
              <div className="bg-white rounded-2xl shadow mb-4">
                <div className="grid grid-cols-3 gap-8 p-6">
                  <div className="text-gray-600 text-sm">Brand</div>
                  <div className="font-semibold text-md col-span-2">
                    {assetQuery.data?.computers?.brand == 'OTHERS'
                      ? assetQuery.data?.computers?.specify_others
                      : assetQuery.data?.computers?.brand}
                  </div>
                </div>
                <hr />
                <div className="grid grid-cols-3 gap-8 p-6">
                  <div className="text-gray-600 text-sm">RAM Size</div>
                  <div className="font-semibold text-md col-span-2">
                    {assetQuery.data?.computers?.ram_size}
                  </div>
                </div>
                <hr />
                <div className="grid grid-cols-3 gap-8 p-6">
                  <div className="text-gray-600 text-sm">Hard Disk Space</div>
                  <div className="font-semibold text-md col-span-2">
                    {assetQuery.data?.computers?.hard_disk_space}
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-8 p-6">
                  <div className="text-gray-600 text-sm">Processor Speed</div>
                  <div className="font-semibold text-md col-span-2">
                    {assetQuery.data?.computers?.processor_speed}
                  </div>
                </div>
              </div>
            )}
            {assetQuery.data?.smart_phone && (
              <div className="bg-white rounded-2xl shadow mb-4">
                <div className="grid grid-cols-3 gap-8 p-6">
                  <div className="text-gray-600 text-sm">Brand</div>
                  <div className="font-semibold text-md col-span-2">
                    {assetQuery.data?.smart_phone?.brand == 'OTHERS'
                      ? assetQuery.data?.smart_phone?.specify_others
                      : assetQuery.data?.smart_phone?.brand}
                  </div>
                </div>
                <hr />
                <div className="grid grid-cols-3 gap-8 p-6">
                  <div className="text-gray-600 text-sm">RAM Size</div>
                  <div className="font-semibold text-md col-span-2">
                    {assetQuery.data?.smart_phone?.ram_size}
                  </div>
                </div>
                <hr />
                <div className="grid grid-cols-3 gap-8 p-6">
                  <div className="text-gray-600 text-sm">Storage Space</div>
                  <div className="font-semibold text-md col-span-2">
                    {assetQuery.data?.smart_phone?.storage_space}
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-8 p-6">
                  <div className="text-gray-600 text-sm">Model</div>
                  <div className="font-semibold text-md col-span-2">
                    {assetQuery.data?.smart_phone?.model}
                  </div>
                </div>
              </div>
            )}
            {assetQuery.data?.television && (
              <div className="bg-white rounded-2xl shadow mb-4">
                <div className="grid grid-cols-3 gap-8 p-6">
                  <div className="text-gray-600 text-sm">Brand</div>
                  <div className="font-semibold text-md col-span-2">
                    {assetQuery.data?.television?.brand == 'OTHERS'
                      ? assetQuery.data?.television?.specify_others
                      : assetQuery.data?.television?.brand}
                  </div>
                </div>
                <hr />
                <div className="grid grid-cols-3 gap-8 p-6">
                  <div className="text-gray-600 text-sm">Dimensions</div>
                  <div className="font-semibold text-md col-span-2">
                    {assetQuery.data?.television?.dimension}
                  </div>
                </div>
                <hr />
                <div className="grid grid-cols-3 gap-8 p-6">
                  <div className="text-gray-600 text-sm">Smart TV</div>
                  <div className="font-semibold text-md col-span-2">
                    {assetQuery.data?.television?.smart_tv ? 'Yes' : 'No'}
                  </div>
                </div>
              </div>
            )}
            {assetQuery.data?.other_electronics && (
              <div className="bg-white rounded-2xl shadow mb-4">
                <div className="grid grid-cols-3 gap-8 p-6">
                  <div className="text-gray-600 text-sm">Nature of Electronics</div>
                  <div className="font-semibold text-md col-span-2">
                    {assetQuery.data?.other_electronics == 'OTHERS'
                      ? assetQuery.data?.other_electronics?.specify_others
                      : assetQuery.data?.other_electronics?.nature_of_electronic}
                  </div>
                </div>
                <hr />
                <div className="grid grid-cols-3 gap-8 p-6">
                  <div className="text-gray-600 text-sm">Model Type</div>
                  <div className="font-semibold text-md col-span-2">
                    {assetQuery.data?.other_electronics?.model_type}
                  </div>
                </div>
              </div>
            )}
            <div className="bg-white rounded-2xl shadow mb-4">
              <div className="grid grid-cols-3 gap-8 p-6">
                <div className="text-gray-600 text-sm">More Details</div>
                <div className="font-semibold text-md col-span-2">
                  {assetQuery.data?.description}
                </div>
              </div>
              <hr />
              <div className="grid grid-cols-3 gap-8 p-6">
                <div className="text-gray-600 text-sm">Puchase Amount</div>
                <div className="font-semibold text-md col-span-2">
                  UGX {assetQuery.data?.purchase_amount?.toLocaleString()}
                </div>
              </div>
              <hr />
              <div className="grid grid-cols-3 gap-8 p-6">
                <div className="text-gray-600 text-sm">Puchase Date</div>
                <div className="font-semibold text-md col-span-2">
                  {formatDate(assetQuery.data?.purchase_date)}
                </div>
              </div>
              <hr />
              <div className="grid grid-cols-3 gap-8 p-6">
                <div className="text-gray-600 text-sm">Current Worth</div>
                <div className="font-semibold text-md col-span-2">
                  UGX {assetQuery.data?.current_worth?.toLocaleString()}
                </div>
              </div>
              <hr />
              <div className="grid grid-cols-3 gap-8 p-6">
                <div className="text-gray-600 text-sm">Assessment Fees</div>
                <div className="font-semibold text-md col-span-2">
                  UGX {assetQuery.data?.total_assessment_fees?.toLocaleString()}
                </div>
              </div>
              <hr />
            </div>
            <div className="mb-4">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {assetQuery.data?.pictures?.map((picture, index) => (
                  <div
                    key={index}
                    className="relative overflow-hidden bg-white rounded-lg shadow-md"
                  >
                    <img
                      src={`${UPLOADS_URL}/collateral/${picture}`}
                      alt={`Picture ${index + 1}`}
                      className="w-full h-64 object-cover transition-transform duration-300 transform hover:scale-105"
                    />
                    <div className="absolute top-0 left-0 bg-black bg-opacity-50 text-white py-1 px-3 text-sm font-semibold">
                      View {index + 1}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContentLayout>
  );
};

import { Button, Spinner } from 'components/Elements';
import { Form, InputFieldWithInfo, RegularTextAreaField } from 'components/Form';
import { ReactSelectField2, ReactSelectFieldWithInfo } from 'components/Pigeon';
import { vehiclesAssetDetailsSchema } from 'features/assets/schemas';
import { useProducts } from 'features/products';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import storage from 'utils/storage';
import { COLLATERAL } from 'variables';
import { defaults } from 'features/assets/data/defaults';

export const VehicleAssetDetailsForm = ({ onSuccess }) => {
  const [vehicleAssetData, setvehicleAssetData] = useState({
    title: '',
    product: '',
    selectedProduct: '',
    vehicle_sub_category: 'personal_vehicles',
    selectedVehicle_sub_category: 'personal_vehicles',
    brand: '',
    color: '',
    engine_no: '',
    chasis_no: '',
    no_plate: '',
    selectedBrand: '',
    specify_others: '',
    tracker: '',
    selectedTracker: '',
    model: '',
    selectedModel: '',
    mileage: '',
    selectedMileage: '',
    parking_status: '',
    selectedParking_status: '',
    year_of_manufacture: '',
    description: '',
  });

  const [vehicleSubCategory, setVehicleSubCategory] = useState('private_vehicles');

  const productsQuery = useProducts();

  useEffect(() => {
    const getData = async () => {
      const assetCategory = localStorage.getItem('assetCategory');
      if (assetCategory) {
        if (assetCategory === COLLATERAL.CATEGORIES.VEHICLES) {
          const result = storage.getOtherValues('vehicleAssetData');
          setvehicleAssetData(result);
        }
      }
    };
    getData();
  }, []);

  if (productsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="md" />
      </div>
    );
  }

  if (!productsQuery?.data) return null;

  return (
    <Form
      id="vehicles"
      onSubmit={async (values) => {
        onSuccess();
      }}
      defaultValues={{
        title: vehicleAssetData.title,
        product: vehicleAssetData.product,
        purpose: vehicleAssetData.purpose,
        vehicle_sub_category: vehicleAssetData.vehicle_sub_category,
        brand: vehicleAssetData.brand,
        specify_others: vehicleAssetData.specify_others,
        tracker: vehicleAssetData.tracker,
        model: vehicleAssetData.model,
        mileage: vehicleAssetData.mileage,
        engine_no: vehicleAssetData.engine_no,
        chasis_no: vehicleAssetData.chasis_no,
        no_plate: vehicleAssetData.no_plate,
        color: vehicleAssetData.color,
        parking_status: vehicleAssetData.parking_status,
        year_of_manufacture: vehicleAssetData.year_of_manufacture,
        description: vehicleAssetData.description,
      }}
      schema={vehiclesAssetDetailsSchema}
    >
      {({ register, formState, watch, setValue }) => (
        <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
          <div className="mb-4">Asset Details for Vehicle</div>
          <div className="">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="space-y-4">
                <InputFieldWithInfo
                  label="Title"
                  error={formState.errors['title']}
                  value={vehicleAssetData.title}
                  registration={register('title')}
                  caption="Please provide a simple title to help you identify or search for your asset easily."
                  onChange={(e) => {
                    formState.errors['title'] = '';
                    setValue('title', e.target.value);
                    const dt = { ...vehicleAssetData, title: e.target.value };
                    setvehicleAssetData(dt);
                    storage.setAnyFormValues('vehicleAssetData', dt);
                  }}
                />
                <InputFieldWithInfo
                  label="Number Plate"
                  error={formState.errors['no_plate']}
                  value={vehicleAssetData.no_plate}
                  registration={register('no_plate')}
                  onChange={(e) => {
                    formState.errors['no_plate'] = '';
                    setValue('no_plate', e.target.value);
                    const dt = { ...vehicleAssetData, no_plate: e.target.value };
                    setvehicleAssetData(dt);
                    storage.setAnyFormValues('vehicleAssetData', dt);
                  }}
                />

                <InputFieldWithInfo
                  label="Engine Number"
                  error={formState.errors['engine_no']}
                  value={vehicleAssetData.engine_no}
                  registration={register('engine_no')}
                  onChange={(e) => {
                    formState.errors['engine_no'] = '';
                    setValue('engine_no', e.target.value);
                    const dt = { ...vehicleAssetData, engine_no: e.target.value };
                    setvehicleAssetData(dt);
                    storage.setAnyFormValues('vehicleAssetData', dt);
                  }}
                />

                <InputFieldWithInfo
                  label="Chasis Number"
                  error={formState.errors['chasis_no']}
                  value={vehicleAssetData.chasis_no}
                  registration={register('chasis_no')}
                  onChange={(e) => {
                    formState.errors['chasis_no'] = '';
                    setValue('chasis_no', e.target.value);
                    const dt = { ...vehicleAssetData, chasis_no: e.target.value };
                    setvehicleAssetData(dt);
                    storage.setAnyFormValues('vehicleAssetData', dt);
                  }}
                />

                <ReactSelectField2
                  label="Select category of your vehicle"
                  type="text"
                  editorContent={watch('vehicle_sub_category')}
                  name="vehicle_sub_category"
                  defaultValue={vehicleAssetData.vehicle_sub_category}
                  // defaultValue={watch('vehicle_sub_category')}
                  error={formState.errors['vehicle_sub_category']}
                  registration={register('vehicle_sub_category')}
                  options={[
                    { label: 'Private Vehicles', value: 'personal_vehicles' },
                    { label: 'Bikes', value: 'bikes' },
                    { label: 'Heavy Vehicles', value: 'heavy_vehicles' },
                    { label: 'Commercial Vehicles', value: 'commercial_vehicles' },
                  ]}
                  onChange={(e) => {
                    formState.errors['vehicle_sub_category'] = '';
                    setValue('vehicle_sub_category', e.value);
                    setvehicleAssetData({
                      ...vehicleAssetData,
                      vehicle_sub_category: e.value,
                    });
                    storage.setAnyFormValues('vehicleAssetData', {
                      ...vehicleAssetData,
                      vehicle_sub_category: e.value,
                    });
                    setVehicleSubCategory(e.value);
                  }}
                />

                <InputFieldWithInfo
                  label="Color"
                  error={formState.errors['color']}
                  value={vehicleAssetData.color}
                  registration={register('color')}
                  caption="Please provide the color of your vehicle asset."
                  onChange={(e) => {
                    formState.errors['color'] = '';
                    setValue('color', e.target.value);
                    // console.log('color data', { ...vehicleAssetData, color: e.target.value })
                    const dt = { ...vehicleAssetData, color: e.target.value };
                    setvehicleAssetData(dt);
                    storage.setAnyFormValues('vehicleAssetData', dt);
                  }}
                />
                <ReactSelectField2
                  label="Please select a loan product"
                  caption="Select a loan product that best suits your needs and preferences."
                  type="text"
                  editorContent={watch('product')}
                  name="product"
                  defaultValue={vehicleAssetData.selectedProduct}
                  error={formState.errors['product']}
                  registration={register('product')}
                  options={productsQuery.data?.map((p) => ({
                    label: p.product_title,
                    value: p._id,
                  }))}
                  onChange={async (e) => {
                    const selectedProduct = await productsQuery.data
                      ?.map((p) => ({
                        label: p.product_title,
                        value: p._id,
                      }))
                      ?.find((p) => p.value === e.value);
                    setValue('product', e.value);
                    formState.errors['product'] = '';
                    setvehicleAssetData({
                      ...vehicleAssetData,
                      product: e.value,
                      selectedProduct: selectedProduct,
                    });
                    storage.setAnyFormValues('vehicleAssetData', {
                      product: e.value,
                      selectedProduct: selectedProduct,
                    });
                  }}
                />
                {vehicleAssetData.vehicle_sub_category && (
                  <ReactSelectField2
                    label={
                      vehicleSubCategory !== 'bikes' ? 'Select a car brand' : 'Select a bike brand'
                    }
                    type="text"
                    editorContent={watch('brand')}
                    name="brand"
                    defaultValue={vehicleAssetData.brand}
                    error={formState.errors['brand']}
                    registration={register('brand')}
                    options={
                      vehicleSubCategory !== 'bikes'
                        ? [
                            { label: 'Toyota', value: 'TOYOTA' },
                            { label: 'Nissan', value: 'NISSAN' },
                            { label: 'Subaru', value: 'SUBARU' },
                            { label: 'Mitsubishi', value: 'MITSUBISHI' },
                            { label: 'Honda', value: 'HONDA' },
                            { label: 'Mercedes Benz', value: 'MERCEDES_BENZ' },
                            { label: 'BMW', value: 'BMW' },
                            { label: 'Audi', value: 'AUDI' },
                            { label: 'Volkswagen', value: 'VOLKSWAGEN' },
                            { label: 'Peugeot', value: 'PEUGEOT' },
                            { label: 'Suzuki', value: 'SUZUKI' },
                            { label: 'Hyundai', value: 'HYUNDAI' },
                            { label: 'Kia', value: 'KIA' },
                            { label: 'Ford', value: 'FORD' },
                            { label: 'Chevrolet', value: 'CHEVROLET' },
                            { label: 'Jeep', value: 'JEEP' },
                            { label: 'Range Rover', value: 'RANGE_ROVER' },
                            { label: 'Land Rover', value: 'LAND_ROVER' },
                            { label: 'Lexus', value: 'LEXUS' },
                            { label: 'Mazda', value: 'MAZDA' },
                            { label: 'Volvo', value: 'VOLVO' },
                            { label: 'Porsche', value: 'PORSCHE' },
                            { label: 'Jaguar', value: 'JAGUAR' },
                            { label: 'Mini', value: 'MINI' },
                            { label: 'Fiat', value: 'FIAT' },
                            { label: 'Skoda', value: 'SKODA' },
                            { label: 'Other', value: 'OTHER' },
                          ]
                        : [
                            { label: 'Bajaj', value: 'BAJAJ' },
                            { label: 'TVS', value: 'TVS' },
                            { label: 'Honda', value: 'HONDA' },
                            { label: 'Yamaha', value: 'YAMAHA' },
                            { label: 'Suzuki', value: 'SUZUKI' },
                            { label: 'Other', value: 'OTHER' },
                          ]
                    }
                    onChange={(e) => {
                      formState.errors['brand'] = '';
                      setValue('brand', e.value);
                      setvehicleAssetData({ ...vehicleAssetData, brand: e.value });
                      storage.setAnyFormValues('vehicleAssetData', {
                        ...vehicleAssetData,
                        brand: e.value,
                      });
                    }}
                  />
                )}
                {vehicleAssetData.vehicle_sub_category && vehicleAssetData.brand === 'OTHER' && (
                  <InputFieldWithInfo
                    label="Specify Other Brand"
                    error={formState.errors['specify_others']}
                    value={vehicleAssetData.specify_others}
                    registration={register('specify_others')}
                    caption="Specify a brand in case you can't find yours in the list"
                    onChange={(e) => {
                      formState.errors['specify_others'] = '';
                      setValue('specify_others', e.target.value);
                      setvehicleAssetData({
                        ...vehicleAssetData,
                        specify_others: e.target.value,
                      });
                      storage.setAnyFormValues('vehicleAssetData', {
                        ...vehicleAssetData,
                        specify_others: e.target.value,
                      });
                    }}
                  />
                )}
                {vehicleAssetData.vehicle_sub_category && (
                  <ReactSelectField2
                    label={
                      vehicleAssetData.vehicle_sub_category !== 'bikes'
                        ? 'Select car model'
                        : 'Select bike model'
                    }
                    type="text"
                    editorContent={watch('model')}
                    name="model"
                    defaultValue={vehicleAssetData.model}
                    error={formState.errors['model']}
                    registration={register('model')}
                    options={
                      vehicleAssetData.vehicle_sub_category !== 'bikes'
                        ? [
                            { label: 'Saloon', value: 'SALOON' },
                            { label: 'SUV', value: 'SUV' },
                            { label: 'Station Wagon', value: 'STATION_WAGON' },
                            { label: 'Pickup', value: 'PICKUP' },
                            { label: 'Van', value: 'VAN' },
                            { label: 'Truck', value: 'TRUCK' },
                            { label: 'Bus', value: 'BUS' },
                            { label: 'Other', value: 'OTHER' },
                          ]
                        : [
                            { label: 'Boda Boda', value: 'BODA_BODA' },
                            { label: 'Sport Bike', value: 'SPORT_BIKE' },
                            { label: 'Cruiser', value: 'CRUISER' },
                            { label: 'Dirt Bike', value: 'DIRT_BIKE' },
                            { label: 'Other', value: 'OTHER' },
                          ]
                    }
                    onChange={(e) => {
                      formState.errors['model'] = '';
                      setValue('model', e.value);
                      setvehicleAssetData({ ...vehicleAssetData, model: e.value });
                    }}
                  />
                )}
                {vehicleAssetData.vehicle_sub_category && (
                  <InputFieldWithInfo
                    type="number"
                    label="Year of Manufacture"
                    error={formState.errors['year_of_manufacture']}
                    registration={register('year_of_manufacture')}
                    value={vehicleAssetData.year_of_manufacture}
                    onChange={(e) => {
                      formState.errors['year_of_manufacture'] = '';
                      setValue('year_of_manufacture', parseInt(e.target.value));
                      setvehicleAssetData({
                        ...vehicleAssetData,
                        year_of_manufacture: e.target.value,
                      });
                    }}
                  />
                )}
                {vehicleAssetData.vehicle_sub_category && (
                  <ReactSelectField2
                    label="Select Mileage"
                    type="text"
                    editorContent={watch('mileage')}
                    name="mileage"
                    registration={register('mileage')}
                    defaultValue={vehicleAssetData.mileage}
                    error={formState.errors['mileage']}
                    options={[
                      { label: '0 - 90000', value: '0_90000' },
                      {
                        label: '91000 - 180000',
                        value: '91000_180000',
                      },
                      {
                        label: '181000 - 300000',
                        value: '181000_300000',
                      },
                    ]}
                    onChange={(e) => {
                      formState.errors['mileage'] = '';
                      setValue('mileage', e.value);
                      setvehicleAssetData({ ...vehicleAssetData, mileage: e.value });
                    }}
                  />
                )}
              </div>

              {vehicleAssetData.vehicle_sub_category && (
                <div className="">
                  <ReactSelectFieldWithInfo
                    label="Tracker"
                    caption="A tracker is installed for the safety of your vehicle. Would you like to hire or buy one?"
                    type="text"
                    editorContent={watch('tracker')}
                    name="tracker"
                    defaultValue={vehicleAssetData.tracker}
                    error={formState.errors['tracker']}
                    registration={register('tracker')}
                    infoTitle="Vehicle Tracker"
                    infoDescription="Tracker is installed for the safety of your vehicle. It helps in tracking the vehicle in case of theft."
                    options={[
                      { label: 'Hire', value: 'HIRE' },
                      { label: 'Buy', value: 'BUY' },
                    ]}
                    onChange={(e) => {
                      formState.errors['tracker'] = '';
                      setValue('tracker', e.value);
                      setvehicleAssetData({ ...vehicleAssetData, tracker: e.value });
                      storage.setAnyFormValues('vehicleAssetData', {
                        ...vehicleAssetData,
                        tracker: e.value,
                      });
                    }}
                  />
                  <br />
                  <RegularTextAreaField
                    label="Asset Description"
                    error={formState.errors['description']}
                    registration={register('description')}
                    caption="Any extra information you’d like us to know about your vehicle e.g Chasis No. This will
                guide us on serving you better."
                    value={vehicleAssetData.description}
                    onChange={(e) => {
                      formState.errors['description'] = '';
                      setValue('description', e.target.value);
                      setvehicleAssetData({
                        ...vehicleAssetData,
                        description: e.target.value,
                      });
                    }}
                  />
                  <br />
                  <ReactSelectField2
                    label="Parking Status"
                    type="text"
                    editorContent={watch('parking_status')}
                    name="parking_status"
                    defaultValue={vehicleAssetData.parking_status}
                    error={formState.errors['parking_status']}
                    registration={register('parking_status')}
                    options={[
                      { label: 'Not Parked', value: 'NOT_PARKED' },
                      { label: 'Parked', value: 'PARKED' },
                    ]}
                    onChange={(e) => {
                      formState.errors['parking_status'] = '';
                      setValue('parking_status', e.value);
                      setvehicleAssetData({
                        ...vehicleAssetData,
                        parking_status: e.value,
                      });
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-row space-x-2 justify-end mt-4">
            <Button type="submit">Next</Button>
          </div>
        </div>
      )}
    </Form>
  );
};

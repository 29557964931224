import * as z from 'zod';

export const landSchema = z.object({
  title: z
    .string({
      required_error: 'Title of Asset is required',
      invalid_type_error: 'Title of Asset must be a string',
    })
    .min(1, { message: 'Title of Asset cannot be empty' }),
  product: z
    .string({
      required_error: 'Product is required',
      invalid_type_error: 'Product must be a string',
    })
    .min(1, { message: 'Product cannot be empty' }),
  type_of_title: z
    .string({
      required_error: 'Type of title is required',
      invalid_type_error: 'Type of title must be a string',
    })
    .min(1, { message: 'Type of title cannot be empty' }),
  plot_no: z.string().optional(),
  block_no: z.string().optional(),
  size_of_land: z
    .string({
      required_error: 'Size of land is required',
      invalid_type_error: 'Size of land must be a string',
    })
    .min(1, { message: 'Size of land cannot be empty' }),
  whats_on_land: z
    .string({
      required_error: 'Whats on land is required',
      invalid_type_error: 'Whats on land must be a string',
    })
    .min(1, { message: 'Whats on land cannot be empty' }),
  specify_others: z.string().optional(),
  description: z
    .string({
      required_error: 'Asset description is required',
      invalid_type_error: 'Asset description must be a string',
    })
    .min(1, { message: 'Asset description cannot be empty' }),
});
export const landValuationSchema = z.object({
  purchase_amount: z.number({
    required_error: 'purchase amount is required',
    invalid_type_error: 'purchase amount must be a number',
  }),
  current_worth: z.number({
    required_error: 'current worth is required',
    invalid_type_error: 'current worth must be a number',
  }),
  purchase_date: z.string({
    required_error: 'purchase date is required',
    invalid_type_error: 'purchase date must be a number',
  }),
});
export const vehiclesAssetDetailsSchema = z.object({
  title: z
    .string({
      required_error: 'number plate is required',
      invalid_type_error: 'number plate must be a string',
    })
    .min(1, { message: 'number plate cannot be empty' }),
  product: z
    .string({
      required_error: 'Product is required',
      invalid_type_error: 'Product must be a string',
    })
    .min(1, { message: 'Product cannot be empty' }),
  vehicle_sub_category: z
    .string({
      required_error: 'vehicle category is required',
      invalid_type_error: 'vehicle category must be a string',
    })
    .min(1, { message: 'vehicle category cannot be empty' }),
  brand: z
    .string({
      required_error: 'Brand is required',
      invalid_type_error: 'Brand must be a string',
    })
    .min(1, { message: 'Brand cannot be empty' }),
  specify_others: z
    .string({
      invalid_type_error: 'Specify others must be a string',
    })
    .optional(),
  tracker: z
    .string({
      required_error: 'tracker is required',
      invalid_type_error: 'tracker must be a string',
    })
    .min(1, { message: 'tracker cannot be empty' }),
  model: z
    .string({
      required_error: 'model is required',
      invalid_type_error: 'model must be a string',
    })
    .min(1, { message: 'model cannot be empty' }),
  engine_no: z
    .string({
      required_error: 'engine number is required',
      invalid_type_error: 'engine number must be a string',
    })
    .min(1, { message: 'engine number cannot be empty' }),
  chasis_no: z
    .string({
      required_error: 'chasis number  is required',
      invalid_type_error: 'chasis number  must be a string',
    })
    .min(1, { message: 'chasis number  cannot be empty' }),
  no_plate: z
    .string({
      required_error: 'number plate is required',
      invalid_type_error: 'number plate must be a string',
    })
    .min(1, { message: 'number plate cannot be empty' }),
  color: z
    .string({
      required_error: 'color is required',
      invalid_type_error: 'color must be a string',
    })
    .min(1, { message: 'color cannot be empty' }),
  mileage: z
    .string({
      required_error: 'mileage is required',
      invalid_type_error: 'mileage must be a string',
    })
    .min(1, { message: 'mileage cannot be empty' }),
  parking_status: z
    .string({
      required_error: 'parking status is required',
      invalid_type_error: 'parking status must be a string',
    })
    .min(1, { message: 'parking status cannot be empty' }),
  year_of_manufacture: z
    .number({
      required_error: 'year of manufacture is required',
      invalid_type_error: 'year of manufacture must be a number',
    })
    .min(1, { message: 'year of manufacture cannot be empty' }),
  description: z
    .string({
      required_error: 'description is required',
      invalid_type_error: 'description must be a string',
    })
    .min(1, { message: 'description cannot be empty' }),
});
export const vehiclesPicturesSchema = z.object({
  imageinput0: z.string({
    required_error: 'Front view (show number plate) is required',
    invalid_type_error: 'Front view (show number plate) must be an image',
  }),
  imageinput1: z.string({
    required_error: 'Side view (both sides) is required',
    invalid_type_error: 'Side view (both sides) must be an image',
  }),
  imageinput2: z.string({
    required_error: 'Back view is required',
    invalid_type_error: 'Back view must be an image',
  }),
  imageinput3: z.string({
    required_error: 'Close-ups of any existing damage or unique features is required',
    invalid_type_error: 'Close-ups of any existing damage or unique features must be an image',
  }),
  imageinput4: z.string({
    required_error: "Driver's seat area is required",
    invalid_type_error: "Driver's seat area must be an image",
  }),
  imageinput5: z.string({
    required_error: 'Passenger seating area is required',
    invalid_type_error: 'Passenger seating area must be an image',
  }),
  imageinput6: z.string({
    required_error: 'Dashboard and control panel is required',
    invalid_type_error: 'Dashboard and control panel must be an image',
  }),
  imageinput7: z.string({
    required_error: 'Close-ups of odometer, indicating mileage is required',
    invalid_type_error: 'Close-ups of odometer, indicating mileage must be an image',
  }),
  imageinput8: z.string({
    required_error: 'Underside of vehicle is required',
    invalid_type_error: 'Underside of vehicle must be an image',
  }),
  imageinput9: z.string({
    required_error: 'Close-up of Wheels and Tyres is required',
    invalid_type_error: 'Close-up of Wheels and Tyres must be an image',
  }),
  imageinput10: z.string({
    required_error: 'Engine is required',
    invalid_type_error: 'Engine must be an image',
  }),
  imageinput11: z.string({
    required_error: 'Logbook is required',
    invalid_type_error: 'Logbook must be an image',
  }),
});
export const vehicleValuationSchema = z.object({
  purchase_amount: z.number({
    required_error: 'purchase amount is required',
    invalid_type_error: 'purchase amount must be a number',
  }),
  current_worth: z.number({
    required_error: 'current worth is required',
    invalid_type_error: 'current worth must be a number',
  }),
  purchase_date: z.string({
    required_error: 'purchase date is required',
    invalid_type_error: 'purchase date must be a number',
  }),
});
export const smartPhoneSchema = z.object({
  title: z
    .string({
      required_error: 'title is required',
      invalid_type_error: 'title must be a string',
    })
    .min(1, { message: 'title cannot be empty' }),
  product: z
    .string({
      required_error: 'Product is required',
      invalid_type_error: 'Product must be a string',
    })
    .min(1, { message: 'Product cannot be empty' }),
  brand: z
    .string({
      required_error: 'Brand is required',
      invalid_type_error: 'Brand must be a string',
    })
    .min(1, { message: 'Brand cannot be empty' }),
  specify_others: z
    .string({
      invalid_type_error: 'Specify others must be a string',
    })
    .optional(),
  ram_size: z
    .string({
      required_error: 'RAM Size is required',
      invalid_type_error: 'RAM Size must be a string',
    })
    .min(1, { message: 'RAM Size cannot be empty' }),
  model: z
    .string({
      required_error: 'model is required',
      invalid_type_error: 'model must be a string',
    })
    .min(1, { message: 'model cannot be empty' }),
  storage_space: z
    .string({
      required_error: 'Storage Space is required',
      invalid_type_error: 'Storage Space must be a string',
    })
    .min(1, { message: 'Storage Space cannot be empty' }),
  description: z
    .string({
      required_error: 'description is required',
      invalid_type_error: 'description must be a string',
    })
    .min(1, { message: 'description cannot be empty' }),
});
export const smartPhonePicturesSchema = z.object({
  imageinput0: z.string({
    required_error: 'Image 1 is required',
    invalid_type_error: 'Image 1 must be an image',
  }),
  imageinput1: z.string({
    required_error: 'Image 2 is required',
    invalid_type_error: 'Image 2 must be an image',
  }),
  imageinput2: z.string({
    required_error: 'Image 3 is required',
    invalid_type_error: 'Image 3 must be an image',
  }),
  imageinput3: z.string({
    required_error: 'Image 4 is required',
    invalid_type_error: 'Image 4 must be an image',
  }),
});
export const smartPhoneValuationSchema = z.object({
  purchase_amount: z.number({
    required_error: 'purchase amount is required',
    invalid_type_error: 'purchase amount must be a number',
  }),
  current_worth: z.number({
    required_error: 'current worth is required',
    invalid_type_error: 'current worth must be a number',
  }),
  purchase_date: z.string({
    required_error: 'purchase date is required',
    invalid_type_error: 'purchase date must be a number',
  }),
});
export const televisionSchema = z.object({
  title: z
    .string({
      required_error: 'title is required',
      invalid_type_error: 'title must be a string',
    })
    .min(1, { message: 'title cannot be empty' }),
  product: z
    .string({
      required_error: 'Product is required',
      invalid_type_error: 'Product must be a string',
    })
    .min(1, { message: 'Product cannot be empty' }),
  brand: z
    .string({
      required_error: 'Brand is required',
      invalid_type_error: 'Brand must be a string',
    })
    .min(1, { message: 'Brand cannot be empty' }),
  specify_others: z
    .string({
      invalid_type_error: 'Specify others must be a string',
    })
    .optional(),
  dimension: z
    .string({
      required_error: 'Dimension is required',
      invalid_type_error: 'Dimension must be a string',
    })
    .min(1, { message: 'Dimension cannot be empty' }),
  smart_tv: z.boolean({
    required_error: 'Smart Tv is required',
    invalid_type_error: 'Smart Tv must be a boolean',
  }),
  description: z
    .string({
      required_error: 'description is required',
      invalid_type_error: 'description must be a string',
    })
    .min(1, { message: 'description cannot be empty' }),
});
export const televisionPicturesSchema = z.object({
  imageinput0: z.string({
    required_error: 'Image 1 is required',
    invalid_type_error: 'Image 1 must be an image',
  }),
  imageinput1: z.string({
    required_error: 'Image 2 is required',
    invalid_type_error: 'Image 2 must be an image',
  }),
  imageinput2: z.string({
    required_error: 'Image 3 is required',
    invalid_type_error: 'Image 3 must be an image',
  }),
  imageinput3: z.string({
    required_error: 'Image 4 is required',
    invalid_type_error: 'Image 4 must be an image',
  }),
});
export const televisionValuationSchema = z.object({
  purchase_amount: z.number({
    required_error: 'purchase amount is required',
    invalid_type_error: 'purchase amount must be a number',
  }),
  current_worth: z.number({
    required_error: 'current worth is required',
    invalid_type_error: 'current worth must be a number',
  }),
  purchase_date: z.string({
    required_error: 'purchase date is required',
    invalid_type_error: 'purchase date must be a number',
  }),
});
export const computersSchema = z.object({
  title: z
    .string({
      required_error: 'title is required',
      invalid_type_error: 'title must be a string',
    })
    .min(1, { message: 'title cannot be empty' }),
  product: z
    .string({
      required_error: 'Product is required',
      invalid_type_error: 'Product must be a string',
    })
    .min(1, { message: 'Product cannot be empty' }),
  brand: z
    .string({
      required_error: 'Brand is required',
      invalid_type_error: 'Brand must be a string',
    })
    .min(1, { message: 'Brand cannot be empty' }),
  specify_others: z
    .string({
      invalid_type_error: 'Specify others must be a string',
    })
    .optional(),
  ram_size: z
    .string({
      required_error: 'RAM Size is required',
      invalid_type_error: 'RAM Size must be a string',
    })
    .min(1, { message: 'RAM Size cannot be empty' }),
  processor_speed: z
    .string({
      required_error: 'Processor speed is required',
      invalid_type_error: 'Processor speed must be a string',
    })
    .min(1, { message: 'Processor speed cannot be empty' }),
  hard_disk_space: z
    .string({
      required_error: 'Hard disk Space is required',
      invalid_type_error: 'Hard disk Space must be a string',
    })
    .min(1, { message: 'Hard disk Space cannot be empty' }),
  description: z
    .string({
      required_error: 'description is required',
      invalid_type_error: 'description must be a string',
    })
    .min(1, { message: 'description cannot be empty' }),
});
export const computerPicturesSchema = z.object({
  imageinput0: z.string({
    required_error: 'Image 1 is required',
    invalid_type_error: 'Image 1 must be an image',
  }),
  imageinput1: z.string({
    required_error: 'Image 2 is required',
    invalid_type_error: 'Image 2 must be an image',
  }),
  imageinput2: z.string({
    required_error: 'Image 3 is required',
    invalid_type_error: 'Image 3 must be an image',
  }),
  imageinput3: z.string({
    required_error: 'Image 4 is required',
    invalid_type_error: 'Image 4 must be an image',
  }),
});
export const computerValuationSchema = z.object({
  purchase_amount: z.number({
    required_error: 'purchase amount is required',
    invalid_type_error: 'purchase amount must be a number',
  }),
  current_worth: z.number({
    required_error: 'current worth is required',
    invalid_type_error: 'current worth must be a number',
  }),
  purchase_date: z.string({
    required_error: 'purchase date is required',
    invalid_type_error: 'purchase date must be a number',
  }),
});
export const otherElectronicsSchema = z.object({
  title: z
    .string({
      required_error: 'title is required',
      invalid_type_error: 'title must be a string',
    })
    .min(1, { message: 'title cannot be empty' }),
  product: z
    .string({
      required_error: 'Product is required',
      invalid_type_error: 'Product must be a string',
    })
    .min(1, { message: 'Product cannot be empty' }),
  nature_of_electronic: z
    .string({
      required_error: 'Nature of Electronic is required',
      invalid_type_error: 'Nature of Electronic must be a string',
    })
    .min(1, { message: 'Nature of Electronic cannot be empty' }),
  specify_others: z
    .string({
      invalid_type_error: 'Specify others must be a string',
    })
    .optional(),
  model_type: z
    .string({
      required_error: 'Model Type is required',
      invalid_type_error: 'Model Type must be a string',
    })
    .min(1, { message: 'Model Type cannot be empty' }),
  description: z
    .string({
      required_error: 'description is required',
      invalid_type_error: 'description must be a string',
    })
    .min(1, { message: 'description cannot be empty' }),
});
export const otherElectronicsPicturesSchema = z.object({
  imageinput0: z.string({
    required_error: 'Image 1 is required',
    invalid_type_error: 'Image 1 must be an image',
  }),
  imageinput1: z.string({
    required_error: 'Image 2 is required',
    invalid_type_error: 'Image 2 must be an image',
  }),
  imageinput2: z.string({
    required_error: 'Image 3 is required',
    invalid_type_error: 'Image 3 must be an image',
  }),
  imageinput3: z.string({
    required_error: 'Image 4 is required',
    invalid_type_error: 'Image 4 must be an image',
  }),
});
export const otherElectronicsValuationSchema = z.object({
  purchase_amount: z.number({
    required_error: 'purchase amount is required',
    invalid_type_error: 'purchase amount must be a number',
  }),
  current_worth: z.number({
    required_error: 'current worth is required',
    invalid_type_error: 'current worth must be a number',
  }),
  purchase_date: z.string({
    required_error: 'purchase date is required',
    invalid_type_error: 'purchase date must be a number',
  }),
});

import { Button } from 'components/Elements';
import { Form } from 'components/Form';
import React, { useRef, useState } from 'react';
import storage from 'utils/storage';
import Swal from 'sweetalert2';

export const VideoCall = ({ prevStep, nextStep }) => {
  const [videoDates, setVideoDates] = useState([]);
  const [timeSlots, setTimeSlots] = useState([{ hours: 9, minutes: 0, meridiem: 'AM' }]);

  const handleAddVideoDate = () => {
    if (videoDates.length < 2) {
      setVideoDates([...videoDates, '']);
      setTimeSlots([...timeSlots, { hours: 9, minutes: 0, meridiem: 'AM' }]);
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'You can only select 2 dates for the introductory video call',
      });
    }
  };
  const handleAddTimeSlot = () => {
    setTimeSlots([...timeSlots, { startTime: '', endTime: '' }]);
  };

  const values = storage.getFormValues();

  const handleTimeSlotChange = (index, field, value) => {
    const updatedTimeSlots = [...timeSlots];

    updatedTimeSlots[index][field] = value;
    setTimeSlots(updatedTimeSlots);
  };

  const handleMeridiemChange = (index, value) => {
    const updatedTimeSlots = [...timeSlots];

    updatedTimeSlots[index].meridiem = value;
    setTimeSlots(updatedTimeSlots);
  };

  return (
    <div className="mx-4 md:mx-12">
      <Form
        id="create-loan"
        onSubmit={async () => {
          storage.setFormValues({
            introductory_video_call_scheduled_dates: videoDates.map((date, index) => ({
              date,
              time_slots: timeSlots[index],
            })),
          });
          nextStep();
        }}
      >
        {({ formState, register }) => (
          <div className="">
            <div className="mx-8">
              {' '}
              <div className="text-lg font-semibold text">
                Select Dates for an introductory Video Call
              </div>
              <div className="">
                Please select at least 2 dates and set time for your availability and the assigned
                loan officer will confirm one and also send you a meeting link.{' '}
              </div>
            </div>

            <div className="mb-4">
              <div className="mx-8 flex flex-col space-y-2">
                <div className="bg-white shadow-md mb-4 rounded-2xl p-4">
                  <div className="flex flex-row justify-between space-x-8 items-center mb-4 rounded-2xl px-5 bg-gray-100 p-2">
                    <div className="flex flex-col space-y-2 md:space-y-0 md:flex-row md:items-center md:space-x-2">
                      <div className="">Set availability</div>
                      <Button size="sm" variant="outline" onClick={handleAddVideoDate}>
                        New Date
                      </Button>
                    </div>
                  </div>
                  {videoDates.map((date, index) => (
                    <div
                      className="flex flex-col space-y-1 md:flex-row md:space-y-0 md:space-x-2 mb-3"
                      key={index}
                    >
                      <input
                        type="date"
                        error={formState.errors['videoDates']}
                        registration={register(`videoDates[${index}]`)}
                        onChange={(e) => {
                          const updatedDates = [...videoDates];
                          updatedDates[index] = e.target.value;
                          setVideoDates(updatedDates);
                        }}
                        className="border border-gray-200 rounded-md shadow-sm p-2 w-full mb-2"
                      />
                      {timeSlots[index] ? (
                        <div className="flex flex-row items-center space-x-2">
                          <select
                            value={timeSlots[index].hours}
                            onChange={(e) => handleTimeSlotChange(index, 'hours', e.target.value)}
                            className="border border-gray-200 rounded-md shadow-sm p-2"
                          >
                            {Array.from({ length: 12 }, (_, i) => i + 1).map((hour) => (
                              <option key={hour} value={hour}>
                                {hour}
                              </option>
                            ))}
                          </select>
                          <span>:</span>
                          <select
                            value={timeSlots[index].minutes}
                            onChange={(e) => handleTimeSlotChange(index, 'minutes', e.target.value)}
                            className="border border-gray-200 rounded-md shadow-sm p-2"
                          >
                            {Array.from({ length: 60 }, (_, i) => i).map((minute) => (
                              <option key={minute} value={minute}>
                                {minute < 10 ? `0${minute}` : minute}
                              </option>
                            ))}
                          </select>
                          <select
                            value={timeSlots[index].meridiem}
                            onChange={(e) => handleMeridiemChange(index, e.target.value)} // Use handleMeridiemChange here
                            className="border border-gray-200 rounded-md shadow-sm p-2"
                          >
                            <option value="AM">AM</option>
                            <option value="PM">PM</option>
                          </select>
                        </div>
                      ) : (
                        <Button size="sm" variant="outline" onClick={handleAddTimeSlot}>
                          Set Available Time
                        </Button>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="mx-8 md:mx-56 flex flex-col space-y-3">
              <div className="flex flex-row items-center space-x-2">
                <Button variant="outline" onClick={() => prevStep()} className="w-full">
                  Previous
                </Button>
                <Button variant="dark" type="submit">
                  Next
                </Button>
              </div>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};

import { Button, Spinner } from 'components/Elements';
import { Form, InputFieldWithInfo, RegularTextAreaField } from 'components/Form';
import { ReactSelectField2 } from 'components/Pigeon';
import { smartPhoneSchema } from 'features/assets/schemas';
import { useProducts } from 'features/products';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import storage from 'utils/storage';
import { COLLATERAL } from 'variables';
import { defaults } from 'features/assets/data/defaults';
import phoneBrands from '../../data/phoneBrands.json';
import phoneModels from '../../data/phoneModels.json';

export const SmartPhoneAssetDetailsForm = ({ onSuccess }) => {
  const [smartPhoneAssetData, setSmartPhoneAssetData] = useState({
    title: '',
    product: '',
    selectedProduct: '',
    brand: '',
    selectedBrand: '',
    specify_others: '',
    ram_size: '',
    selectedram_size: '',
    model: '',
    selectedModel: '',
    storage_space: '',
    selectedstorage_space: '',
    description: '',
  });

  const productsQuery = useProducts();

  useEffect(() => {
    const getData = async () => {
      const assetCategory = localStorage.getItem('assetCategory');
      if (assetCategory) {
        if (assetCategory === COLLATERAL.CATEGORIES.SMART_PHONES) {
          const result = storage.getOtherValues('smartPhoneAssetData');
          setSmartPhoneAssetData(result);
        }
      }
    };
    getData();
  }, []);

  if (productsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="md" />
      </div>
    );
  }

  if (!productsQuery?.data) return null;

  return (
    <Form
      id="create-post"
      onSubmit={async (values) => {
        const dt = storage.getOtherValues('smartPhoneAssetData');
        localStorage.setItem('formStep', 'pictures');
        onSuccess();
      }}
      defaultValues={{
        title: smartPhoneAssetData.title,
        product: smartPhoneAssetData.product,
        brand: smartPhoneAssetData.brand,
        specify_others: smartPhoneAssetData.specify_others,
        ram_size: smartPhoneAssetData.ram_size,
        model: smartPhoneAssetData.model,
        storage_space: smartPhoneAssetData.storage_space,
        description: smartPhoneAssetData.description,
      }}
      schema={smartPhoneSchema}
    >
      {({ register, formState, watch, setValue }) => (
        <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
          <div className="mb-4">Asset Details for Smart Phone</div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="space-y-5">
              <InputFieldWithInfo
                label="Title of Asset"
                error={formState.errors['title']}
                registration={register('title')}
                caption="Enter a concise title for your asset. This will help us identify it easily and efficiently."
                onChange={(e) => {
                  formState.errors['title'] = '';
                  setValue('title', e.target.value);
                  // console.log('title data', { ...vehicleAssetData, title: e.target.value })
                  const dt = { ...smartPhoneAssetData, title: e.target.value };
                  setSmartPhoneAssetData(dt);
                  storage.setAnyFormValues('smartPhoneAssetData', dt);
                }}
              />
              <ReactSelectField2
                label="Please select Product"
                type="text"
                editorContent={watch('product')}
                name="product"
                defaultValue={smartPhoneAssetData.selectedProduct}
                error={formState.errors['product']}
                registration={register('product')}
                options={productsQuery.data?.map((p) => ({
                  label: p.product_title,
                  value: p._id,
                }))}
                onChange={async (e) => {
                  const selectedProduct = await productsQuery.data
                    ?.map((p) => ({
                      label: p.product_title,
                      value: p._id,
                    }))
                    ?.find((p) => p.value === e.value);
                  setValue('product', e.value);
                  formState.errors['product'] = '';
                  setSmartPhoneAssetData({
                    ...smartPhoneAssetData,
                    product: e.value,
                    selectedProduct: selectedProduct,
                  });
                  storage.setAnyFormValues('smartPhoneAssetData', {
                    product: e.value,
                    selectedProduct: selectedProduct,
                  });
                }}
              />
              <ReactSelectField2
                label="Select Mobile Phone Brand"
                type="text"
                editorContent={watch('brand')}
                name="brand"
                defaultValue={smartPhoneAssetData.selectedBrand}
                error={formState.errors['brand']}
                registration={register('brand')}
                options={phoneBrands}
                onChange={async (e) => {
                  const selectedProduct = await phoneBrands.find((p) => p.value === e.value);
                  setValue('brand', e.value);
                  formState.errors['brand'] = '';
                  setSmartPhoneAssetData({
                    ...smartPhoneAssetData,
                    brand: e.value,
                    selectedBrand: selectedProduct,
                  });
                  storage.setAnyFormValues('smartPhoneAssetData', {
                    brand: e.value,
                    selectedBrand: selectedProduct,
                  });
                }}
              />
              {smartPhoneAssetData.brand === 'OTHER' && (
                <InputFieldWithInfo
                  label="Specify Other Brand"
                  error={formState.errors['specify_others']}
                  registration={register('specify_others')}
                  caption="Specify a brand in case you can't find yours in the list"
                  onChange={(e) => {
                    formState.errors['specify_others'] = '';
                    setValue('specify_others', e.target.value);
                    // console.log('specify_others data', { ...vehicleAssetData, specify_others: e.target.value })
                    const dt = {
                      ...smartPhoneAssetData,
                      specify_others: e.target.value,
                    };
                    setSmartPhoneAssetData(dt);
                    storage.setAnyFormValues('smartPhoneAssetData', dt);
                  }}
                />
              )}
              <ReactSelectField2
                label="RAM Size"
                type="text"
                editorContent={watch('ram_size')}
                name="ram_size"
                defaultValue={smartPhoneAssetData.selectedram_size}
                error={formState.errors['ram_size']}
                registration={register('ram_size')}
                options={defaults.RAMSize}
                onChange={async (e) => {
                  const selectedProduct = defaults.RAMSize.find((p) => p.value === e.value);
                  setValue('ram_size', e.value);
                  formState.errors['ram_size'] = '';
                  setSmartPhoneAssetData({
                    ...smartPhoneAssetData,
                    ram_size: e.value,
                    selectedram_size: selectedProduct,
                  });
                  storage.setAnyFormValues('smartPhoneAssetData', {
                    ram_size: e.value,
                    selectedram_size: selectedProduct,
                  });
                }}
              />
              <ReactSelectField2
                label="Select Phone Model"
                type="text"
                editorContent={watch('model')}
                name="model"
                defaultValue={smartPhoneAssetData.selectedModel}
                error={formState.errors['model']}
                registration={register('model')}
                options={phoneModels}
                onChange={async (e) => {
                  const selectedProduct = await phoneModels.find((p) => p.value === e.value);
                  setValue('model', e.value);
                  formState.errors['model'] = '';
                  setSmartPhoneAssetData({
                    ...smartPhoneAssetData,
                    model: e.value,
                    selectedModel: selectedProduct,
                  });
                  storage.setAnyFormValues('smartPhoneAssetData', {
                    model: e.value,
                    selectedModel: selectedProduct,
                  });
                }}
              />
              <ReactSelectField2
                label="Storage Space"
                type="text"
                editorContent={watch('storage_space')}
                name="storage_space"
                defaultValue={smartPhoneAssetData.selectedstorage_space}
                error={formState.errors['storage_space']}
                registration={register('storage_space')}
                options={defaults.storageSpace}
                onChange={async (e) => {
                  const selectedProduct = defaults.storageSpace.find((p) => p.value === e.value);
                  setValue('storage_space', e.value);
                  formState.errors['storage_space'] = '';
                  setSmartPhoneAssetData({
                    ...smartPhoneAssetData,
                    storage_space: e.value,
                    selectedstorage_space: selectedProduct,
                  });
                  storage.setAnyFormValues('smartPhoneAssetData', {
                    storage_space: e.value,
                    selectedstorage_space: selectedProduct,
                  });
                }}
              />
            </div>
            <div className="space-y-5">
              <RegularTextAreaField
                label="Asset Description"
                error={formState.errors['description']}
                registration={register('description')}
                caption="Any extra information you’d like us to know about your smart phone. This will
                guide us on serving you better."
                value={smartPhoneAssetData.description}
                onChange={(e) => {
                  formState.errors['description'] = '';
                  setValue('description', e.target.value);
                  setSmartPhoneAssetData({
                    ...smartPhoneAssetData,
                    description: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex flex-row space-x-2 justify-end mt-4">
            <Button type="submit">Next</Button>
          </div>
        </div>
      )}
    </Form>
  );
};

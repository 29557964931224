import React from 'react';
import { Link } from 'react-router-dom';

export const Legal = () => {
  return (
    <div className="mb-6 p-4 bg-gray-100 border-l-4 border-primary-100 rounded-lg">
      <h2 className="text-md font-bold text-primary-100 mb-2">Legal Information</h2>
      <p className="text-gray-700 text-xs mb-2">
        By proceeding, you confirm that the collateral asset you are about to add:
      </p>
      <ul className="text-xs list-disc list-inside text-gray-600 mb-2">
        <li>Belongs to you or you have the legal authority to pledge it as collateral.</li>
        <li>Is free of any liens or encumbrances, unless otherwise disclosed.</li>
        <li>Complies with local regulations regarding ownership and collateralization.</li>
      </ul>
      <p className="text-xs text-gray-700">
        Please note: By submitting this asset, you consent to its verification and agree to our{' '}
        <Link to="/terms-and-conditions" className="text-blue-600 hover:underline">
          Borrower Terms and Conditions
        </Link>{' '}
        and{' '}
        <Link to="/general-policy" className="text-blue-600 hover:underline">
          General Policy
        </Link>
        . Misrepresentation may result in legal action.
      </p>
    </div>
  );
};

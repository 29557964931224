import { useNavigate } from 'react-router-dom';

import { Layout } from '../components/Layout';
import { RegisterForm } from '../components/RegisterFormV2';

export const Register = () => {
  const navigate = useNavigate();

  return (
    <Layout title="Get started with Finablr">
      <div className="">
        <RegisterForm onSuccess={() => navigate('/please-confirm-email')} />
      </div>
    </Layout>
  );
};

import { Button } from 'components/Elements';
import { Form, InputFieldWithInfo, ReactNumberFormatInputField2 } from 'components/Form';
import { useCreateAsset } from 'features/assets/api/createAsset';
import { vehicleValuationSchema } from 'features/assets/schemas';
import React from 'react';
import { dataURItoBlob } from 'utils/dataURIToBlob';

export const VehicleValuationForm = ({ onSuccess, onBack, selectedImages }) => {
  const createAssetMutation = useCreateAsset();

  return (
    <Form
      id="create-post"
      onSubmit={async (values) => {
        const vehicleAssetData = JSON.parse(localStorage.getItem('vehicleAssetData'));

        const category = localStorage.getItem('assetCategory');
        // console.log('data', values, vehicleAssetData)
        const bodyFormData = new FormData();
        if (selectedImages) {
          selectedImages.forEach((image, index) => {
            if (image) {
              bodyFormData.append(`pictures`, dataURItoBlob(image));
            }
          });
        }
        bodyFormData.append('title', vehicleAssetData.title);
        bodyFormData.append('category', category);
        bodyFormData.append('vehicle_sub_category', vehicleAssetData.vehicle_sub_category);
        bodyFormData.append('brand', vehicleAssetData.brand);
        bodyFormData.append('product', vehicleAssetData.product);
        bodyFormData.append('mileage', vehicleAssetData.mileage);
        bodyFormData.append('model', vehicleAssetData.model);
        bodyFormData.append('year_of_manufacture', vehicleAssetData.year_of_manufacture);
        bodyFormData.append('color', vehicleAssetData.color);
        bodyFormData.append('no_plate', vehicleAssetData.no_plate);
        bodyFormData.append('engine_no', vehicleAssetData.engine_no);
        bodyFormData.append('chasis_no', vehicleAssetData.chasis_no);
        bodyFormData.append('description', vehicleAssetData.description);
        bodyFormData.append('purchase_amount', values.purchase_amount);
        bodyFormData.append('current_worth', values.current_worth);
        bodyFormData.append('purchase_date', values.purchase_date);
        bodyFormData.append('specify_others', vehicleAssetData.specify_others);
        bodyFormData.append('tracker', vehicleAssetData.tracker);
        // bodyFormData.append('color', vehicleAssetData.color);
        bodyFormData.append('parking_status', vehicleAssetData.parking_status);

        await createAssetMutation.mutateAsync({ data: bodyFormData }).then(async (data) => {
          localStorage.removeItem('assetCategory');
          localStorage.removeItem('formStep');
          localStorage.removeItem('vehicleAssetData');
          localStorage.removeItem('assetCategory');

          onSuccess();
        });
      }}
      schema={vehicleValuationSchema}
    >
      {({ register, formState, watch, setValue }) => (
        <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
          <div className="mb-4">Valuation</div>
          <div className="mb-2">
            To enhance your prospects of securing a loan, it's imperative to furnish precise and
            comprehensive information.
          </div>
          <div className="md:mx-48">
            <div className="">
              <div className="flex flex-row items-center justify-between mt-3">
                <div className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                  How much did you buy the vehicle? (UGX)
                </div>
              </div>
              <ReactNumberFormatInputField2
                prefix="UGX "
                name="purchase_amount"
                // value={amount}
                error={formState.errors['purchase_amount']}
                registration={register('purchase_amount')}
                onChange={(event) => {
                  //console.log('amount', event);
                  formState.errors['purchase_amount'] = '';
                  setValue('purchase_amount', event.floatValue);
                }}
              />
            </div>
            <div className="mb-2">
              <div className="flex flex-row items-center justify-between mt-3">
                <div className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                  How much is it worth currently? (UGX)
                </div>
              </div>
              <ReactNumberFormatInputField2
                prefix="UGX "
                name="current_worth"
                // value={amount}
                error={formState.errors['current_worth']}
                registration={register('current_worth')}
                onChange={(event) => {
                  console.log('amount', event);
                  formState.errors['current_worth'] = '';
                  setValue('current_worth', event.floatValue);
                }}
              />
            </div>
            <InputFieldWithInfo
              type="date"
              label="When did you buy it? (DD/MM/YYYY)"
              caption="Please provide a date in the format (Date / Month / Year)"
              error={formState.errors['purchase_date']}
              registration={register('purchase_date')}
              onChange={(event) => {
                //console.log('amount', event);
                formState.errors['purchase_date'] = '';
                setValue('purchase_date', event.target.value);
              }}
            />
          </div>
          <div className="flex flex-row space-x-2 justify-end mt-4">
            <Button
              variant="outline"
              onClick={() => {
                onBack();
              }}
            >
              Back
            </Button>
            <Button isLoading={createAssetMutation.isLoading} type="submit">
              Finish
            </Button>
          </div>
        </div>
      )}
    </Form>
  );
};

import * as z from 'zod';
import { Button, Spinner } from 'components/Elements';
import { CheckboxField, Form } from 'components/Form';
import { useGuarantor } from '../../api/getGuarantor';
import React, { useState } from 'react';
import { formatText } from 'utils/formatText';
import storage from 'utils/storage';

const schema = z.object({
  acceptTerms: z.boolean(),
});

export const TermsAndConditions = ({ nextStep, prevStep }) => {
  const values = storage.getFormValues();
  const values2 = storage.getOtherValues('guarantorData');
  // console.log('values2', values2)
  const [acceptTerms, setAcceptTerms] = useState(false);

  const guarantorQuery = useGuarantor({ phone: values2?.phoneNumber });

  if (guarantorQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!guarantorQuery.data) return null;

  return (
    <div className="px-4 md:px-24 md:pt-12">
      <div className="text-lg mb-6">Review your loan application</div>
      <div className="border border-gray-700 rounded-md shadow bg-white p-2">
        <div className="rounded-xl border border-gray-200 mb-4">
          <div className="bg-primary-100 p-2 rounded-t-xl text-white">Loan Details</div>
          <div className="p-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
              <div className="text-xs md:text-sm">Payment Method</div>
              <div className="text-sm md:text-md font-semibold">
                {formatText(values.paymentMethod)}
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
              <div className="text-xs md:text-sm">Amount Required</div>
              <div className="text-sm md:text-md font-semibold">
                UGX {values.amount?.toLocaleString()}
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
              <div className="text-xs md:text-sm">Monthly Installment</div>
              <div className="text-sm md:text-md font-semibold">
                UGX {values.monthlyPayment?.toLocaleString()}
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
              <div className="text-xs md:text-sm">Interest Rate</div>
              <div className="text-sm md:text-md font-semibold">{values.interestRate} %</div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
              <div className="text-xs md:text-sm">Duration</div>
              <div className="text-sm md:text-md font-semibold">{values.period} months</div>
            </div>
          </div>
        </div>

        <div className="rounded-xl border border-gray-200 mb-4">
          <div className="bg-primary-100 p-2 rounded-t-xl text-white">Guarantor</div>
          <div className="p-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
              <div className="text-xs md:text-sm">Name</div>
              <div className="font-semibold text-sm md:text-md">{guarantorQuery.data?.name}</div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
              <div className="text-xs md:text-sm">Phone Number</div>
              <div className="font-semibold text-sm md:text-md">
                {guarantorQuery.data?.phoneNumber}
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
              <div className="text-xs md:text-sm">Email Address</div>
              <div className="font-semibold text-sm md:text-md overflow-hidden">
                {guarantorQuery.data?.email}
              </div>
            </div>
          </div>
        </div>
        <Form
          id="create-loan"
          onSubmit={async () => {
            storage.setFormValues({
              acceptTerms,
            });
            nextStep();
          }}
          schema={schema}
        >
          {({ register, formState, setValue }) => (
            <>
              <div
                className="rounded-xl border border-gray-200 mb-4"
                style={{ height: '300px', overflow: 'hidden', overflowY: 'scroll' }}
              >
                <div className="bg-primary-100 p-2 rounded-t-xl text-white">
                  Terms and Conditions
                </div>
                <div className="p-4">
                  <ul className="list-disc pl-4 text-sm">
                    <li>
                      <strong>Introduction:</strong> By using the platform, you agree to a binding
                      agreement with Enabling Finance Limited.
                    </li>
                    <li>
                      <strong>Loan Terms:</strong> Loans are secured with collateral and have a
                      maximum three-year term.
                    </li>
                    <li>
                      <strong>Payments and Fees:</strong> Payments are made to designated accounts;
                      late fees and processing fees apply.
                    </li>
                    <li>
                      <strong>Collateral Security:</strong> Collateral is held by EFL until full
                      repayment; disposal occurs upon default.
                    </li>
                    <li>
                      <strong>Default and Collection:</strong> Missed payments or fraud may result
                      in collateral attachment and disposal.
                    </li>
                    <li>
                      <strong>Electronic Transactions:</strong> All transactions and disclosures are
                      electronic.
                    </li>
                    <li>
                      <strong>Dispute Resolution:</strong> Disputes are resolved through arbitration
                      or Ugandan courts.
                    </li>
                    <li>
                      <strong>Limitation of Liability:</strong> EFL is not responsible for indirect
                      damages or delays in government processes.
                    </li>
                    <li>
                      <strong>Complaints:</strong> Submit complaints via email or post; resolution
                      within 10 business days.
                    </li>
                  </ul>
                  <div className="text-sm md:text-md mt-4">
                    For a detailed understanding of the borrower terms and conditions, you can
                    access the full document by clicking{' '}
                    <a
                      href="https://docs.google.com/document/d/e/2PACX-1vTPrWi2ccLjcc-e5AqkuOiDw0fFBSmSdSO-5CVMMPx4qTy1z1vVpIUQeobn5FKmrAxLhRPpnPioQx0c/pub"
                      target="_blank"
                      className="text-primary-100 hover:underline"
                    >
                      here
                    </a>
                    . This is a summarized version for your convenience.
                  </div>
                </div>
                <div className="m-2">
                  <CheckboxField
                    label="Accept Terms and Conditions"
                    registration={register('acceptTerms')}
                    error={formState.errors['acceptTerms']}
                    values={acceptTerms}
                    name="acceptTerms"
                    onChange={(e) => {
                      setAcceptTerms(!acceptTerms);
                      setValue('acceptTerms', !acceptTerms);
                    }}
                  />
                </div>
              </div>
              <div className="mx-0 md:mx-32 flex flex-col space-y-3">
                <div className="flex flex-row items-center space-x-2">
                  <Button variant="outline" onClick={() => prevStep()} className="w-full">
                    Previous
                  </Button>
                  <Button disabled={!acceptTerms} variant="dark" type="submit" className="w-full">
                    Next
                  </Button>
                </div>
              </div>
            </>
          )}
        </Form>
      </div>
    </div>
  );
};

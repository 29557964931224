import { Link } from 'components/Elements';
import React from 'react';

export const Disclaimer = () => {
  return (
    <div className="mb-6 p-4 bg-gray-100 border-l-4 border-primary-100 rounded-lg">
      <h2 className="text-md font-bold text-primary-100 mb-2">Disclaimer</h2>

      <p className="text-xs text-gray-700 mb-2">
        We value your privacy and the security of your information. The images and details of your
        collateral assets submitted during your loan application process will only be used for:
      </p>
      <ul className="text-xs list-disc list-inside text-gray-600 mb-2">
        <li>Verification and evaluation by our loan assessment team.</li>
        <li>Presentation to approved lenders once your loan application has been approved.</li>
      </ul>
      <p className="text-xs text-gray-700 mb-2">
        Your collateral asset images will <strong>not</strong> be used for any other purposes, such
        as marketing or third-party sharing, without your explicit consent.
      </p>
      <p className="text-xs text-gray-700">
        By proceeding, you confirm that you understand and agree to our{' '}
        <Link to="/terms-and-conditions" className="text-blue-600 hover:underline">
          Borrower Terms and Conditions
        </Link>{' '}
        and{' '}
        <Link to="/privacy-policy" className="text-blue-600 hover:underline">
          Privacy Policy
        </Link>
        . If you have any concerns about your data, please contact us before submitting.
      </p>
    </div>
  );
};

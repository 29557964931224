import React from 'react';
import { Dialog, DialogDescription, DialogTitle } from 'components/Elements';
import { useNavigate } from 'react-router-dom';
import { ChevronRight, Info } from 'react-feather';

export function HowToUseDialog({ isOpen, onClose }) {
  const navigate = useNavigate();

  const handleClose = () => {
    onClose();
  };

  const handleProceed = () => {
    localStorage.setItem('showHowToUse', 'false');
    onClose();
  };

  return (
    <Dialog isOpen={isOpen} onClose={handleClose} initialFocus={null}>
      <div className="inline-block align-start text-start bg-gray-900 rounded-lg px-6 pt-6 pb-4 text-white shadow-2xl transform transition-all sm:my-8 sm:align-top sm:p-8 max-w-4xl w-full">
        {/* Title Section */}
        <DialogTitle className="text-2xl font-semibold flex items-center space-x-4 mb-6">
          <Info className="h-10 w-10 text-primary-200" />
          <span>Welcome to Finablr</span>
        </DialogTitle>

        {/* Content Section */}
        <div className="text-gray-300">
          <p className="text-lg font-medium text-center mb-4">
            Uganda's first and only Tier 4 Money Lending network application.
          </p>

          {/* Requirements Section */}
          <div className="bg-gray-800 p-6 rounded-lg mb-6">
            <h3 className="font-semibold text-lg text-primary-300 mb-4 uppercase">
              Requirements for a Loan
            </h3>
            <ul className="space-y-3">
              <li className="flex items-start space-x-4">
                <span className="flex items-center justify-center bg-primary-300 text-gray-900 font-bold w-6 h-6 rounded-full">
                  1
                </span>
                <span>
                  Clear front and back images of your national ID or passport. Unclear images may
                  cause delays or rejection of your application.
                </span>
              </li>
              <li className="flex items-start space-x-4">
                <span className="flex items-center justify-center bg-primary-300 text-gray-900 font-bold w-6 h-6 rounded-full">
                  2
                </span>
                <span>
                  A collateral asset is required to secure the loan. Accepted collateral assets
                  include:
                  <ul className="list-disc list-inside mt-2 text-gray-400">
                    <li>Real Estate with Land Title</li>
                    <li>Vehicles & Bikes</li>
                    <li>Computers</li>
                    <li>Smartphones</li>
                    <li>Televisions</li>
                    <li>Other Electronics</li>
                  </ul>
                </span>
              </li>
            </ul>
          </div>

          {/* Step-by-Step Guide */}
          <div className="bg-gray-800 p-6 rounded-lg">
            <h3 className="font-semibold text-lg text-primary-300 mb-4 uppercase">
              Step-by-Step Guide to Borrow Money
            </h3>
            <ul className="space-y-4">
              <li>
                <strong>Step 1:</strong> Add information about your collateral asset.
                <ul className="mt-2 text-gray-400">
                  <li>
                    Ensure you have clear images of your asset.{' '}
                    <a href="/app/assets" target="_blank" className="text-primary-200 underline">
                      Go to Collateral Assets
                    </a>{' '}
                    and click on "New Asset."
                  </li>
                </ul>
              </li>
              <li>
                <strong>Step 2:</strong> Submit your loan request.{' '}
                <a href="/app/pigeon-loans" target="_blank" className="text-primary-200 underline">
                  Start your loan application
                </a>{' '}
                by clicking borrow money.
              </li>
              <li>
                <strong>Other details required:</strong>
                <ul className="list-disc list-inside text-gray-400 mt-2 space-y-2">
                  <li>Payment Method</li>
                  <li>Loan Details</li>
                  <li>Guarantor Details</li>
                  <li>Collateral Assets</li>
                  <li>Accept Terms and Conditions</li>
                  <li>Signature</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end space-x-4 mt-6">
          <button
            onClick={handleClose}
            className="px-6 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition"
          >
            Close
          </button>
          <button
            onClick={handleProceed}
            className="px-6 py-2 bg-primary-300 text-gray-900 font-semibold rounded-lg hover:bg-primary-200 transition"
          >
            Don't Show Again
          </button>
        </div>
      </div>
    </Dialog>
  );
}

import {
  AboutUs,
  ActivateAccount,
  BorrowerPolicy,
  ContactUs,
  ExecutiveGuarantee,
  ExecutiveSuccess,
  GeneralPolicy,
  LenderPolicy,
  Redirect,
  ServiceNotice,
  TermsAndConditions,
} from 'features/misc';
import { useAuth } from 'lib/auth';
import { useRoutes, Navigate } from 'react-router-dom';
import { lazyImport } from 'utils/lazyImport';
import { protectedRoutes } from './protected';
import { publicRoutes } from './public';
import { ConfirmAccount } from 'features/auth/routes/ConfirmAccount';
import { Spinner } from 'components/Elements';
import { CreateAsset } from 'features/assets/routes/CreateAsset';
const { PigeonLoansRoutes } = lazyImport(() => import('features/pigeonLoans'), 'PigeonLoansRoutes');

export const AppRoutes = () => {
  const auth = useAuth();

  const commonRoutes = [
    { path: '/service-notice', element: <ServiceNotice /> },
    { path: '/about-us', element: <AboutUs /> },
    { path: '/contact-us', element: <ContactUs /> },
    { path: '/terms-and-conditions', element: <TermsAndConditions /> },
    { path: '/lender-policy', element: <LenderPolicy /> },
    { path: '/borrower-policy', element: <BorrowerPolicy /> },
    { path: '/general-policy', element: <GeneralPolicy /> },
    { path: '/activate/:code', element: <ActivateAccount /> },
    { path: '/please-confirm-email', element: <ConfirmAccount /> },
    { path: '/executive-guarantee', element: <ExecutiveGuarantee /> },
    { path: '/executive-guarantee/success', element: <ExecutiveSuccess /> },
    { path: '/redirect', element: <Redirect /> },
    { path: '/app/pigeon-loans/*', element: <PigeonLoansRoutes /> },
    { path: '/app/assets/create', element: <CreateAsset /> },
  ];

  if (auth.isLoading) {
    return (
      <div className="flex items-center justify-center w-screen h-screen">
        <Spinner size="xl" />
      </div>
    );
  }

  const routes = auth.user ? protectedRoutes : publicRoutes;

  const element = useRoutes([...routes, ...commonRoutes]);

  return <>{element}</>;
};

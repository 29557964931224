import { Button } from 'components/Elements';
import { Form, InputField, UploadField } from 'components/Form';
import React, { useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useUploadFiles } from '../api/uploadFiles';
import { useAuth } from 'lib/auth';

export const KYCRequirements = () => {
  const navigate = useNavigate();
  const uploadFilesMutation = useUploadFiles();

  const { user } = useAuth();

  const [avatar, setAvatar] = useState();
  const [preview, setPreview] = useState();
  const [nationalIDBack, setNationalIDBack] = useState();
  const [nationalIDFront, setNationalIDFront] = useState();
  const [work, setWork] = useState('');
  const [nin, setNIN] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  return (
    <div>
      <div className="bg-primary-100 p-5 flex flex-row justify-between md:px-12">
        <div className="text-white font-black text-md md:text-xl">KYC Notice</div>
        <button>
          <MdClose className="text-white" height={8} />
        </button>
      </div>
      <div className="p-5 text-lg border-b border-gray-200 bg-primary-50 text-primary-700 rounded-t-lg">
        <strong>Heads up!</strong> We noticed some details are missing from your profile. To proceed
        with your loan application, please update your KYC information. Let’s get this done quickly!
      </div>

      <div className="m-8">
        <>
          <Form
            id="update-files"
            onSubmit={async (values) => {
              const bodyFormData = new FormData();
              if (avatar) bodyFormData.append('avatar', avatar);
              if (nationalIDFront) bodyFormData.append('nationalIDFront', nationalIDFront);
              if (nationalIDBack) bodyFormData.append('nationalIDBack', nationalIDBack);
              if (nin) bodyFormData.append('nin', nin);
              bodyFormData.append('work', work);
              const result = await uploadFilesMutation.mutateAsync({
                data: bodyFormData,
                id: user._id,
              });
              if (result) {
                setPreview(null);
                setAvatar(null);
                setNationalIDFront(null);
                setNationalIDBack(null);
                navigate('/app/pigeon-loans');
              }
            }}
          >
            {({ register, formState, setValue }) => (
              <>
                <UploadField
                  title="Step 1. Provide a Selfie Photo"
                  // error={formState.errors['avatar'].message}
                  caption="A selfie helps improve your credit rating and let's lenders recognize you."
                  onChange={(e) => {
                    setValue('avatar', 'file set');
                    const file = e.target.files[0];
                    if (file && file.type.startsWith('image/')) {
                      setAvatar(file);
                    }
                  }}
                />
                <div className="">
                  <div className="">
                    <span className="font-semibold text-lg">Step 2. Identification Document</span>
                    <br />
                    <span className="text-md">National ID / Passport</span>
                  </div>
                </div>
                <div className="">
                  <div className="">Provide your National ID / Passport number</div>
                  <InputField
                    error={formState.errors['nin']}
                    //   registration={register('work')}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setNIN(e.target.value);
                    }}
                  />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <UploadField
                      title="Provide a Front image of your national ID"
                      caption="Provide a clear image of the front of your national ID."
                      // error={formState.errors['pigeonLoansNationalIDFront'].message}
                      onChange={(e) => {
                        setValue('pigeonLoansNationalIDFront', 'file set');
                        const file = e.target.files[0];
                        if (file && file.type.startsWith('image/')) {
                          setNationalIDFront(file);
                          console.log('nationalIDFront', nationalIDFront);
                        }
                      }}
                    />
                  </div>
                  <div>
                    <UploadField
                      title="Provide a Back image of your national ID"
                      caption="Provide a clear image of the Back of your national ID."
                      // error={formState.errors['pigeonLoansNationalIDBack'].message}
                      onChange={(e) => {
                        setValue('pigeonLoansNationalIDBack', 'file set');
                        const file = e.target.files[0];
                        if (file && file.type.startsWith('image/')) {
                          setNationalIDBack(file);
                          console.log('nationalIDBack', nationalIDBack);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="">
                  <div className="">
                    <span className="font-semibold text-lg">Step 3. Describe your work</span>
                    <br />
                    <span>Please provide a brief description of your work.</span>
                  </div>
                </div>
                <InputField
                  error={formState.errors['work']}
                  //   registration={register('work')}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setWork(e.target.value);
                  }}
                />
                <div className="my-4">
                  <h2 className="text-lg font-medium text-gray-700">
                    Your Privacy is Our Priority
                  </h2>
                  <div className="mt-3 p-4 bg-blue-50 border-l-4 border-blue-600 rounded-md">
                    <p className="text-gray-600">
                      We take your security seriously. Your personal data is encrypted and securely
                      stored, and we only share it with authorized parties when necessary.
                    </p>
                    <p className="mt-2 text-gray-600">
                      For more details, please review our
                      <a href="/general-policy" className="text-secondary-100 hover:underline ml-1">
                        General Policy
                      </a>
                      .
                    </p>
                  </div>
                </div>
                <div className="flex flex-row justify-end items-center space-x-2">
                  <Button isLoading={uploadFilesMutation.isLoading} type="submit">
                    Submit
                  </Button>
                </div>
              </>
            )}
          </Form>
        </>
      </div>
    </div>
  );
};

import React, { useState } from 'react';
import { Form } from 'components/Form';
import storage from 'utils/storage';
import { Info, Lock } from 'react-feather';
import { Link } from 'react-router-dom';
import { Button } from 'components/Elements';
import Swal from 'sweetalert2';

export const SelectPaymentMethod = ({ nextStep }) => {
  const [paymentMethod, setPaymentMethod] = useState();

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <Form
      id="create-loan"
      onSubmit={async () => {
        storage.setFormValues({
          paymentMethod,
        });
        nextStep();
      }}
    >
      {() => (
        <div className=" h-screen px-4 md:px-24 pt-4">
          <div className="mb-4">
            <div className="text-lg font-medium">Select a favorable payment method.</div>
            <div className="text-md mb-4">
              Please choose the payment method that aligns with your financial preferences. You can
              select either the Interest Only option for lower initial payments or the Declining
              Balance option to reduce your principal over time.
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <button
                type="button"
                onClick={() => {
                  setPaymentMethod('INTEREST_ONLY');
                }}
                className={`${
                  paymentMethod === 'INTEREST_ONLY'
                    ? 'bg-primary-100 text-white'
                    : 'bg-white text-gray-600'
                } md:h-48 border border-gray-200 shadow w-full  hover:text-white hover:bg-primary-100 rounded-2xl p-8 mb-3`}
              >
                <div className="text-xl">Interest Only Method</div>
                <div className="text-sm">
                  Recommended for short-term loans where you only pays the interest for a period.
                </div>
              </button>
              <button
                type="button"
                onClick={() => {
                  setPaymentMethod('DECLINING_BALANCE');
                }}
                className={`${
                  paymentMethod === 'DECLINING_BALANCE'
                    ? 'bg-primary-100 text-white'
                    : 'bg-white text-gray-600'
                } md:h-48 border border-gray-200 shadow w-full  hover:text-white hover:bg-primary-100 rounded-2xl p-8 mb-3`}
              >
                <div className="text-xl">Declining Balance Method</div>
                <div className="text-sm">
                  Recommended for loans where the borrower’s repayments reduce the principal over
                  time.
                </div>
              </button>
            </div>
          </div>
          <div className="bg-yellow-50 p-4 rounded-lg shadow-md text-sm flex flex-col items-start space-y-2 mb-4">
            <div className="flex flex-row items-center">
              <Info size={18} className="text-yellow-500" />
              <div className="ml-3 text-lg font-semibold text-gray-700">
                Your Privacy is Our Priority
              </div>
            </div>
            <p className="text-gray-600">
              We understand the importance of safeguarding your personal information. As you proceed
              with your loan application, rest assured that all data provided is treated with the
              utmost confidentiality and security.
            </p>
            <p className="text-gray-600">
              To help you make an informed decision, we will only collect the information necessary
              to process your loan. This includes details such as your identity, financial standing,
              and loan preferences. We will never share your data without your consent.
            </p>
            <div className="bg-yellow-100 p-2 rounded-md text-sm flex flex-row items-center">
              <div className="flex flex-row items-center space-x-2">
                <Lock size={14} className="text-yellow-600" />
                <div className="ml-3">
                  Your information is encrypted and securely stored to prevent unauthorized access.
                  For more details, read our{' '}
                  <Link
                    target="_blank"
                    to="/general-policy"
                    className="text-blue-600 cursor-pointer hover:underline"
                  >
                    General Policy
                  </Link>{' '}
                  and{' '}
                  <Link
                    target="_blank"
                    to="/borrower-terms-and-conditions"
                    className="text-blue-600 cursor-pointer hover:underline"
                  >
                    Borrower Terms & Conditions
                  </Link>
                  .
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full">
            <p className="text-gray-600 text-sm mb-4 md:mb-0">
              By proceeding, you consent to the use of your data for processing your loan
              application in accordance with our Borrower Terms & Conditions.
            </p>
            <div className="flex items-center">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                className="text-gray-700"
              />
              <span className="ml-2 font-bold text-gray-600">
                I agree to the Borrower Terms & Conditions
              </span>
            </div>
          </div>
          <div className="flex flex-row justify-end items-end">
            <Button
              type="button"
              onClick={() => {
                if (!paymentMethod) {
                  Swal.fire({
                    icon: 'warn',
                    title: 'Please select a payment method',
                    text: "Select your payment method. It's required to determine your loan payment schedule.",
                  });
                }
                if (!isChecked) {
                  Swal.fire({
                    icon: 'warn',
                    title: 'Oops',
                    text: 'You can not proceed without agreeing to our Borrower Terms & Conditions',
                  });
                }
                storage.setFormValues({
                  paymentMethod,
                });
                if (isChecked && paymentMethod) {
                  nextStep();
                }
              }}
            >
              Continue
            </Button>
          </div>
        </div>
      )}
    </Form>
  );
};

import { Spinner } from 'components/Elements';
import { useAuth } from 'lib/auth';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import storage from 'utils/storage';

export const Redirect = () => {
  const { user } = useAuth();
  const params = new URLSearchParams(window.location.search);

  const navigate = useNavigate();

  useEffect(() => {
    const redirectValue = params.get('route');

    if (!user || user === null || user === undefined) {
      storage.setRedirect(redirectValue);
      return navigate('/auth/login');
    } else if (user) {
      switch (redirectValue) {
        case 'borrow_money':
          return navigate('/app/pigeon-loans');
        case 'lend_money':
          return navigate('/app/lend-money');
        case 'my_portfolio':
          return user.role === 'LENDER'
            ? navigate('/app/my-portfolio')
            : navigate('/app/lend-money/complete-lender-profile');
        default:
          return navigate('/app');
      }
    }
  }, []);

  return (
    <div className="flex items-center justify-center w-screen h-screen">
      <div className="">Redirecting..</div>
      <Spinner size="xl" />
    </div>
  );
};

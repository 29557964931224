import clsx from 'clsx';

import { FieldWrapper } from './FieldWrapper';
import { Purpose } from 'features/pigeonLoans/tips/Purpose';

export const TextAreaFieldWithInfo = (props) => {
  const {
    rows = 3,
    label,
    className,
    disabled,
    value,
    registration,
    caption,
    placeholder,
    onChange,
    error,
    maxLength,
    infoTitle,
    infoDescription,
    name,
    required,
  } = props;

  return (
    <>
      <div className="flex flex-row items-center justify-between mt-3">
        <div className="block text-sm font-medium text-gray-700">{label}</div>
        <Purpose title={infoTitle} description={infoDescription} />
      </div>
      <textarea
        rows="4"
        value={value}
        name={name}
        className="mb-3 appearance-none block w-full px-3 py-3 border bg-gray-100 border-none border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        onChange={onChange}
        required={required}
      ></textarea>
      <div className="my-1 text-xs flex justify-end">{caption}</div>
      {error?.message && (
        <div role="alert" aria-label={error.message} className="text-sm font-semibold text-red-500">
          {error.message}
        </div>
      )}
    </>
  );
};

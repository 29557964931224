import React from 'react';

export const LenderTermsAndConditions = () => {
  return (
    <div>
      <div className="bg-secondary-100 p-2 rounded-t-xl text-white">
        Terms and Conditions (Please read the terms and conditions below before)
      </div>
      <div className="p-4">
        {/* Terms and conditions content */}
        <div className="mb-2 text-sm md:text-md">
          <b>1. Introduction:</b> These terms govern lending through the Finablr platform, operated
          by Enabling Finance Limited (EFL). By agreeing, you acknowledge the associated risks and
          responsibilities, including the potential loss of funds if a borrower defaults.
        </div>
        <div className="mb-2 text-sm md:text-md">
          <b>2. Legal and Regulatory Information:</b> EFL is registered in Uganda and licensed by
          UMRA. It facilitates loan agreements between lenders and borrowers, manages collections,
          and administers loan repayments.
        </div>
        <div className="mb-2 text-sm md:text-md">
          <b>3. Lender Representations and Warranties:</b> Lenders must be legally authorized to
          operate, ensure the accuracy of their provided information, and fully understand the risks
          of lending through the platform.
        </div>
        <div className="mb-2 text-sm md:text-md">
          <b>4. Risk Acknowledgement:</b> Lending involves inherent risks, including borrower
          default and delays in collateral recovery. EFL does not guarantee loan repayments or
          provide investment advice.
        </div>
        <div className="mb-2 text-sm md:text-md">
          <b>5. Lending Process:</b> Lenders match with borrowers through the platform and agree to
          terms via loan agreements. Funds are held in a collection account and disbursed after
          agreements are finalized.
        </div>
        <div className="mb-2 text-sm md:text-md">
          <b>6. Provision Fund:</b> A discretionary fund provides partial compensation for delayed
          repayments in case of borrower default. Claims are subject to review and approval by EFL’s
          Provision Fund Committee.
        </div>
        <div className="mb-2 text-sm md:text-md">
          <b>7. Costs and Charges:</b> Lenders are charged a commission on interest earned. Late
          fees may also apply to borrowers in case of default, as specified in the loan agreement.
        </div>
        <div className="mb-2 text-sm md:text-md">
          <b>8. Termination:</b> Terms remain valid as long as a lender has active loan agreements.
          Termination requires written notice and adherence to stipulated conditions.
        </div>
        <div className="mb-3 text-sm md:text-md">
          By checking the box below, you agree to the Enabling Finance Limited lender terms and
          conditions.
        </div>
        <div className="text-sm md:text-md">
          For a detailed understanding of the lender terms and conditions, you can access the full
          document by clicking{' '}
          <a
            href="https://docs.google.com/document/d/e/2PACX-1vSqfxPg0K831HueLKjKjA6IFBmcl3Ydz9w9ys9n-7yzKKQCU2P-2QGgmJ2wROqpL_UC0ss2RbF3uEZQ/pub"
            target="_blank"
            className="text-secondary-100 hover:underline"
          >
            here
          </a>
          . This is a summarized version for your convenience.
        </div>
      </div>
    </div>
  );
};

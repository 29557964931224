import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../../components/Layout';
import { loginWithTwitter } from 'features/auth/api/loginWithTwitter';
import { handleUserResponse } from 'lib/auth';
import { Spinner } from 'components/Elements';

export const TwitterRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleTwitterInAuth = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');

      if (!code) {
        console.error('No twitter code found in the URL.');
        navigate('/auth/login');
        return;
      }

      try {
        // Call the API with the Twitter code
        const response = await loginWithTwitter({ code });

        const user = await handleUserResponse(response);

        console.log('Twitter login successful:', user);

        navigate('/app'); // Redirect to the main app
      } catch (error) {
        console.error('Error during Twitter login:', error);
        navigate('/auth/login'); // Redirect to login page on error
      }
    };

    handleTwitterInAuth();
  }, [navigate]);

  return (
    <Layout title="Logging in with Twitter">
      <div className="flex justify-center items-center min-h-screen">
        <p className="text-md">Signing in with Twitter. Please wait.</p>
        <Spinner />
      </div>
    </Layout>
  );
};

import { useEffect, useState } from 'react';
import { useAuth } from 'lib/auth';
import PropTypes from 'prop-types';
import * as z from 'zod';
import { redirect, useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { Button } from 'components/Elements';
import { Form, InputField, PhoneInputField } from 'components/Form';
import 'react-phone-input-2/lib/style.css';
// import { FaGoogle, FaLinkedin, FaGithub, FaTwitter, FaFacebook } from 'react-icons/fa';
// import { ChevronDown } from 'react-feather';
import GoogleImg from 'assets/Google__G__logo.png';
import storage from 'utils/storage';

const schema = z.object({
  phoneNumber: z
    .string({
      required_error: 'Phone number is required',
      invalid_type_error: 'Phone number must be a string',
    })
    .min(1, { message: 'Phone number cannot be empty' }),
  password: z
    .string({
      required_error: 'Password is required',
      invalid_type_error: 'Password must be a string',
    })
    .min(1, { message: 'Password cannot be empty' }),
});

export const LoginForm = ({ onSuccess }) => {
  const { login, isLoggingIn, user } = useAuth();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [phoneErr, setPhoneErr] = useState('');
  const [loginErr, setLoginErr] = useState('');
  const [route, setRoute] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    // if (user) {
    //   navigate('/app');
    // }
    const redirectValue = storage.getRedirect();
    setRoute(redirectValue);
  }, []);

  const socialAuthHandlers = {
    google: () => (window.location.href = 'https://restapi.finablr.app/api/v2/oauth/google'),
    linkedin: () => (window.location.href = 'https://restapi.finablr.app/api/v2/oauth/linkedin'),
    github: () => (window.location.href = 'https://restapi.finablr.app/api/v2/oauth/github'),
    twitter: () => (window.location.href = 'https://restapi.finablr.app/api/v2/oauth/twitter'),
    facebook: () => (window.location.href = 'https://restapi.finablr.app/api/v2/oauth/facebook'),
  };

  return (
    <div>
      {route === 'borrow_money' && (
        <div className="bg-red-100 text-red-500 p-3 rounded-lg shadow">
          You need to be authenticated to apply for a loan!
        </div>
      )}
      {route === 'lend_money' && (
        <div className="bg-red-100 text-red-500 p-3 rounded-lg shadow">
          You need to be authenticated to lend or check out loan requests!
        </div>
      )}
      {route === 'my_portfolio' && (
        <div className="bg-red-100 border border-red-300 font-light text-red-500 p-3 rounded-lg shadow">
          You need to be authenticated to check out your lender portfolio!
        </div>
      )}
      <Form
        onSubmit={async (values) => {
          setLoginErr('');
          const data = { phoneNumber, password: values.password };
          await login(data).then(() => onSuccess());
        }}
        schema={schema}
      >
        {({ register, formState, setValue }) => (
          <>
            {!showPasswordInput ? (
              <div className="flex flex-col">
                <div className="mb-3">
                  <div className="block text-md font-medium text-gray-700">
                    Enter your phone number to log in
                  </div>
                  <PhoneInput
                    country={'ug'}
                    countryCodeEditable={false}
                    masks={{ ug: '... ... ...' }}
                    name="phoneNumber"
                    value={phoneNumber}
                    inputStyle={{
                      width: '100%',
                      height: '50px',
                      fontSize: '1rem',
                      border: phoneErr ? '1px solid #E80404' : '1px solid #e5e5e5',
                      borderRadius: '18px',
                    }}
                    buttonStyle={{
                      borderTopLeftRadius: '18px',
                      borderBottomLeftRadius: '18px',
                      backgroundColor: '#fff',
                    }}
                    containerClass="mt-2"
                    onChange={(phone) => {
                      setPhoneErr('');
                      setLoginErr('');
                      setValue('phoneNumber', `+${phone}`);
                      setPhoneNumber(`+${phone}`);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        if (phoneNumber && phoneNumber.length === 13) {
                          setShowPasswordInput(true);
                          setPhoneErr('');
                        } else {
                          setPhoneErr('Phone number is invalid');
                        }
                      }
                    }}
                  />
                  {formState.errors['phoneNumber']?.message && (
                    <div role="alert" className="text-sm font-semibold text-red-500">
                      {formState.errors['phoneNumber']?.message}
                    </div>
                  )}
                  {phoneErr && (
                    <div role="alert" className="mx-6 pt-1 text-sm font-semibold text-red-500">
                      {phoneErr}
                    </div>
                  )}
                </div>

                <div className="">
                  <Button
                    onClick={() => {
                      if (phoneNumber && phoneNumber.length === 13) {
                        setShowPasswordInput(true);
                        setPhoneErr('');
                      } else {
                        setPhoneErr('Phone number is invalid');
                      }
                    }}
                    className="w-full"
                  >
                    Next
                  </Button>
                </div>
              </div>
            ) : (
              <>
                <div className="mt-4">
                  Sign In
                  {phoneNumber && (
                    <div className="text-sm text-gray-900">Phone Number: {phoneNumber}</div>
                  )}
                </div>
                <InputField
                  type="password"
                  label="Password"
                  name="password"
                  passwordField={true}
                  error={formState.errors['password']}
                  registration={register('password')}
                  className="rounded-full"
                  onChange={(event) => {
                    setLoginErr('');
                    setValue('password', event.target.value);
                    setPassword(event.target.value);
                  }}
                />
                {loginErr && (
                  <div role="alert" className="text-sm font-semibold text-red-500">
                    {loginErr}
                  </div>
                )}
                <div>
                  <Button isLoading={isLoggingIn} type="submit" className="w-full">
                    Sign in
                  </Button>
                </div>
              </>
            )}
          </>
        )}
      </Form>

      <button
        onClick={socialAuthHandlers.google}
        className="flex items-center shadow-lg justify-center space-x-2 px-6 py-3 mt-4 text-sm font-medium text-gray-800 bg-gray-100 rounded-2xl hover:bg-gray-200 focus:outline-none focus:ring-0 w-full"
      >
        <img src={GoogleImg} alt="google" className="h-6" />
        <div className="text-md font-medium">Sign in with Google</div>
      </button>
      <button className="w-full md:text-start mt-4" onClick={() => navigate('/auth/register')}>
        Don't have an account?{' '}
        <span className="text-primary-100 hover:text-secondary-100">Click here to register.</span>
      </button>
      <button
        className="w-full md:text-start text-sm text-primary-100 hover:text-secondary-100"
        onClick={() => navigate('/auth/forgot-password')}
      >
        Forgot Password?
      </button>
    </div>
  );
};

LoginForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

import React, { useState, useCallback } from 'react';
import { Image, Trash2 } from 'react-feather';
import Webcam from 'react-webcam';
import { FieldWrapper } from './FieldWrapper';

export const UploadField = (props) => {
  const {
    title,
    error,
    required,
    x,
    y,
    onChange,
    size,
    preview = '',
    caption,
    registration,
    button,
  } = props;

  const [useWebcam, setUseWebcam] = useState(false);
  const [webcamImage, setWebcamImage] = useState('');
  const [uploadedPreview, setUploadedPreview] = useState(preview || ''); // State for file upload preview

  const webcamRef = React.useRef(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      setWebcamImage(imageSrc);
      setUploadedPreview(''); // Clear any file preview when using webcam
      const file = dataURLtoFile(imageSrc, 'webcam_capture.jpg');
      onChange({ target: { name: 'file', files: [file] } }); // Call the provided onChange handler
    }
  }, [webcamRef, onChange]);

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const handleFileChange = (e) => {
    const file = e.target.files && e.target.files[0];
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setUploadedPreview(reader.result);
      setWebcamImage(''); // Clear any webcam image when uploading a file
      onChange(e); // Call the provided onChange handler
    };
    reader.readAsDataURL(file);
  };

  const handleRemoveImage = () => {
    setUploadedPreview('');
    setWebcamImage('');
    onChange({ target: { name: 'file', files: [] } }); // Clear file from form
  };

  return (
    <>
      <h1 className="text-gray-700 dark:text-gray-200 font-bold">{title}</h1>
      {/* <span className="text-gray-600 text-sm">
        {caption ? caption : `Images of (${x} x ${y}) px are recommended. Max file size: ${size}`}
      </span> */}

      {uploadedPreview || webcamImage ? (
        <div className="border border-dotted rounded p-2 flex flex-col items-center justify-center shadow-sm">
          <div className="flex flex-row justify-end w-full">
            <button
              type="button"
              className="text-red-500 flex items-center"
              onClick={handleRemoveImage}
            >
              <Trash2 className="mr-1" /> Remove
            </button>
          </div>
          <img
            src={uploadedPreview || webcamImage}
            className="w-1/2"
            alt="uploaded to server"
            onClick={() => {
              window.open(uploadedPreview || webcamImage, '_blank');
            }}
          />
        </div>
      ) : (
        <>
          {useWebcam ? (
            <div className="flex flex-col items-center mt-4">
              <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" width="100%" />
              <button
                type="button"
                onClick={capture}
                className="mt-2 bg-blue-500 text-white p-2 rounded shadow"
              >
                Capture Photo
              </button>
              <button
                type="button"
                onClick={() => setUseWebcam(false)}
                className="mt-2 text-gray-600 text-sm underline"
              >
                Switch to file upload
              </button>
            </div>
          ) : (
            <div className="border border-dotted rounded p-10 flex flex-col items-center justify-center mt-4 shadow-sm">
              <Image className="text-gray-300 h-20 w-20" />
              <label>
                <span className="mt-2 text-base leading-normal">
                  <span className="text-red-500 cursor-pointer">Browse</span> for image.
                </span>
                <input
                  type="file"
                  accept="image/*"
                  name="file"
                  className="hidden"
                  onChange={handleFileChange}
                  {...registration}
                />
              </label>
              <button
                type="button"
                onClick={() => setUseWebcam(true)}
                className="mt-2 text-gray-600 text-sm underline"
              >
                Use Webcam
              </button>
            </div>
          )}
        </>
      )}

      {error && (
        <div role="alert" aria-label={error} className="text-sm font-semibold text-red-500">
          {typeof error === 'string' ? error : error.message}
        </div>
      )}
    </>
  );
};

import { Form, InputFieldWithInfo, ReactNumberFormatInputField2 } from 'components/Form';
import { useCreateAsset } from 'features/assets/api/createAsset';
import { smartPhoneValuationSchema } from 'features/assets/schemas';
import React from 'react';
import { dataURItoBlob } from 'utils/dataURIToBlob';

export const SmartPhoneValuationForm = ({ onSuccess, onBack, selectedImages }) => {
  const createAssetMutation = useCreateAsset();

  return (
    <Form
      id="create-post"
      onSubmit={async (values) => {
        const smartPhoneAssetData = JSON.parse(localStorage.getItem('smartPhoneAssetData'));
        const category = localStorage.getItem('assetCategory');

        const bodyFormData = new FormData();

        if (selectedImages) {
          selectedImages.forEach((url, index) => {
            if (url) {
              bodyFormData.append(`pictures`, dataURItoBlob(url));
            }
          });
        }

        bodyFormData.append('title', smartPhoneAssetData.title);
        bodyFormData.append('category', category);
        bodyFormData.append('brand', smartPhoneAssetData.brand);
        bodyFormData.append('specify_others', smartPhoneAssetData.specify_others);
        bodyFormData.append('ram_size', smartPhoneAssetData.ram_size);
        bodyFormData.append('storage_space', smartPhoneAssetData.storage_space);
        bodyFormData.append('model', smartPhoneAssetData.model);
        bodyFormData.append('product', smartPhoneAssetData.product);
        bodyFormData.append('description', smartPhoneAssetData.description);

        bodyFormData.append('purchase_amount', values.purchase_amount);
        bodyFormData.append('current_worth', values.current_worth);
        bodyFormData.append('purchase_date', values.purchase_date);

        await createAssetMutation.mutateAsync({ data: bodyFormData }).then(() => {
          localStorage.removeItem('assetCategory');
          localStorage.removeItem('formStep');
          localStorage.removeItem('smartPhoneAssetData');
          localStorage.removeItem('assetImageURLs');

          onSuccess();
        });
      }}
      schema={smartPhoneValuationSchema}
    >
      {({ register, formState, watch, setValue }) => (
        <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
          <div className="mb-4">Valuation</div>
          <div className="mb-2">
            To enhance your prospects of securing a loan, it's imperative to furnish precise and
            comprehensive information.
          </div>
          <div className="md:mx-48">
            <div className="">
              <div className="flex flex-row items-center justify-between mt-3">
                <div className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                  How much did you buy the Smart Phone? (UGX)
                </div>
              </div>
              <ReactNumberFormatInputField2
                prefix="UGX "
                name="purchase_amount"
                error={formState.errors['purchase_amount']}
                registration={register('purchase_amount')}
                onChange={(event) => {
                  formState.errors['purchase_amount'] = '';
                  setValue('purchase_amount', event.floatValue);
                }}
              />
            </div>
            <div className="mb-2">
              <div className="flex flex-row items-center justify-between mt-3">
                <div className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                  How much is it worth currently? (UGX)
                </div>
              </div>
              <ReactNumberFormatInputField2
                prefix="UGX "
                name="current_worth"
                error={formState.errors['current_worth']}
                registration={register('current_worth')}
                onChange={(event) => {
                  formState.errors['current_worth'] = '';
                  setValue('current_worth', event.floatValue);
                }}
              />
            </div>
            <InputFieldWithInfo
              type="date"
              label="When did you buy it? (DD/MM/YYYY)"
              caption="Please provide a date in the format (Date / Month / Year)"
              error={formState.errors['purchase_date']}
              registration={register('purchase_date')}
              onChange={(event) => {
                formState.errors['purchase_date'] = '';
                setValue('purchase_date', event.target.value);
              }}
            />
          </div>
          <div className="flex flex-row space-x-2 justify-end mt-4">
            <Button
              variant="outline"
              onClick={() => {
                onBack();
              }}
            >
              Back
            </Button>
            <Button isLoading={createAssetMutation.isLoading} type="submit">
              Finish
            </Button>
          </div>
        </div>
      )}
    </Form>
  );
};

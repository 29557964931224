import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import * as z from 'zod';
import PhoneInput from 'react-phone-input-2';
import { Button, Spinner } from 'components/Elements';
import { Form, InputField } from 'components/Form';
import { useAuth } from 'lib/auth';
import 'react-phone-input-2/lib/style.css';
import GoogleImg from 'assets/Google__G__logo.png';
import { registerQuick } from '../api/registerQuick';

const schema = z.object({
  firstName: z.string({
    required_error: 'First name is required',
    invalid_type_error: 'First name must be a string',
  }),
  lastName: z.string({
    required_error: 'Last name is required',
    invalid_type_error: 'Last name must be a string',
  }),
  phoneNumber: z.string({
    required_error: 'Phone Number is required',
    invalid_type_error: 'Phone Number must be a string',
  }),
  email: z
    .string({
      required_error: 'Email is required',
      invalid_type_error: 'Email must be a string',
    })
    .email({ message: 'Invalid email address' }),
  password: z.string({
    required_error: 'Password is required',
    invalid_type_error: 'Password must be a string',
  }),
});

export const RegisterForm = ({ onSuccess }) => {
  const { register, isRegistering } = useAuth();

  const [phoneNumber, setPhoneNumber] = useState();

  const socialAuthHandlers = {
    google: () => (window.location.href = 'https://restapi.finablr.app/api/v2/oauth/google'),
    linkedin: () => (window.location.href = 'https://restapi.finablr.app/api/v2/oauth/linkedin'),
    github: () => (window.location.href = 'https://restapi.finablr.app/api/v2/oauth/github'),
    twitter: () => (window.location.href = 'https://restapi.finablr.app/api/v2/oauth/twitter'),
    facebook: () => (window.location.href = 'https://restapi.finablr.app/api/v2/oauth/facebook'),
  };

  return (
    <div>
      <div className="text-lg text-start mb-2">
        Get access to your free Finablr account, apply for a loan within 5 minutes!
      </div>

      <Form
        onSubmit={async (values) => {
          values.phoneNumber = phoneNumber;
          await registerQuick(values);
          onSuccess();
        }}
        schema={schema}
      >
        {({ register, formState, setValue }) => (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 space-y-1">
              <InputField
                type="text"
                label="First Name"
                error={formState.errors['firstName']}
                registration={register('firstName')}
              />
              <InputField
                type="text"
                label="Last Name"
                error={formState.errors['lastName']}
                registration={register('lastName')}
              />
            </div>

            <InputField
              type="text"
              label="Other Name"
              error={formState.errors['otherName']}
              registration={register('otherName')}
            />
            <div className="mb-3">
              <div className="block text-sm font-medium text-gray-700  mt-3">Phone Number</div>
              <PhoneInput
                country={'ug'}
                countryCodeEditable={false}
                masks={{ ug: '... ... ...' }}
                name="phoneNumber"
                value={phoneNumber}
                inputStyle={{
                  width: '100%',
                  height: '50px',
                  fontSize: '1rem',
                  border: '1px solid #e5e5e5',
                  borderRadius: '18px',
                }}
                buttonStyle={{
                  borderTopLeftRadius: '18px',
                  borderBottomLeftRadius: '18px',
                  backgroundColor: '#fff',
                }}
                containerClass="mt-2"
                onChange={(phone) => {
                  formState.errors['phoneNumber'] = '';
                  setValue('phoneNumber', `+${phone}`);
                  setPhoneNumber(`+${phone}`);
                  const newValues = {
                    phoneNumber: `+${phone}`,
                  };
                  const existingValues =
                    JSON.parse(localStorage.getItem('individualRegister')) || {};
                  // Merge existing and new values
                  const mergedValues = {
                    ...existingValues,
                    ...newValues,
                  };
                  // Save merged values back to storage
                  localStorage.setItem('individualRegister', JSON.stringify(mergedValues));
                }}
              />
              {formState.errors['phoneNumber']?.message && (
                <div
                  role="alert"
                  aria-label={formState.errors['phoneNumber']?.message}
                  className="text-sm font-semibold text-red-500"
                >
                  {formState.errors['phoneNumber']?.message}
                </div>
              )}
            </div>

            <InputField
              type="email"
              label="Email Address"
              error={formState.errors['email']}
              registration={register('email')}
            />
            <InputField
              type="password"
              label="Password"
              passwordField={true}
              error={formState.errors['password']}
              registration={register('password')}
            />

            <div className="">
              By signing up, you are agreeing to the{' '}
              <Link to="/general-policy" className="text-primary-100">
                General Policy for Lenders and Borrowers
              </Link>
              . This site uses essential cookies. See our Cookie Notice for more information.
            </div>

            <div>
              <Button isLoading={isRegistering} type="submit" className="w-full">
                Sign Up
              </Button>
            </div>
          </>
        )}
      </Form>

      <button
        onClick={socialAuthHandlers.google}
        className="flex items-center shadow-lg justify-center space-x-2 px-6 py-3 mt-4 text-sm font-medium text-gray-800 bg-gray-100 rounded-2xl hover:bg-gray-200 focus:outline-none focus:ring-0 w-full"
      >
        <img src={GoogleImg} alt="google" className="h-6" />
        <div className="text-md font-medium">Sign up with Google</div>
      </button>
      <div className="mt-2 flex items-center justify-center">
        <div className="text-sm">
          Already have an account? {''}
          <Link to="../login" className="font-medium text-primary-100 hover:text-primary-300">
            Sign In
          </Link>
        </div>
      </div>
    </div>
  );
};

RegisterForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

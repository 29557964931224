const storagePrefix = 'msw-db';

const storage = {
  getRedirect: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}_redirect`));
  },
  setRedirect: (redirect) => {
    window.localStorage.setItem(`${storagePrefix}_redirect`, JSON.stringify(redirect));
  },
  clearRedirect: () => {
    window.localStorage.removeItem(`${storagePrefix}_redirect`);
  },
  getToken: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}token`));
  },
  setToken: (token) => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
  },
  getFormValues: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}formValues`)) || {};
  },
  getOtherValues: (value) => {
    return JSON.parse(window.localStorage.getItem(value)) || '';
  },
  setFormValues: (newValues) => {
    // Retrieve existing form values
    const existingValues =
      JSON.parse(window.localStorage.getItem(`${storagePrefix}formValues`)) || {};

    // Merge existing and new values
    const mergedValues = {
      ...existingValues,
      ...newValues,
    };

    // Save merged values back to storage
    window.localStorage.setItem(`${storagePrefix}formValues`, JSON.stringify(mergedValues));
  },
  setAnyFormValues: (name, newValues) => {
    // Retrieve existing form values
    const existingValues = JSON.parse(window.localStorage.getItem(name)) || {};

    // Merge existing and new values
    const mergedValues = {
      ...existingValues,
      ...newValues,
    };

    // Save merged values back to storage
    window.localStorage.setItem(name, JSON.stringify(mergedValues));
  },
  clearStorage: () => {
    window.localStorage.clear();
  },
};

export default storage;

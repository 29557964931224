import { Button } from 'components/Elements';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const Success = () => {
  const navigate = useNavigate();
  return (
    <div className="m-8">
      <div className=" py-24  rounded-2xl p-4 mb-4">
        <div className="text-3xl text-primary-100 font-bold text-center mb-2">Success!</div>
        <div className="text-gray-600 text-center text-lg mb-3">
          You've successfully submitted your loan application.
        </div>
        <div className="text-md text-gray-600 text-center mb-6">
          Your application is under review. The Finablr admin will evaluate your submission and
          request any additional information if needed. You’ll be notified with the next steps via
          app notifications and email.
        </div>
        <div className="text-left text-sm text-gray-700 p-4 bg-gray-50 border rounded-lg mb-4">
          <h3 className="font-semibold mb-2">What Happens Next:</h3>
          <ol className="list-decimal pl-6">
            <li>The admin reviews your application for missing information.</li>
            <li>
              If approved, your loan appears to lenders as{' '}
              <span className="font-semibold">Pending qualification</span>. Lenders can add it to
              their wishlist.
            </li>
            <li>
              Your application undergoes a <span className="font-semibold">Due Diligence</span>{' '}
              process conducted by Enabling Finance Limited (EFL), focusing on collateral
              verification.
            </li>
            <li>
              Upon passing due diligence, the loan is marked as{' '}
              <span className="font-semibold">Qualified</span>.
            </li>
            <li>
              Lenders who added your loan to their wishlist are notified and can either submit
              counter-offers or approve the loan directly.
            </li>
            <li>
              A legally binding contract is generated, signed by all parties, including witnesses.
            </li>
            <li>Once the contract is finalized, your loan is approved, and funds are disbursed.</li>
          </ol>
        </div>
        <div className="text-sm text-gray-600 italic text-center mb-4">
          This process complies with the{' '}
          <span className="text-primary-100 font-semibold">
            Financial Institutions (Amendment) Act, 2016
          </span>{' '}
          and the{' '}
          <span className="font-semibold text-primary-100">
            Consumer Protection Guidelines, 2011
          </span>
          . Your data is securely managed under the{' '}
          <span className="font-semibold text-primary-100">
            Data Protection and Privacy Act, 2019
          </span>
          .
        </div>
        <Button className="mx-auto" onClick={() => navigate('/app')}>
          Back Home
        </Button>
      </div>
    </div>
  );
};

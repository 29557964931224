import Select from 'react-select';
import { FieldWrapper } from './FieldWrapper';

export const ReactSelectField2 = (props) => {
  const {
    label,
    options,
    error,
    name,
    onInputChange,
    caption,
    required,
    defaultValue,
    onChange,
    onMenuClose,
    isMulti = false,
  } = props;

  return (
    <FieldWrapper label={label} error={error}>
      {/* caption */}
      <div className="text-xs text-gray-500 flex justify-start">{caption}</div>
      <Select
        className="basic-single"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        onMenuClose={onMenuClose}
        name={name}
        defaultValue={defaultValue}
        required={required}
        isMulti={isMulti}
        onInputChange={onInputChange}
        options={options}
        onChange={onChange}
        maxMenuHeight={100}
      />
    </FieldWrapper>
  );
};

import { Spinner } from 'components/Elements';
import { MainLayout } from 'components/Layout';
import { lazyImport } from 'utils/lazyImport';
import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const { AnalyticsRoutes } = lazyImport(() => import('features/analytics'), 'AnalyticsRoutes');
const { ProductsRoutes } = lazyImport(() => import('features/products'), 'ProductsRoutes');
const { AssetsRoutes } = lazyImport(() => import('features/assets'), 'AssetsRoutes');
const { TransactionsRoutes } = lazyImport(
  () => import('features/transactions'),
  'TransactionsRoutes'
);
// const { PigeonLoansRoutes } = lazyImport(() => import('features/pigeonLoans'), 'PigeonLoansRoutes');
const { LendMoneyRoutes } = lazyImport(() => import('features/lendMoney'), 'LendMoneyRoutes');
const { MyLoanRoutes } = lazyImport(() => import('features/myLoan'), 'MyLoanRoutes');
const { MyLoansRoutes } = lazyImport(() => import('features/myLoans'), 'MyLoansRoutes');
const { MyPortfolioRoutes } = lazyImport(() => import('features/myPortfolio'), 'MyPortfolioRoutes');
const { ThreadsRoutes } = lazyImport(() => import('features/messages'), 'ThreadsRoutes');
const { WishlistRoutes } = lazyImport(() => import('features/wishlist'), 'WishlistRoutes');
const { Dashboard } = lazyImport(() => import('features/misc'), 'Dashboard');
const { Profile } = lazyImport(() => import('features/users'), 'Profile');
const { Users } = lazyImport(() => import('features/users'), 'Users');
const { ContractsRoutes } = lazyImport(() => import('features/contracts'), 'ContractsRoutes');
const { GuarantorsRoutes } = lazyImport(() => import('features/guarantors'), 'GuarantorsRoutes');
const { NotificationsRoutes } = lazyImport(
  () => import('features/notifications'),
  'NotificationsRoutes'
);
const { DueDiligenceRoutes } = lazyImport(
  () => import('features/dueDiligence'),
  'DueDiligenceRoutes'
);
const { SettingsRoutes } = lazyImport(() => import('features/settings'), 'SettingsRoutes');

const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const protectedRoutes = [
  { path: '/', element: <Dashboard /> },
  {
    path: '/app',
    element: <App />,
    children: [
      { path: '/app/products/*', element: <ProductsRoutes /> },
      { path: '/app/my-loans/*', element: <MyLoansRoutes /> },
      { path: '/app/assets/*', element: <AssetsRoutes /> },
      { path: '/app/transactions/*', element: <TransactionsRoutes /> },
      { path: '/app/lend-money/*', element: <LendMoneyRoutes /> },
      { path: '/app/my-loan/*', element: <MyLoanRoutes /> },
      { path: '/app/my-portfolio/*', element: <MyPortfolioRoutes /> },
      { path: '/app/accounts/*', element: <Users /> },
      // { path: '/app/pigeon-loans/*', element: <PigeonLoansRoutes /> },
      { path: '/app/analytics/*', element: <AnalyticsRoutes /> },
      { path: '/app/my-account', element: <Profile /> },
      { path: '/app/notifications/*', element: <NotificationsRoutes /> },
      { path: '/app/due-diligence/*', element: <DueDiligenceRoutes /> },
      { path: '/app/settings/*', element: <SettingsRoutes /> },
      { path: '/app/chats/*', element: <ThreadsRoutes /> },
      { path: '/app/wishlist/*', element: <WishlistRoutes /> },
      { path: '/app/contracts/*', element: <ContractsRoutes /> },
      { path: '/app/guarantors/*', element: <GuarantorsRoutes /> },
      { path: '/app/', element: <Dashboard /> },

      { path: '*', element: <Navigate to="." /> },
    ],
  },
];

export const COLLATERAL = {
  CATEGORIES: {
    VEHICLES: 'VEHICLES',
    COMPUTERS: 'COMPUTERS',
    TELEVISION: 'TELEVISION',
    SMART_PHONES: 'SMART_PHONES',
    OTHER_ELECTRONICS: 'OTHER_ELECTRONICS',
    REAL_ESTATE_WITH_LAND_TITLE: 'REAL_ESTATE_WITH_LAND_TITLE',
  },
};

import { Button, Dialog, DialogTitle } from 'components/Elements';
import React, { useState } from 'react';
import { useAuth } from 'lib/auth';
import { useCreateRepayment } from '../api/createRepayment';
import {
  DocumentUploadField,
  Form,
  InputField,
  ReactNumberFormatInputField2,
  SelectField,
} from 'components/Form';

export function CreateRepayment({ amount }) {
  const [show, setShow] = useState(false);

  const createRepaymentMutation = useCreateRepayment();

  const [paymentDoc, setPaymentDoc] = useState();

  const [amountReceived, setAmountReceived] = useState();
  const [paymentMethod, setPaymentMethod] = useState();

  const { user } = useAuth();

  const handleClose = () => {
    setShow(false);
  };

  const handleOpen = () => {
    setShow(true);
  };

  return (
    <div>
      <Button onClick={handleOpen} className="w-full whitespace-nowrap" size="sm">
        REPAY NOW
      </Button>
      <Dialog isOpen={show} onClose={handleClose} initialFocus={null}>
        <div className="inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <DialogTitle className="text-xl text-start font-bold">Repay Now</DialogTitle>
          <div className="mb-4 text-gray-600 font-sm">Make a repayment for your loan.</div>
          <>
            {createRepaymentMutation.isLoading ? (
              <>
                <div className="">
                  <div className=" p-8 flex flex-col items-center">
                    <svg
                      className="animate-spin h-8 w-8 text-blue-600 mb-4"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v8H4z"
                      ></path>
                    </svg>
                    <h2 className="text-lg text-center font-semibold text-gray-700">
                      Please wait, your repayment is being submitted...
                    </h2>
                  </div>
                </div>
              </>
            ) : (
              <>
                <Form
                  id="create-repayment"
                  onSubmit={async (values) => {
                    const bodyFormData = new FormData();
                    bodyFormData.append('user', user._id);
                    bodyFormData.append('amountReceived', amountReceived);
                    bodyFormData.append('paymentDoc', paymentDoc);
                    bodyFormData.append('paymentMethod', paymentMethod);
                    const result = await createRepaymentMutation.mutateAsync({
                      data: bodyFormData,
                    });
                    if (result) {
                      handleClose();
                    }
                  }}
                  options={{
                    defaultValues: {
                      amountReceived: amount,
                    },
                  }}
                >
                  {({ register, formState }) => (
                    <>
                      <span>How much do you want to repay?</span>
                      <br />
                      <ReactNumberFormatInputField2
                        prefix="UGX "
                        name="amountReceived"
                        // value={amount}
                        error={formState.errors['amountReceived']}
                        registration={register('amountReceived')}
                        onChange={(e) => {
                          setAmountReceived(e.target.value);
                        }}
                      />

                      <SelectField
                        label="Select Payment Method"
                        error={formState.errors['paymentMethod']}
                        registration={register('paymentMethod')}
                        options={[
                          { value: '', label: 'Select ... ' },
                          { value: 'CASH', label: 'Cash' },
                          { value: 'BANK', label: 'Bank' },
                          { value: 'MOBILE', label: 'Mobile' },
                        ]}
                        onChange={(e) => {
                          setPaymentMethod(e.target.value);
                        }}
                      />
                      <DocumentUploadField
                        label="Proof of Payment (Only images files allowed.) "
                        error={formState.errors['paymentDoc']}
                        onChange={(e) => {
                          setPaymentDoc(e.target.files[0]);
                        }}
                      />
                      <div className="flex flex-row items-center space-x-2">
                        <button
                          onClick={handleClose}
                          type="button"
                          className="mt-4 p-2 px-4 bg-gray-100 w-full rounded-2xl"
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="mt-4 p-2 px-4 w-full rounded-2xl text-white bg-primary-100"
                        >
                          Proceed
                        </button>
                      </div>
                    </>
                  )}
                </Form>
              </>
            )}
          </>
        </div>
      </Dialog>
    </div>
  );
}

import clsx from 'clsx';

export const CheckboxField = (props) => {
  const { label, disabled, value, name, caption, registration, onChange, error } = props;
  return (
    <div className="flex justify-start p-4">
      <div>
        <div className="form-check">
          <input
            className="form-check-input appearance-none h-4 w-4 border border-primary-100 rounded-sm bg-white checked:bg-primary-100 checked:border-primary-100 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
            type="checkbox"
            disabled={disabled}
            name={name}
            value={value}
            // {...registration}+
            onChange={onChange}
            id="flexCheckDefault"
          />
          <label
            className="form-check-label inline-block text-gray-900 font-semibold"
            htmlFor="flexCheckDefault"
          >
            {label}
          </label>
        </div>
        {error?.message && (
          <div
            role="alert"
            aria-label={error.message}
            className="text-sm font-semibold text-red-500"
          >
            {error.message}
          </div>
        )}
        <div className="mt-2 text-sm">{caption}</div>
      </div>
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import * as z from 'zod';
import { Button, Spinner } from 'components/Elements';
import { Form } from 'components/Form';
import { ReactSelectField } from 'components/Pigeon';
import { useMyAssets } from 'features/assets';
import storage from 'utils/storage';
import { Link, useNavigate } from 'react-router-dom';

const schema = z.object({
  collateral: z.array(
    z.string({
      required_error: 'collateral is required',
      invalid_type_error: 'collateral must be a string',
    })
  ),
});

export const Collateral = ({ nextStep, prevStep }) => {
  const myAssetsQuery = useMyAssets();

  const [collaterals, setCollaterals] = useState();

  const navigate = useNavigate();

  if (myAssetsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="sm" />
      </div>
    );
  }

  // Generate formatted options for the select field
  const assetOptions =
    myAssetsQuery.data?.map((asset) => {
      let label = asset.title; // Default label if no specific category match

      // Customize label based on asset.category
      if (asset.category === 'REAL_ESTATE_WITH_LAND_TITLE') {
        label = `${asset.title}, ${
          asset.real_estate?.land_location
        } - UGX ${asset.purchase_amount?.toLocaleString()}`;
      } else if (asset.category === 'VEHICLES') {
        label = `${asset.vehicles?.model} - UGX ${asset.purchase_amount?.toLocaleString()}`;
      }

      return {
        label,
        value: asset._id,
      };
    }) || [];

  return (
    <Form
      id="create-loan"
      onSubmit={() => {
        storage.setFormValues({
          collateral: collaterals,
        });
        nextStep();
      }}
      schema={schema}
    >
      {({ register, formState, watch, setValue }) => (
        <div className="px-12 md:px-24 pt-12 m">
          <div className="flex flex-row justify-between align-center">
            <div className="text-lg mb-6 font-semibold">Select your collateral Assets</div>
            <Link
              to="/app/assets/create?loan_application=true&application_level=4"
              className="text-primary-100 font-semibold text-sm"
            >
              Add Collateral
            </Link>
          </div>
          <div className="my-3">
            You can either select from your list of collaterals or add a new one by clicking the Add
            Collateral button. Don't worry, we shall save your loan progress. Once you're done
            adding collateral, you can simply click Borrow money again to continue.
          </div>
          <ReactSelectField
            label="Select from your assets"
            registration={register('collateral')}
            options={assetOptions}
            editorContent={watch('collateral')}
            setValue={() => {}}
            name="collateral"
            isMulti={true}
            defaultValue={watch('collateral')}
            error={formState.errors['collateral']}
            onChange={(e) => {
              formState.errors['collateral'] = '';
              const selectedValues = e.map((item) => item.value);
              setCollaterals(selectedValues);
              setValue('collateral', selectedValues);
            }}
          />
          <div className="mx-0 md:mx-32 flex flex-col space-y-3 mt-4">
            <div className="flex flex-row items-center space-x-2">
              <Button variant="outline" onClick={() => prevStep()} className="w-full">
                Previous
              </Button>
              <Button variant="dark" type="submit" className="w-full">
                Next
              </Button>
            </div>
          </div>
        </div>
      )}
    </Form>
  );
};

import { ContentLayout } from 'components/Layout';

import { Button, FullScreenSpinner } from 'components/Elements';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useContract } from '../api/getContract';
import { UPLOADS_URL } from 'config';
import { convertToWords, toWords } from 'utils/toWords';
import { formatDate } from 'utils/format';
import { formatText } from 'utils/formatText';
import { useRef } from 'react';
import { useAuth } from 'lib/auth';
import { useReactToPrint } from 'react-to-print';
import '../contracts.css';
import { SubmitBorrowerSignature } from '../components/SubmitBorrowerSignature';
import { formatDateToISO8601 } from 'utils/formatDateToISO8601';
import { UpdateAddress } from '../components/UpdateAddress';
import { numToWords } from 'utils/numToWords';
import { ContractCollateral } from '../components/ContractCollateral';
import { SubmitLenderSignature } from '../components/SubmitLenderSignature';
import { Download } from 'react-feather';
import Swal from 'sweetalert2';
import { useMyLoan } from 'features/myLoan/api/getMyLoan';
import { AuditLog } from './AuditLog';
import { ServiceFee } from '../components/ServiceFee';

export const Contract = () => {
  const { id } = useParams();

  const contractQuery = useContract({ id });

  const navigate = useNavigate();

  const { user } = useAuth();
  const contractDoc = useRef();
  const myLoanQuery = useMyLoan();

  const handlePrint = useReactToPrint({
    content: () => contractDoc.current,
    documentTitle: `EFL ${contractQuery.data?.product?.product_title?.toUpperCase()} AND REPAYMENT SCHEDULE_${contractQuery.data?.borrower?.basicInformation?.firstName?.toUpperCase()}_${contractQuery.data?.contract_date?.toLocaleString()}`,
    onBeforeGetContent: () => {
      if (contractQuery.data?.borrower_signature?.confirmed_by_borrower) {
        return true;
      } else {
        Swal.fire({
          title: 'Error',
          text: 'Please sign the contract before printing',
          icon: 'error',
          confirmButtonText: 'Ok',
        });
        return false;
      }
    },
  });

  if (contractQuery.isLoading || myLoanQuery.isLoading) {
    return <FullScreenSpinner />;
  }

  if (!contractQuery.data) return null;

  return (
    <ContentLayout
      title={'Contract'}
      end={
        <div className="flex flex-row items-center space-x-4">
          {myLoanQuery.data?.status !== 'NEW' &&
            myLoanQuery.data?.status !== 'DUE_DILIGENCE' &&
            myLoanQuery.data?.status !== 'EFL_APPROVED' &&
            myLoanQuery.data?.status !== 'OFFER_ACCEPTED' && (
              <button onClick={handlePrint}>
                <Download size={16} />
              </button>
            )}

          <Button size="sm" onClick={() => navigate('/app/contracts')}>
            Back
          </Button>
        </div>
      }
    >
      {/* <Breadcrumbs routes={routes} /> */}
      <div className=" mt-4">
        {!contractQuery.data?.borrower_signature?.confirmed_by_borrower &&
          user.role === 'BORROWER' && (
            <div className="bg-orange-100 text-sm border border-orange-500 p-4 rounded-xl shadow">
              <span className="font-bold text-lg">Important Notice!</span>
              <br />
              Please review your contract carefully and provide the following pending details;
              {!contractQuery.data?.borrower?.addressInformation?.permanentAddress && (
                <div className="">- Submit your location details</div>
              )}
              {!contractQuery.data?.borrower_signature?.confirmed_by_borrower && (
                <div className="">
                  - Sign Contract (
                  <button
                    onClick={() => {
                      const element = document.getElementById('borrower_signature');
                      if (element) {
                        element.scrollIntoView({ behavior: 'smooth' });
                      }
                    }}
                    className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                  >
                    At end of contract
                  </button>
                  )
                </div>
              )}
            </div>
          )}
        {!contractQuery.data?.lender_signature?.confirmed_by_lender && user.role === 'LENDER' && (
          <div className="bg-orange-100 text-sm border border-orange-500 p-4 rounded-xl shadow">
            <span className="font-bold text-lg">Important Notice!</span>
            <br />
            Please review your contract carefully and provide the following pending details;
            {!contractQuery.data?.lender_signature?.confirmed_by_lender && (
              <div className="">
                - Sign Contract (
                <button
                  onClick={() => {
                    const element = document.getElementById('lender_signature');
                    if (element) {
                      element.scrollIntoView({ behavior: 'smooth' });
                    }
                  }}
                  className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                >
                  At end of contract
                </button>
                )
              </div>
            )}
          </div>
        )}
      </div>

      {/* Contract */}
      <div className="mt-4 mb-4">
        <div ref={contractDoc} className="px-4 md:px-32 relative contract bg-white p-8 ">
          <div className="absolute inset-0 flex justify-center mt-72 pointer-events-none">
            <div
              className="text-lightblue-300 font-black opacity-10"
              style={{
                fontSize: '24rem',
              }}
            >
              EFL
            </div>
          </div>
          {contractQuery.data?.collateralCategory ===
          ('VEHICLES' || 'OTHER_ELECTRONICS' || 'SMART_PHONES' || 'COMPUTERS' || 'TELEVISION') ? (
            <>
              <div className="p-4 relative z-10">
                <div className="mx-auto  mb-4 text-sm md:text-md">
                  <div className="font-black mb-4 text-center">THE REPUBLIC OF UGANDA</div>
                  <div className="font-bold mb-4  text-center">
                    THE SECURITY INTEREST IN MOVABLE PROPERTY ACT, CAP. 293
                  </div>
                  <div className="font-bold mb-4 text-center">
                    THE ELECTRONICS SIGNATURE ACT, 2011
                  </div>
                  <div className="font-bold mb-4 text-center">CHATTEL MORTGAGE DEED</div>
                  <div className="font-bold mb-8 text-center">FINABLR ELITE COLLATERAL LOAN </div>
                  <div className="font-bold text-center uppercase underline mb-3">
                    {contractQuery.data?.product?.product_title} agreement
                  </div>
                  <div className="text-center mt-4 mb-6">
                    This Chattel Mortgage is made this{' '}
                    <span
                      className={`font-bold ${
                        formatDate(contractQuery.data?.contract_date) === 'Pending' &&
                        `text-red-500`
                      }`}
                    >
                      {formatDateToISO8601(contractQuery.data?.contract_date)}
                    </span>
                  </div>
                  <div className="font-bold text-center mb-6">BETWEEN</div>
                  <div className="">
                    <span className="font-bold">
                      <span>
                        <span className="text-red-500">
                          {contractQuery.data?.borrower?.basicInformation?.firstName}{' '}
                          {contractQuery.data?.borrower?.basicInformation?.lastName}
                        </span>{' '}
                        of{' '}
                      </span>
                      <span className="">
                        {contractQuery.data?.borrower?.addressInformation?.permanentAddress ? (
                          <>{contractQuery.data?.borrower?.addressInformation?.permanentAddress}</>
                        ) : (
                          <>
                            {user.role === 'BORROWER' ? (
                              <UpdateAddress />
                            ) : (
                              'P.O BOX # KAMPALA OF KAMPALA'
                            )}
                          </>
                        )}
                      </span>
                    </span>{' '}
                    (hereinafter referred to as the “<span className="font-bold">Mortgagor</span>”
                    which expression shall where the context so admits includes the Mortgagor’s
                    assignees, successors in title, administrators, executors and permitted
                    assignees) of the first part;
                  </div>
                  <div className="mb-6 font-bold text-center">AND</div>
                  <div className="mb-6">
                    <span className="font-bold">
                      ENABLING FINANCE LTD operating through her ‘Finablr’ app
                    </span>
                    , of{' '}
                    <span className="font-bold">P.O. Box 134920, Kampala Tel: 0706400057 </span>
                    (hereinafter referred to as “<span className="font-bold">the Lender</span>”
                    which expression shall where the context so admits includes its successors in
                    title, legal representatives and assigns) of the one part;
                  </div>
                </div>
                <div className="mb-4 flex flex-col items-start space-y-6">
                  <div className="font-bold underline-offset-1">WHEREAS</div>
                  <div className="">
                    A. The Mortgagor is the registered proprietor and owner of property described in
                    the Schedule hereto (herein referred to as the "Property" or “Chattels”) and is
                    authorised to mortgage the said Property for the purpose of securing a credit
                    facility obtained pursuant to a facility agreement dated{' '}
                    <span className="text-red-500 font-bold">
                      {formatDateToISO8601(contractQuery.data?.contract_date)}
                    </span>
                    (herein referred to as the “Facility Letter/ Facility Agreement”) of UG. SHS.
                    {contractQuery.data?.loanAmount?.toLocaleString()} (
                    {toWords && toWords.convert(contractQuery.data?.loanAmount)} ONLY) (herein
                    referred to as “the Facility”), over the Property for the purpose of securing
                    the Facility.
                  </div>
                  <div className="">
                    B. The Lendor has agreed to the request of the Mortgagor and for the benefit of
                    the said Mortgagor to provide the Facility and has executed the Facility Letter
                    for this purpose and it is agreed between the parties hereto that the Facility
                    Letter shall be deemed integral and read together with this Chattel Mortgage
                    Deed.
                  </div>
                  <div className="">
                    C. Under this Chattel Mortgage Deed, the Mortgagor has agreed to mortgage the
                    Property as security for the repayment of all the principal sums and interest
                    together with costs, expenses and commission accruing from the Facility to the
                    Mortgagor.
                  </div>
                  <div className="">
                    This Chattel Mortgage Deed is in addition to other deeds, guarantees and
                    undertakings by the Mortgagor under the Facility Letter.
                  </div>
                  <div className="font-bold">NOW IT IS HEREBY AGREED AND DECLARED as follows:</div>
                  <div className="">
                    <div className="font-bold">1. Definitions </div>
                    In this Chattel Mortgage Deed (including its recitals) the following expressions
                    shall, unless the context otherwise requires, have the following meanings:
                  </div>
                  <div className="">
                    1.1 <span className="font-bold">“Chattels”</span> or{' '}
                    <span className="font-bold">“Property”</span> means the property described in
                    the Schedule (including any component parts of those assets from time to time
                    held by the Mortgagor (whether or not attached to those assets)), together with
                    all additions, alterations, substitutions, replacements, renewals or
                    modifications of or to those assets from time to time, and all accessories to
                    those assets from time to time (including maintenance and other records,
                    manuals, handbooks, data, drawings and schematics relating to those assets or
                    documents relating to warranties and patent indemnities given by manufacturers
                    or suppliers of those assets);
                  </div>
                  <div className="">
                    1.2 <span className="font-bold">"Documents”</span> means all logbooks,
                    maintenance records, record books, manuals, handbooks, drawings, technical data
                    and all other documents relating to the Chattels;
                  </div>
                  <div className="">
                    1.3 <span className="font-bold">“Secured Liabilities”</span> means all present
                    and future obligations and liabilities of the Mortgagor to the Lendor, whether
                    actual or contingent and whether owed jointly or severally, as principal or
                    surety or in any other capacity, under or in connection with the Facility
                    Agreement or this Chattel Mortgage Deed together with all interest (including,
                    without limitation, default interest) accruing in respect of those obligations
                    or liabilities;
                  </div>
                  <div className="">
                    1.4 <span className="font-bold">“SIMPA”</span> means the Security Interest in
                    Movable Property Act, Cap. 239;
                  </div>
                  <div className="">
                    1.5 <span className="font-bold">“Uganda Shillings”</span> and “
                    <span className="font-bold">Ug.Shs.</span>” means the lawful currency for the
                    time being of the Republic of Uganda; and
                  </div>
                  <div className="">
                    1.6 <span className="font-bold">“USD”</span> and{' '}
                    <span className="font-bold">“$”</span> means the lawful currency of the United
                    States of America.
                  </div>
                  <div className="">
                    <span className="font-bold">2. Covenant to Pay</span>
                    <br />
                    The Mortgagor shall subject to the provisions of the Facility Agreement and this
                    Chattel Mortgage pay and discharge the Secured Liabilities to the Lendor on
                    demand on the due date for payment, and pay interest on the principal so due,
                    and other Facility charges as set out in the Facility Agreement.
                  </div>
                  <div className="">
                    <span className="font-bold">3. Charging Provisions</span>
                    <br />
                    The Mortgagor, with full title guarantee, charges in favour of the Lendor as a
                    continuing security for the payment and discharge of the Secured Liabilities by
                    way of first mortgage:
                  </div>
                  <div className="">3.1 the Chattels and the Documents;</div>
                  <div className="">
                    3.2 the benefit of all contracts and agreements, including all conditions and
                    warranties, entered into at any time relating to the title, merchantable
                    quality, fitness for purpose, description, condition, operation, use, servicing,
                    maintenance or repair of the Chattels;
                  </div>
                  <div className="">
                    3.3 the full benefit of all insurances from time to time in force in relation to
                    the mortgaged Property, and
                  </div>
                  <div className="">
                    3.4 any money payable to the Mortgagor for, or in connection with, a disposal by
                    way of sale or otherwise of the Chattels, including any deposit, sum on account
                    or instalment.
                  </div>
                  <div className="font-bold">4. Cost and fees</div>
                  <div className="">
                    4. 1 Interest Rate: The loan shall attract interest at the rate of{' '}
                    <span className="font-bold text-red-500">
                      {contractQuery.data?.loan?.interestRate}% per month
                    </span>
                    , calculated on {formatText(contractQuery.data?.loan?.paymentMethod)}{' '}
                    amortization basis, for a period of {contractQuery.data?.loan?.term} (
                    {toWords && toWords.convert(contractQuery.data?.loan?.term)}) months.
                  </div>
                  <div className="">
                    4.2 Application and Administration Fees: An application, establishment,
                    administration, and other related fees shall be charged at a rate of{' '}
                    <span className="font-bold text-red-500">6.5%</span> of the loan amount
                    approved.
                  </div>
                  <div className="">
                    4.3 Collateral Perfection Costs: All costs related to the stamping, fees, seals,
                    and any other expenses incurred in connection with the perfection of collateral
                    associated with the loan shall be borne by the Borrower.
                  </div>
                  <div className="">
                    4.4 Penalty for Default: In the event that the Borrower defaults on any monthly
                    installment for more than 7 (Seven) days, a late fee of 12.5% shall be applied
                    to the principal installment amount in default for that respective month. This
                    penalty shall be applicable to all installments during the active loan period.
                  </div>
                  <div className="">
                    4.5 Early Repayment Option: The Borrower may opt to make an early repayment of
                    the principal or the entire loan amount at no additional cost, provided that a
                    three (3) days’ notice is given to Enabling Finance Ltd (EFL) through its
                    official channels.
                  </div>
                  <div className="">
                    <span className="font-bold">5. Representations and Warranties </span>
                    The Mortgagor makes the following representations and warranties to the Lendor:
                  </div>
                  <div className="">
                    <span className="font-bold">5.1 Ownership of Secured Assets</span>
                    The Mortgagor is the sole legal and beneficial owner of, and has good, valid and
                    marketable title to the Chattels.
                  </div>
                  <div className="">
                    <span className="font-bold">5.2 No Security</span>
                    The Chattels are free from any security other than the security created by this
                    Chattel Mortgage Deed.
                  </div>
                  <div className="">
                    <span className="font-bold">5.3 No Adverse Claims</span>
                    The Mortgagor has not received, or acknowledged notice of, any adverse claim by
                    any person in respect of the Chattels or any interest in them.
                  </div>

                  <div className="">
                    <span className="font-bold">5.4 No Fixing</span>
                    None of the Chattels (or any part of them) is or will be treated as being fixed
                    to any land, premises or other property.
                  </div>
                  <div className="">
                    <span className="font-bold">5.5 No Adverse Covenants </span>
                    <span className="font-bold">There are no covenants</span>, agreements,
                    reservations, conditions, interests, rights or other matters whatsoever that
                    materially and adversely affect the Chattels.
                  </div>
                  <div className="">
                    <span className="font-bold">5.6 No Breach of Laws</span>
                    There is no breach of any law or regulation that materially and adversely
                    affects the Chattels.
                  </div>
                  <div className="">
                    <span className="font-bold">No Prohibitions</span>
                    There is no prohibition on assignment in any insurance policy or relevant
                    agreement and the entry into this Chattel Mortgage Deed by the Mortgagor does
                    not, and will not, constitute a breach of any insurance policy or any other
                    policy, agreement, document, instrument or obligation binding on the Mortgagor
                    or the Mortgagor’s assets.
                  </div>
                  <div className="">
                    <span className="font-bold">5.8 Enforceable Security</span>
                    This Chattel Mortgage Deed constitutes and will constitute the legal, valid,
                    binding and enforceable obligations of the Mortgagor, and is, and will continue
                    to be, effective security over all and every part of the Chattels in accordance
                    with its terms.
                  </div>
                  <div className="">
                    <span className="font-bold">
                      5.9 Times for making Representations and Warranties
                    </span>
                    The Mortgagor makes the representations and warranties set out in clause 4.1 to
                    clause 4.8 to the Lendor on the date of this Chattel Mortgage Deed and these are
                    deemed to be repeated on each day that the Secured Liabilities remain
                    outstanding.
                  </div>
                  <div className="">
                    <span className="font-bold">6. Covenants</span>
                    The Mortgagor accordingly covenants with the Lendor as follows:
                  </div>
                  <div className="">
                    6.1 Not at any time while, any principal and interest payments and monies remain
                    owing, pursuant to the Facility Agreement do or allow any act or deed whereby
                    the Property hereby mortgaged shall or may become prejudicially affected.
                  </div>
                  <div className="">
                    6.2 At all times while any monies herein secured remain owing to this security
                    keep and maintain all and singular parts of the Property hereby mortgaged in
                    good order and condition in which they are at the date hereof and if any of the
                    parts of the Property are destroyed or damaged or cease to exist, the Mortgagor
                    will repair such damage or replace the Property so destroyed or ceasing to exist
                    and if so required by the Lendor, execute any instrument that may be necessary
                    to give the Lendor security over the Property, replacing any parts which have
                    been destroyed or have ceased to exist.
                  </div>
                  <div className="">
                    6.3 Not at any time, except with the prior written consent of the Lendor:
                  </div>
                  <div className="">
                    6.3.1 remove the Property or any parts thereof from the country without the
                    prior written consent of the Lendor except for necessary repairs for which the
                    Lendor will be given prior notice, and certificate of repairs issued after
                    repairs shall also be made available to the Lendor; or
                  </div>
                  <div className="">
                    6.3.2 create, purport to create or permit to subsist any security on, or in
                    relation to, any Chattels other than the security created by this Chattel
                    Mortgage Deed; or
                  </div>
                  <div className="">
                    6.3.3 sell, assign, transfer, part with possession of or otherwise dispose of in
                    any manner (or purport to do so) all or any part of, or any interest in, the
                    Chattels; or
                  </div>
                  <div className="">
                    6.3.4 create or grant (or purport to create or grant) any interest in the
                    Chattels in favour of a third party.
                  </div>

                  <div className="">6.4 Insurance</div>
                  <div className="">
                    6.4.1 During the continuance of this security keep the Property insured
                    comprehensively from all risks, including credit life cover of the Mortgagor, as
                    the Lendor may from time to time require to the full replacement value thereof
                    with an insurance company approved by the Lendor in the names of the Lendor and
                    will duly pay premiums and other monies necessary for keeping the policies
                    provided that in default of premiums the Lendor may pay and claim costs on the
                    Mortgagor.
                  </div>
                  <div className="">
                    6.4.2 The Mortgagor shall, if requested by the Lendor, produce to the Lendor
                    each policy, certificate or cover note relating to the insurance required by
                    clause 5.4.1 above.
                  </div>
                  <div className="">
                    6.4.3 The Mortgagor shall, if requested by the Lendor, procure that a note of
                    the Lendor’s interest is endorsed on each insurance policy effected or
                    maintained by it in accordance with clause 5.4.1 above or ensure that each
                    insurance policy effected or maintained by it in accordance with clause 5.4.1
                    above contains a loss payee clause under which the Lendor is named as first loss
                    payee.
                  </div>
                  <div className="">
                    6.4.4 All monies received on any insurance whatsoever in respect of loss or
                    damage by fire or otherwise to the said Property and their accessories or any
                    part thereof shall, as the Lendor requires either be applied in making good the
                    loss or damage or towards payment of principal and interest payments and other
                    monies hereby secured as a matter of priority.
                  </div>
                  <div className="font-bold">7. Enforceability</div>
                  <div className="">
                    7.2 The security constituted by this Chattel Mortgage Deed will become
                    enforceable at any time after the Lendor has demanded payment of the Secured
                    Liabilities, and upon default, the Lendor or its agents or any receiver shall be
                    at liberty and without recourse to court realize the security herein constituted
                    in accordance with Part VI of the SIMPA and any other relevant laws being in
                    force in Uganda from time to time.
                  </div>
                  <div className="">
                    7.2 Provided always and it is hereby mutually agreed and declared as follows: -
                  </div>
                  <div className="">
                    7.2.1 upon default it shall be lawful for the Lendor or its agents/servants to
                    enter upon the premises in which the Chattels or any of them shall be and seize
                    or take possession of the whole or any part thereof of the Chattels and after
                    expiration of fourteen (14) days from the date of such seizing/taking possession
                    to sell them either by public auction or private treaty without recourse to
                    court and for the purposes of this instrument, the Mortgagor hereby appoints the
                    Lendor or any receiver as its duly constituted agent and attorney with
                    irrevocable powers in the name and on behalf of the Mortgagor to execute and do
                    any assurances, acts or things and exercise all powers of the Lendor herein
                    provided;
                  </div>
                  <div className="">
                    7.2.2 that until the Mortgagor makes default in the payment of any of the moneys
                    hereby secured, or in the observance or performance of any covenant or condition
                    in the Facility Letter and/or this Chattel Mortgage Deed, herein expressed or
                    implied, and on the Mortgagor’s part to be observed or performed, or until the
                    Mortgagor becomes insolvent or winding up commences or receivership is preferred
                    or until execution is levied against the goods of the Mortgagor and such
                    execution is not stayed or satisfied within thirty (30) days, the Mortgagor may
                    retain possession and use of the Chattels herein charged;
                  </div>
                  <div className="">
                    7.2.3 that the giving by the Mortgagor to the Lendor of any bill of exchange or
                    promissory note for the whole or any part of the money hereby secured shall not,
                    until such bill or promissory note is honoured or met, be considered as payments
                    or on account of the moneys secured by this instrument or in any way affect or
                    alter the rights or powers of the Lendor by virtue of this instrument and no
                    promissory note or bill of exchange which before, at or at any time after
                    execution of this instrument may be given by the whole or any portion of the
                    moneys hereby secured or the remedy thereon of the Lendor or of the holder
                    thereof shall merge in the covenants herein expressed or implied.
                  </div>
                  <div className="font-bold">8. Release</div>
                  <div className="">
                    If the Mortgagor shall retire and pay to the Lendor all principal and interest
                    payments and moneys under and pursuant to this Chattel Mortgage Deed and the
                    Facility Agreement, the obligations of the Mortgagor shall be discharged, and
                    the Lendor shall release the charge over the Chattels.
                  </div>
                  <div className="">9. Power of Attorney</div>
                  <div className="">
                    The Mortgagor irrevocably appoints the Lendor to be the attorney of the
                    Mortgagor to execute and do all such deeds as may be expedient for the full
                    exercise of all or any of the powers of the Lendor. The Mortgagor hereby
                    covenants with the Lendor to ratify and confirm transactions, which any such
                    attorney may lawfully execute or do. The Mortgagor irrevocably acknowledges and
                    agrees that the said power of attorney is given to the Lendor to secure the
                    performance of those obligations owed to the Lendor.
                  </div>

                  <div className="">10. Miscellaneous</div>
                  <div className="font-bold">10.1.1 Waiver</div>
                  <div className="">
                    No failure or delay by the Lendor in exercising any right or remedy shall
                    operate as a waiver thereof nor shall any single or partial exercise or waiver
                    of any right or remedy preclude its further exercise or the exercise of any
                    other right or remedy.
                  </div>
                  <div className="font-bold">10.1.2 Severability </div>
                  <div className="">
                    Each of the provisions of this Chattels Mortgage Deed is severable and distinct
                    from the others and if at any time one or more of such provisions is or becomes
                    invalid, illegal or unenforceable, the validity, legality and enforceability of
                    the remaining provisions hereof shall not in any way be affected or impaired
                    thereby.
                  </div>
                  <div className="font-bold">10.1.3 Costs</div>
                  <div className="">
                    The Mortgagor shall meet all costs, charges and expenses as shall or may be
                    incurred by the Lendor in relation to the creation of this Chattel Mortgage or
                    as a result of the occurrence of any breach of the terms and conditions of this
                    Chattel Mortgage Deed or which may otherwise be deemed necessary by the Lendor
                    for the purpose of protecting or defending its interest and rights in the
                    mortgaged Property or any rights conferred under this Chattel Mortgage Deed or
                    the Facility Agreement.
                  </div>
                  <div className="font-bold">10.1.4 Notices</div>
                  <div className="">
                    Any notice to be served or communication to be made under or in connection with
                    this Chattel Mortgage Deed shall be made at the addresses indicated in this
                    Chattel Mortgage Deed or in the Facility Agreement.
                  </div>

                  <div className="font-bold">10.1.5 Amendments, waivers and consents </div>
                  <div className="">
                    Any amendments or waiver of, or any consent given under any provision of this
                    Chattel Mortgage Deed shall be in writing and, in the case of an amendment,
                    signed by the parties.
                  </div>
                  <div className="font-bold">10.1.6 Jurisdiction</div>
                  <div className="">
                    This Chattel Mortgage Deed shall be governed by and interpreted in accordance
                    with the laws of Uganda and the Mortgagor hereby irrevocably submits to the
                    non-exclusive jurisdiction of the High Court of Uganda, but this Chattel
                    Mortgage Deed may be enforced in any court of competent jurisdiction.
                  </div>

                  <div className="">
                    IN WITNESS WHEREOF the parties hereto have hereunto caused their respective
                    Common Seals and hands to be affixed hereto, the date, month and year first
                    above mentioned.
                  </div>

                  {/* Borrower & Borrower Witness */}

                  <div className="grid grid-cols-1 md:grid-cols-2 items-center justify-center gap-4 w-full">
                    <div className="">
                      <div className="font-bold">SIGNED by the said: MORTGAGOR:</div>
                      <div className="font-bold">
                        Name:{' '}
                        <span className="text-red-500">
                          {contractQuery.data?.borrower?.basicInformation?.firstName}{' '}
                          {contractQuery.data?.borrower?.basicInformation?.lastName}
                        </span>{' '}
                      </div>
                      <div className="font-bold my-4">
                        {contractQuery.data?.borrower_signature?.confirmed_by_borrower && (
                          <div className="flex flex-row items-baseline">
                            <div className="">Signature:</div>
                            <div className="ml-2 border-b-2 border-dashed">
                              <img
                                src={
                                  UPLOADS_URL +
                                  '/signatures/' +
                                  contractQuery.data?.borrower_signature?.location
                                }
                                className="h-10 pb-2"
                                alt="Signature"
                              />
                            </div>
                          </div>
                        )}
                        {!contractQuery.data?.borrower_signature?.confirmed_by_borrower &&
                          user.role === 'BORROWER' && (
                            <SubmitBorrowerSignature
                              location={contractQuery.data?.borrower_signature?.location}
                              id={id}
                            />
                          )}
                      </div>
                    </div>
                    <div className="">
                      <div className="">
                        {' '}
                        In the presence of: <span className="font-bold">WITNESS</span>
                      </div>
                      <div className="flex font-bold flex-row items-baseline">
                        <div className="">Name:</div>
                        <div className="ml-2 border-b-2 border-dashed">
                          {contractQuery.data?.borrower_witness?.name
                            ? contractQuery.data?.borrower_witness?.name
                            : 'DINAH JASPER ACEN'}
                        </div>
                      </div>
                      <div className="font-bold my-4">
                        {contractQuery.data?.borrower_witness && (
                          <div className="flex flex-row items-baseline">
                            <div className="">Signature:</div>
                            <div className="ml-2 border-b-2 border-dashed">
                              {contractQuery.data?.borrower_witness?.signature ? (
                                <img
                                  src={
                                    UPLOADS_URL +
                                    '/signatures/' +
                                    contractQuery.data?.borrower_witness?.signature
                                  }
                                  className="h-10 pb-2"
                                  alt="Signature"
                                />
                              ) : (
                                <div className="w-16 h-10"></div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* EFL & Lender Witness */}
                <div className="mb-4 mt-12">
                  <div className="grid grid-cols-1 items-top md:grid-cols-2 gap-4 mb-12">
                    <div className="">
                      <div className="font-bold">SIGNED for and on behalf of </div>
                      <div className="font-bold">ENABLING FINANCE LIMITED</div>
                      <div className="font-bold my-3">name: YYEYO LUKE</div>
                      <div className="font-bold">Position: CHIEF EXECUTIVE OFFICER</div>
                      <div className="font-bold">
                        <div className="flex flex-row items-baseline">
                          <div className="">Signature:</div>
                          <div className="ml-2 border-b-2 border-dashed">
                            {contractQuery.data?.efl_witness?.signature ? (
                              <img
                                src={
                                  UPLOADS_URL +
                                  '/signatures/' +
                                  contractQuery.data?.efl_witness?.signature
                                }
                                className="h-10 pb-2"
                                alt="Signature"
                              />
                            ) : (
                              <div className="w-16 h-10"></div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="">
                        {' '}
                        In the presence of: <span className="font-bold">WITNESS (Lender)</span>
                      </div>
                      <div className="font-bold">
                        Name:{' '}
                        <span className="text-red-500">
                          {contractQuery.data?.lender?.basicInformation?.firstName}{' '}
                          {contractQuery.data?.lender?.basicInformation?.lastName}
                        </span>{' '}
                      </div>
                      <div className="font-bold my-4">
                        {contractQuery.data?.lender_signature?.confirmed_by_lender && (
                          <div className="flex flex-row items-baseline">
                            <div className="">Signature:</div>
                            <div className="ml-2 border-b-2 border-dashed">
                              <img
                                src={
                                  UPLOADS_URL +
                                  '/signatures/' +
                                  contractQuery.data?.borrower_signature?.location
                                }
                                className="h-10 pb-2"
                                alt="Signature"
                              />
                            </div>
                          </div>
                        )}
                        {!contractQuery.data?.lender_signature?.confirmed_by_lender &&
                          user.role === 'LENDER' && (
                            <SubmitLenderSignature
                              location={contractQuery.data?.lender_signature?.location}
                              id={id}
                            />
                          )}
                      </div>
                    </div>
                  </div>

                  {/* <div className="grid grid-cols-2 gap-4 mb-12 items-center">
                    <div className="">
                      <div className="" id="borrower_signature">
                        SIGNED by
                      </div>
                      <div className="">
                        {contractQuery.data?.borrower?.basicInformation?.username}
                      </div>
                      <div className=""></div>
                      <div className=""></div>
                    </div>
                    <div className="">
                      <div className="">
                        {contractQuery.data?.borrower_signature?.confirmed_by_borrower && (
                          <img
                            src={
                              UPLOADS_URL +
                              '/signatures/' +
                              contractQuery.data?.borrower_signature?.location
                            }
                            className="h-16"
                            alt="Signature"
                          />
                        )}
                        {!contractQuery.data?.borrower_signature?.confirmed_by_borrower &&
                          user.role === 'BORROWER' && (
                            <SubmitBorrowerSignature
                              location={contractQuery.data?.borrower_signature?.location}
                              id={id}
                            />
                          )}
                      </div>
                      <div className="">THE BORROWER</div>
                    </div>
                  </div>
                  <div className="mb-12 font-semibold" id="lender_signature">
                    In the presence of:
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="">
                      <div className="">SIGNED by</div>
                      <div className="">
                        {contractQuery.data?.lender?.basicInformation?.username}
                      </div>
                      <div className=""></div>
                      <div className=""></div>
                    </div>
                    <div className="">
                      <div className="">
                        {contractQuery.data?.lender_signature?.confirmed_by_lender && (
                          <img
                            src={
                              UPLOADS_URL +
                              '/signatures/' +
                              contractQuery.data?.lender_signature?.location
                            }
                            className="h-16"
                            alt="Signature"
                          />
                        )}
                        {!contractQuery.data?.lender_signature?.confirmed_by_lender &&
                          user.role === 'LENDER' && (
                            <SubmitLenderSignature
                              location={contractQuery.data?.lender_signature?.location}
                              id={id}
                            />
                          )}
                      </div>
                      <div className="">WITNESS</div>
                    </div>
                  </div> */}
                </div>

                <div className="mb-4">
                  <div className="mb-4">
                    <div className="font-bold underline text-center mb-4">
                      {' '}
                      SCHEDULE: PARTICULARS OF MORTGAGED PROPERTY
                    </div>
                    <div className="mb-4">
                      I/We, being the Mortgagor, do hereby declare that the Property/Chattels listed
                      below and pledged as security belong to me/us and are not subject to any other
                      claim by any person or institution whatsoever.
                    </div>
                    <div className="mb-4">
                      I/We hereby agree that the said Chattels be taken as security for the Facility
                      advanced to me/us by ENABLING FINANCE LIMITED of P.O. Box 134920, Kampala,
                      Uganda.
                    </div>

                    <div className="">
                      <table className="table-auto w-full border-collapse border border-gray-300">
                        <thead className="bg-gray-100">
                          <tr>
                            <th className="border border-gray-300 px-4 py-2 text-left"></th>
                            <th className="font-bold border border-gray-300 px-4 py-2 text-left">
                              PARAMETER
                            </th>
                            <th className="font-bold border border-gray-300 px-4 py-2 text-left">
                              PARTICULARS
                            </th>
                          </tr>
                        </thead>
                        {contractQuery.data?.collateralCategory === 'VEHICLES' && (
                          <tbody>
                            {contractQuery.data?.loan?.collateral?.map((row, index) => (
                              <tr key={index} className="odd:bg-white even:bg-gray-50">
                                <td className="border border-gray-300 px-4 py-2"></td>
                                <td className="border border-gray-300 px-4 py-2">MOTOR VEHICLE</td>
                                <td className="border border-gray-300 px-4 py-2">
                                  •⁠ ⁠Make/Brand: {row.vehicles?.brand}
                                  <br />
                                  •⁠ ⁠Model: {row.vehicles?.model}
                                  <br />
                                  •⁠ ⁠No. Plate: {row.vehicles?.no_plate?.slice(0, 2)}
                                  <br />
                                  •⁠ ⁠Year of Manufacture: {row.vehicles?.year_of_manufacture}
                                  <br />
                                  •⁠ ⁠Engine No: {row.vehicles?.engine_no}
                                  <br />
                                  •⁠ ⁠Chasis No: {row.vehicles?.chasis_no}
                                  <br />
                                  •⁠ ⁠Colour: {row.vehicles?.color}
                                  <br />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="p-4 relative z-10">
                <div className="mx-auto  mb-4 text-sm md:text-md">
                  <div className="font-black mb-4 text-center">THE REPUBLIC OF UGANDA</div>
                  <div className="font-bold mb-4  text-center">
                    IN THE MATTER OF THE CONTRACTS ACT NO. 7 OF 2010
                  </div>
                  <div className="font-bold mb-4 text-center">AND</div>
                  <div className="font-bold mb-4 text-center">
                    IN THE MATTER OF THE TIER 4 MICROFINANCE INSTITUTIONS AND
                  </div>
                  <div className="font-bold mb-8 text-center">MONEYLENDERS ACT NO. 13 OF 2016</div>
                  <div className="font-bold text-center uppercase underline mb-3">
                    {contractQuery.data?.product?.product_title} agreement
                  </div>
                  <div className="text-center mt-4 mb-6">
                    <span className="font-bold text-center">THIS AGREEMENT </span> is made this day
                    of{' '}
                    <span
                      className={`font-bold ${
                        formatDate(contractQuery.data?.contract_date) === 'Pending' &&
                        `text-red-500`
                      }`}
                    >
                      {formatDateToISO8601(contractQuery.data?.contract_date)}
                    </span>
                  </div>
                  <div className="font-bold text-center mb-6">BETWEEN</div>
                  <div className="mb-6">
                    <span className="font-bold">
                      ENABLING FINANCE LTD operating through her ‘Finablr’ app
                    </span>
                    , of <span className="font-bold">P.O. Box 12284, Kampala Tel: 0706400057 </span>
                    (hereinafter referred to as
                    <span className="font-bold"> “the Lender"</span> which expression shall where
                    the context so admits includes its successors in title, legal representatives
                    and assigns) of the one part;
                  </div>
                  <div className="mb-6 font-bold text-center">AND</div>
                  <div className="">
                    <div className="font-bold">
                      <div>{contractQuery.data?.borrower?.basicInformation?.firstName} of </div>
                      <div className="">
                        {contractQuery.data?.borrower?.addressInformation?.permanentAddress ? (
                          <>{contractQuery.data?.borrower?.addressInformation?.permanentAddress}</>
                        ) : (
                          <>
                            <UpdateAddress />
                          </>
                        )}
                      </div>
                    </div>
                    , (hereinafter referred to as “<span className="font-bold">the Borrower</span>”,
                    (which expression shall where the context so admits include his/her successors
                    in title, legal representatives and assigns) of the other part.
                  </div>
                </div>
                <div className="mb-4 flex flex-col items-start space-y-6">
                  <div className="font-bold">
                    NOW THEREFORE THE PARTIES HERETO AGREE AS FOLLOWS: -
                  </div>
                  <div className="">
                    1. Upon the Borrower’s application using the EFL-Finablr App and acceptance of
                    the loan offer, the Lender hereby agrees to advance to the Borrower a loan of{' '}
                    <span className="font-bold">
                      Ug. Shs. {contractQuery.data?.loanAmount?.toLocaleString()} (
                      {contractQuery.data?.loanAmount &&
                        toWords.convert(contractQuery.data?.loanAmount)}{' '}
                      Only)
                    </span>{' '}
                    on such terms and conditions laid down in the Finablr platform and the Borrower
                    hereby accepts responsibility for all obligations and debts under this agreement
                    and acknowledges such indebtedness to the Lender.
                  </div>
                  <div className="">
                    2. The loan shall be used for the purpose of{' '}
                    <span className="font-bold uppercase">{contractQuery.data?.loan?.purpose}</span>
                    ; diversion of loan funds to other uses shall not be permitted and will be
                    considered a breach of agreement on loan use. It may also cause EFL to restrict
                    future loans to the Borrower or to recall the loan altogether. EFL has a
                    continuing right to investigate whether the loan was used for the intended
                    purpose.
                  </div>
                  <div className="">
                    3. The loan shall be for a period of{' '}
                    <span className="font-bold">3 (Three) months</span> with a grace period of{' '}
                    <span className="font-bold">three (3) days</span> afterward.
                  </div>
                  <div className="">
                    4. The loan shall attract interest at the rate of{' '}
                    <span className="font-bold">
                      {contractQuery.data?.loan?.interestRate}% per month
                    </span>{' '}
                    for the{' '}
                    <span className="font-bold">
                      {contractQuery.data?.loan?.term} (
                      {contractQuery.data?.loanAmount &&
                        toWords.convert(contractQuery.data?.loan?.term)}
                      ) months{' '}
                    </span>
                    on{' '}
                    <span className="font-bold uppercase">
                      {formatText(contractQuery.data?.loan?.paymentMethod)}
                    </span>
                    .
                  </div>
                  <div className="">
                    5. Once overdue, the outstanding loan principal amount shall attract a late fee
                    at the rate of <span className="font-bold">0.37% per day. (11% per month)</span>{' '}
                    past the grace period.
                  </div>
                  <div className="">
                    6. Application, establishment, administration and other fees shall be charged at
                    a rate of{' '}
                    <span className="font-bold">
                      <ServiceFee
                        amount={contractQuery.data?.loanAmount}
                        serviceFees={contractQuery.data?.product?.service_fees}
                      />
                    </span>{' '}
                    of the loan amount approved.
                  </div>
                  <div className="">
                    7. Costs of stamps, fees, seals and other costs in connection with perfecting of
                    collateral associated with the loan shall be borne by the Borrower.
                  </div>
                  <div className="">
                    8. The Borrower shall pay all incidental fees from the loan at the time of
                    disbursement.
                  </div>
                  <div className="">
                    9. The Borrower agrees to repay principal and interest to EFL in{' '}
                    <span className="font-bold">
                      {' '}
                      {contractQuery.data?.loan?.term} (
                      {contractQuery.data?.loan && numToWords(contractQuery.data?.loan?.term)})
                      monthly
                    </span>{' '}
                    installments as stipulated in the Finablr loan repayment schedule sent to the
                    borrower via our official WhatsApp business line. The Finablr loan repayment
                    schedule shall constitute an integral part of this agreement. The first
                    installment of the loan shall be due on the{' '}
                    <span className="font-bold">
                      {contractQuery.data?.loan &&
                        formatDateToISO8601(contractQuery.data?.loan?.payments[1]?.dateExpected)}
                    </span>
                    . In the event that the loan repayment date does not fall on a working day, the
                    said loan repayment installment shall and is hereby agreed to be paid before the
                    end of the next working day.
                  </div>
                  <div className="">
                    10. Where the borrower defaults on any monthly installment as per the schedule
                    for more than <span className="font-bold">7 (seven) days</span>, he/she shall
                    attract a one-off <span className="font-bold">penalty of 11%</span> applied onto
                    the principal installment amount in default for that respective month. This
                    shall be applicable to all installments during the active loan period.
                  </div>
                  <div className="">
                    11. The Borrower has the option to make an{' '}
                    <span className="font-bold">early repayment</span> of the principal or entire
                    loan on giving <span className="font-bold">three days (3) days-notice</span> to
                    EFL, through its official channels.
                  </div>
                  <div className="">
                    12. Where the Borrower is in breach of the loan agreement and/or is in default
                    and the{' '}
                    <span className="font-bold">
                      loan installment remains unpaid for 60 (Sixty) days, EFL will recall the loan
                      and immediately demand repayment of all the outstanding loan balance
                    </span>
                    . EFL will therefore proceed against the Borrower for recovery of the total
                    principal plus interest and all incidental costs.
                  </div>
                  <div className="">
                    13. EFL reserves the right to recall the loan at any time, whether there is
                    default or not, as long as in EFL’s own assessment the Borrower has become a
                    risky Borrower. Where such a loan is recalled, the entire outstanding loan
                    balance shall fall due and owing immediately and the recovery procedure shall be
                    put into effect.
                  </div>
                  <div className="">
                    14. For as long as the loan falls in arrears, the Borrower shall incur charges
                    on the principal in arrears at the rate(s) indicated in paragraphs five (5)
                    above, until the entire sum is fully paid.
                  </div>
                  <div className="">
                    15. All loan repayments shall be made by the Borrower{' '}
                    <span className="font-bold">ONLY</span> on the EFL collection accounts described
                    below and a receipt issued electronically to the Borrower by EFL. All E-payment
                    methods may be made directly to the account described below.
                    <br /> <br />
                    <div class="overflow-x-auto">
                      <table class="table-auto border-collapse border border-gray-400 w-full">
                        <thead>
                          <tr>
                            <th class="px-4 py-2 bg-gray-200 border border-gray-400 text-start font-bold">
                              INSTITUTION:
                            </th>
                            <td class="px-4 py-2 border border-gray-400">
                              Stanbic Bank, Ntinda Branch
                            </td>
                            <td class="px-4 py-2 border border-gray-400"></td>
                            <td class="px-4 py-2 border border-gray-400">Airtel Money</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="px-4 py-2 font-bold text-start">ACCOUNT NAME:</td>
                            <td class="px-4 py-2 border border-gray-400">
                              Enabling Finance Limited
                            </td>
                            <td class="px-4 py-2 border border-gray-400 font-bold">OR</td>
                            <td class="px-4 py-2 border border-gray-400">Yyeyo Luke</td>
                          </tr>
                          <tr>
                            <td class="px-4 py-2 font-bold text-start">ACCOUNT NUMBER:</td>
                            <td class="px-4 py-2 border border-gray-400">9030021053324</td>
                            <td class="px-4 py-2 border border-gray-400"></td>
                            <td class="px-4 py-2 border border-gray-400">0706400057</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="">
                    16. The borrower shall include in his repayment any withdraw fees applicable.
                  </div>
                  <div className="">
                    17. The Borrower has pledged to the Lender collateral security to ensure that
                    the loan is paid through foreclosure in the event of default including any
                    amounts due from penalty, interest, service charges and/or other costs or/and
                    breach of the loan agreement. The Borrower has, by way of security for repayment
                    of the said loan sum, deposited into EFL’s custody collateral described below
                    and the same shall be continuing security until the entire loan and any
                    incidental costs have been fully repaid, or where the Borrower and the Lender
                    have executed a subsequent addendum to swap the same;
                  </div>
                  {contractQuery.data?.loan?.collateral &&
                    contractQuery.data?.loan?.collateral?.map((collateral, index) => (
                      <ContractCollateral collateral={collateral} index={index} />
                    ))}

                  <div className="">
                    18. The borrower(s) guarantee that they are the registered owners of the above
                    described land and developments thereon and/ or otherwise are vested with the
                    legal authority to transact on the said property.
                  </div>
                  <div className="">
                    19. The Borrower has the obligation to deliver to EFL all the original documents
                    of collateral, and the same shall be in custody of EFL or its security trustees
                    until the loan plus all incidental costs have been repaid. It is hereby agreed
                    and understood by all parties that the Lender shall not be liable for collateral
                    documents that may be held and delayed in third party government offices where
                    EFL submitted them for lien registration such as in the event of mortgage
                    registration or release or caveat registration or lifting.
                  </div>
                  <div className="">
                    20. The borrower further consents to the lender disbursing agreed sums of money
                    directly to service providers including Uganda Revenue Authority on their behalf
                    with record being kept.
                  </div>
                  <div className="">
                    21. The lender's interest shall not be affected by any third party interest and
                    the borrower confirms that he will not subject the collateral to any third party
                    interests for the period when the loan is still running.
                  </div>
                  <div className="">
                    22. The borrower confirms that in case he subjects the collateral to any third
                    party interest in anyway without the written consent of the lender, the lender
                    shall terminate the contract with immediate effect.
                  </div>
                  <div className="">
                    23. Upon such termination above, the borrower shall be at liberty to rescue the
                    collateral by paying all the outstanding balance or the lender shall be at
                    liberty to sell the collateral and realise her money without any court order.
                  </div>
                  <div className="">
                    24. The borrower confirms that subjecting the collateral in this agreement to
                    any third party interest, without written consent from the lender, shall mean
                    forfeiting its/his interest in the property.
                  </div>
                  <div className="">
                    25. In case of death, bankruptcy or liquidation of the Borrower, all sums which
                    would at the time have been owing to the Lender by the Borrower shall be
                    recovered from the Borrower’s estate and EFL shall have first preference in
                    settling the debts of the deceased or the bankrupt Borrower. However, the
                    Borrower’s estate may not be resorted to where the outstanding loan amount and
                    interest plus any other incidental costs have been paid by foreclosure of the
                    attached collateral of any insurance company.
                  </div>
                  <div className="">
                    26. The Borrower hereby agrees and authorizes EFL to make inquiries from any
                    bank, financial institution or association or any approved credit reference
                    database to confirm any information provided by the Borrower and provide and
                    share all statutory information in respect of the loan facility and all
                    subsequent facilities as shall be advanced to the Borrower from time to time and
                    in line with applicable Uganda Microfinance regulatory Authority Regulations.
                  </div>
                  <div className="">
                    27. Regarding the agreement and all related matters, the general terms and
                    conditions, which have already been agreed to by the Borrower on the Finablr
                    platform and in this agreement also apply and bind the Borrower, even though not
                    attached to this agreement.
                  </div>
                  <div className="">
                    28. This agreement shall be governed by the laws of the Republic of Uganda.
                  </div>
                  <div className="">
                    29. If any provision of this agreement or application thereof shall, for any
                    reason and to any extent, be invalid or unenforceable, neither the remainder of
                    this agreement nor the application of the provision to other persons, entities
                    or circumstances shall be affected, but instead shall be enforced to the maximum
                    extent permitted by law.
                  </div>
                  <div className="">
                    30. The Borrower agrees to pay fees, charges and costs incidental to the
                    recovery or collection of the outstanding unpaid principal and interest amount
                    and EFL shall be at liberty to debit the Borrower’s account accordingly.
                  </div>
                  <div className="">
                    31. It is hereby agreed by all parties that if any of the monies for the time
                    being owing to the Lender are not forthwith paid on demand or having become
                    payable without demand, the statutory powers of sale of deposited collateral
                    without recourse to court conferred on collateral by the Tier 4 Microfinance
                    Institutions And Moneylenders Act No. 13 Of 2016 including the powers to sell by
                    private treaty without reference to Court shall immediately become exercisable.
                    The procedure to be followed in such circumstance is prescribed in the terms and
                    conditions of the Finablr platform available on{' '}
                    <a
                      href="https://www.enablingfinancelimited.com"
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue-500 underline"
                    >
                      www.enablingfinancelimited.com
                    </a>
                  </div>
                  <div className="">
                    32. No indulgence, waiver, election or non-election by either party under this
                    agreement shall affect the other party’s duties and liabilities hereunder.
                  </div>
                  <div className="">
                    33. The parties hereby agree that this document and its annexures contain the
                    entire agreement between the parties and this agreement shall not be modified,
                    changed, altered or amended in any way except through a written amendment signed
                    by the Borrower and EFL.
                  </div>
                  <div className="">
                    34. The parties shall in good faith make efforts to resolve any dispute,
                    controversy or claim of any nature whatsoever arising out of or relating to or
                    in connection with this contract. To this end, the parties shall each appoint
                    representatives who shall meet and attempt to resolve any dispute arising
                    between them. If any dispute arising from this contract cannot be settled within
                    seven (7) working days, such dispute shall be referred to arbitration by a
                    single arbitrator and finally settled in accordance with the provisions of the
                    Arbitration and Conciliation Act Cap 4 Laws of Uganda.
                  </div>
                  <div className="">
                    35. EFL shall have the responsibility of tracking all events regarding this
                    transaction from point of application to point of full repayment inclusive of
                    producing accountability to both parties for all minor and major transactions by
                    way of source documents such as receipts, payment vouchers, demand notices all
                    on behalf of the concerned parties.
                  </div>
                  <div className="">
                    36. By executing this Agreement, the Borrower hereby acknowledges receipt of the
                    loan amount.
                  </div>

                  <div className="">
                    I, the above-named borrower hereby confirms that I have read, understood and
                    agreed to the above conditions.
                  </div>
                  <div className="">
                    <span className="font-bold">IN WITNESS WHEREOF</span> the parties have hereto
                    set their respective hands and seals hereunto on the day, month and year first
                    above written.
                  </div>
                </div>
                <div className="mb-4">
                  <div className="grid grid-cols-2 gap-4 mb-12 items-center">
                    <div className="">
                      <div className="">
                        <span className="font-bold">SIGNED</span> by
                      </div>
                      <div className="">YYEYO LUKE</div>
                      <div className="">On behalf of LENDER</div>
                      <div className="">ENABLING FINANCE LIMITED</div>
                    </div>
                    <div className="">
                      <div className="">
                        <img
                          src={UPLOADS_URL + '/signatures/yyeyo_luke.png'}
                          className="h-16"
                          alt="Signature"
                        />
                      </div>
                      <div className="">LENDER</div>
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4 mb-12 items-center">
                    <div className="">
                      <div className="" id="borrower_signature">
                        SIGNED by
                      </div>
                      <div className="">
                        {contractQuery.data?.borrower?.basicInformation?.username}
                      </div>
                      <div className=""></div>
                      <div className=""></div>
                    </div>
                    <div className="">
                      <div className="">
                        {contractQuery.data?.borrower_signature?.confirmed_by_borrower && (
                          <img
                            src={
                              UPLOADS_URL +
                              '/signatures/' +
                              contractQuery.data?.borrower_signature?.location
                            }
                            className="h-16"
                            alt="Signature"
                          />
                        )}
                        {!contractQuery.data?.borrower_signature?.confirmed_by_borrower &&
                          user.role === 'BORROWER' && (
                            <SubmitBorrowerSignature
                              location={contractQuery.data?.borrower_signature?.location}
                              id={id}
                            />
                          )}
                      </div>
                      <div className="">THE BORROWER</div>
                    </div>
                  </div>
                  <div className="mb-12 font-semibold" id="lender_signature">
                    In the presence of:
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="">
                      <div className="">SIGNED by</div>
                      <div className="">
                        {contractQuery.data?.lender?.basicInformation?.username}
                      </div>
                      <div className=""></div>
                      <div className=""></div>
                    </div>
                    <div className="">
                      <div className="">
                        {contractQuery.data?.lender_signature?.confirmed_by_lender && (
                          <img
                            src={
                              UPLOADS_URL +
                              '/signatures/' +
                              contractQuery.data?.lender_signature?.location
                            }
                            className="h-16"
                            alt="Signature"
                          />
                        )}
                        {!contractQuery.data?.lender_signature?.confirmed_by_lender &&
                          user.role === 'LENDER' && (
                            <SubmitLenderSignature
                              location={contractQuery.data?.lender_signature?.location}
                              id={id}
                            />
                          )}
                      </div>
                      <div className="">WITNESS</div>
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <div className="border border-gray-500 p-4 mb-4">
                    <div className="font-bold">ENABLING FINANCE LIMITED</div>
                    <div className="">
                      SUBJECT: {contractQuery.data?.product?.product_title} SCHEDULE
                    </div>
                    <div className="">
                      CLIENT: {contractQuery.data?.borrower?.basicInformation?.firstName}
                    </div>
                    <div className="">
                      INTEREST: {contractQuery.data?.loan?.interestRate}% PER MONTH REDUCING BALANCE
                    </div>
                    <div className="">DATE: {contractQuery.data?.contract_date}</div>
                  </div>
                  {contractQuery.data?.loan?.payments && (
                    <>
                      <div className="font-bold mb-3">Repayment Schedule</div>
                      <div className="overflow-x-auto">
                        <table className="table-auto border-collapse border border-gray-400 w-full">
                          <thead>
                            <tr>
                              <th className="px-4 py-2 border border-gray-400">
                                Installment Number
                              </th>
                              <th className="px-4 py-2 border border-gray-400">Date Expected</th>
                              <th className="px-4 py-2 border border-gray-400">
                                Installment Amount
                              </th>
                              <th className="px-4 py-2 border border-gray-400">Principal Due</th>
                              <th className="px-4 py-2 border border-gray-400">Interest Due</th>
                              <th className="px-4 py-2 border border-gray-400">Balance</th>
                            </tr>
                          </thead>
                          <tbody>
                            {contractQuery.data?.loan?.payments.map((payment, index) => (
                              <tr key={payment._id}>
                                <td className="px-4 py-2 border border-gray-400">
                                  {index === 0 ? null : payment.installmentNumber}
                                </td>
                                <td className="px-4 py-2 border border-gray-400">
                                  {index === 0
                                    ? null
                                    : new Date(payment.dateExpected).toLocaleDateString()}
                                </td>
                                <td className="px-4 py-2 border border-gray-400">
                                  {index === 0
                                    ? null
                                    : payment.installmentAmount
                                    ? payment.installmentAmount?.toLocaleString()
                                    : '-'}
                                </td>
                                <td className="px-4 py-2 border border-gray-400">
                                  {payment.principalDue?.toLocaleString()}
                                </td>
                                <td className="px-4 py-2 border border-gray-400">
                                  {payment.interestDue?.toLocaleString()}
                                </td>
                                <td className="px-4 py-2 border border-gray-400">
                                  {payment.balance?.toLocaleString()}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="border-4 border-gray-800 p-4 md:p-12 mb-4">
                <div className="mx-auto flex flex-col items-center space-y-1 mb-4 text-sm md:text-md">
                  <div className="font-bold">THE REPUBLIC OF UGANDA</div>
                  <div className="font-bold">IN THE MATTER OF</div>

                  <div className="font-bold underline">MEMORANDUM OF DEPOSIT</div>

                  <div className="">
                    <span className="font-bold">THIS MEMORANDUM </span> made this day of{' '}
                    <span
                      className={`font-bold ${
                        contractQuery.data?.contract_date === 'Pending' && `text-red-500`
                      }`}
                    >
                      {formatDateToISO8601(contractQuery.data?.contract_date)}
                    </span>
                  </div>
                  <div className="font-bold">BETWEEN</div>
                  <div className="">
                    <span className="">
                      <div className="font-bold">
                        <div>{contractQuery.data?.borrower?.basicInformation?.firstName} of </div>
                        <div className="">
                          {contractQuery.data?.borrower?.addressInformation?.permanentAddress ? (
                            <>
                              {contractQuery.data?.borrower?.addressInformation?.permanentAddress}
                            </>
                          ) : (
                            <>
                              <UpdateAddress />
                            </>
                          )}
                        </div>
                      </div>
                      , (hereinafter referred to as “<span className="font-bold">the Borrower</span>
                      ”, which expression shall where the context so admits include his legal
                      representatives, assigns and/or successors in title, of the first part;
                    </span>
                    <div className="mb-6 font-bold text-center">AND</div>
                    <span className="font-bold">
                      ENABLING FINANCE LTD operating through her ‘Finablr’ app,{' '}
                    </span>{' '}
                    of <span className="font-bold">P.O. Box 12284, Kampala Tel: 0706400057 </span>{' '}
                    (hereinafter referred to as <span className="font-bold">“the Lender”</span>{' '}
                    which expression shall where the context so admits includes its successors in
                    title, legal representatives and assigns) of the one part;
                  </div>
                  <div className="my-8">WITNESSETH AS FOLLOWS:</div>
                  <div className="">
                    WHEREAS at the request and for the benefit of the Borrower, the Lender has
                    agreed to provide a Loan facility of Ug. Shs.{' '}
                    {contractQuery.data?.loanAmount?.toLocaleString()} (
                    {contractQuery.data && convertToWords(contractQuery.data?.loanAmount)} Only) to
                    be paid in {contractQuery.data?.loan?.term} (
                    {contractQuery.data && convertToWords(contractQuery.data?.loan?.term)}) monthly
                    instalments as specified in the attached repayment schedule and secured by the
                    Property specifically described in the Schedule hereto. The Borrower and the
                    Lender have to this end entered into a Loan Agreement dated the{' '}
                    <span className="font-bold">
                      {formatDateToISO8601(contractQuery.data?.contract_date)}
                    </span>{' '}
                    and it is agreed that the said Loan Agreement be deemed integral and construed
                    as one with this Memorandum.
                  </div>
                  <div className="my-8">NOW THEREFORE IT IS HEREBY AGREED AS FOLLOWS:</div>
                  <div className="">
                    In consideration of and as security for payment of the Loan amount with interest
                    as provided in the Loan Agreement, the Borrower deposits with the Lender the
                    collateral below;
                    <br />
                    {contractQuery.data?.loan?.collateral &&
                      contractQuery.data?.loan?.collateral?.map((collateral, index) => (
                        <ContractCollateral collateral={collateral} index={index} />
                      ))}{' '}
                    <br />
                    For the Property specifically described in the Schedule (hereinafter referred as{' '}
                    <span className="font-bold">‘the Security’</span> ).
                  </div>
                  <div className="">
                    In the event that the Borrower defaults in the payment of any installment, the
                    whole amount of the Loan advanced will be come due and payable with or without a
                    formal written demand.
                  </div>
                  <div className="">
                    For the avoidance of any doubt, in the event that the Borrower defaults in the
                    payment of any installments of the Loan amount as and when they fall due or
                    commits any other or further breach of the terms of and under the Loan Agreement
                    the Lender shall have the undisputed right to dispose of the Security in
                    realization thereof.
                  </div>
                  <div className="my-8">
                    IN WITNESS WHEREOF the parties have hereunto set respective hands on the date
                    month and year first above written
                  </div>
                  <div className="my-8">SIGNED and delivered by</div>
                  <div className="mb-4">
                    <div className="grid grid-cols-2 gap-4 mb-12 items-center">
                      <div className="">
                        <div className="">SIGNED by</div>
                        <div className="">
                          {contractQuery.data?.borrower_signature?.confirmed_by_borrower && (
                            <img
                              src={
                                UPLOADS_URL +
                                '/signatures/' +
                                contractQuery.data?.borrower_signature?.location
                              }
                              className="h-16"
                              alt="Signature"
                            />
                          )}
                        </div>
                        <div className="">
                          {' '}
                          {contractQuery.data?.borrower?.basicInformation?.firstName}
                        </div>
                        <div className="">BORROWER</div>
                      </div>
                    </div>

                    <div className="my-4">In the presence of;</div>

                    <div className="grid grid-cols-2 gap-4 mb-12 items-center">
                      <div className="">
                        <div className="">YYEYO LUKE</div>
                        <div className="">On behalf of</div>
                        <div className="">LENDER</div>
                        <div className="">Enabling Finance Limited</div>
                      </div>
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                      <div className="">
                        <div className="">
                          {contractQuery.data?.lender_signature?.confirmed_by_lender && (
                            <img
                              src={
                                UPLOADS_URL +
                                '/signatures/' +
                                contractQuery.data?.lender_signature?.location
                              }
                              className="h-16"
                              alt="Signature"
                            />
                          )}
                        </div>
                        <div className="">
                          {' '}
                          {contractQuery.data?.lender?.basicInformation?.firstName}
                        </div>
                        <div className="">WITNESS</div>
                        <div className=""></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* <DueDiligenceComponentFiles dueDiligence={contractQuery.data?.due_diligence} /> */}
        </div>
      </div>

      {/* Audit Log */}
      <AuditLog comments={contractQuery.data?.loan?.comments} />
    </ContentLayout>
  );
};

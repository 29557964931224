import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';
import { Button } from 'components/Elements';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';
import {
  CheckboxField,
  Form,
  InputField,
  InputFieldWithInfo,
  ReactMultiSelectField,
  ReactNumberFormatInputField2,
  UploadField,
} from 'components/Form';
import { useRef, useState } from 'react';
import { useCreateLenderProfile } from '../api/createLenderProfile';
import Swal from 'sweetalert2';
import SignatureCanvas from 'react-signature-canvas';
import { FaInfoCircle } from 'react-icons/fa';
import { MdWarning } from 'react-icons/md';
import { Stepper } from '../components/Stepper';
import { LenderTermsAndConditions } from '../components/LenderTermsAndConditions';

const nationalIdSchema = z.object({
  nationalIDFront: z.string({
    required_error: 'National ID Front is required',
    invalid_type_error: 'National ID Front must be a string',
  }),
  nationalIDBack: z.string({
    required_error: 'National ID Back is required',
    invalid_type_error: 'National ID Back must be a string',
  }),
});
const lendingPreferencesSchema = z.object({
  pastLendingExperience: z.number({
    required_error: 'Past Lending Experience is required',
    invalid_type_error: 'Past Lending Experience must be a number',
  }),
  sourcesOfFunds: z.string({
    required_error: 'Sources Of Funds is required',
    invalid_type_error: 'Sources Of Funds must be a string',
  }),
  lendingAmountMin: z.number({
    required_error: 'Minimum Lending Amount is required',
    invalid_type_error: 'Minimum Lending Amount must be a number',
  }),
  lendingAmountMax: z.number({
    required_error: 'Maximum Lending Amount is required',
    invalid_type_error: 'Maximum Lending Amount must be a number',
  }),
  lendingDurationMin: z.number({
    required_error: 'Minimum Lending Duration is required',
    invalid_type_error: 'Minimum Lending Duration must be a number',
  }),
  lendingDurationMax: z.number({
    required_error: 'Maximum Lending Duration is required',
    invalid_type_error: 'Maximum Lending Duration must be a number',
  }),
  interestRateMin: z.number({
    required_error: 'Minimum Interest Rate is required',
    invalid_type_error: 'Minimum Interest Rate must be a number',
  }),
  interestRateMax: z.number({
    required_error: 'Maximum Interest Rate is required',
    invalid_type_error: 'Maximum Interest Rate must be a number',
  }),
});
const termsSchema = z.object({
  acceptTerms: z.boolean(),
});
export const CompleteLenderProfile = () => {
  const routes = [
    {
      name: 'Complete Lender Profile',
      path: '/app/lend-money/complete-lender-profile',
    },
  ];
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [trimmedDataURL, setTrimmedDataURL] = useState(null);
  let sigPadRef = useRef();
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [NINFront, setNationalIDFront] = useState();
  const [NINBack, setNationalIDBack] = useState();
  const [lendingPreferences, setlendingPreferences] = useState();
  const [signErr, setSignErr] = useState();
  const [isSigned, setisSigned] = useState(false);
  const [nin, setNIN] = useState();
  const [avatar, setAvatar] = useState();
  const createLenderProfileMutation = useCreateLenderProfile();

  const clear = () => {
    // clear signiture canvas
    sigPadRef.current.clear();
    setisSigned(false);
    setSignErr();
    setTrimmedDataURL();
  };
  const remove = () => {
    // clear signiture canvas
    setisSigned(false);
    setSignErr();
    setTrimmedDataURL();
  };

  const trim = () => {
    try {
      if (isSigned) {
        const trimmedData = sigPadRef.current.getTrimmedCanvas().toDataURL('image/png');
        if (trimmedData) {
          // console.log('trimmedData', trimmedData)
          setSignErr();
          setTrimmedDataURL(trimmedData);
        } else {
          console.log('no trimmedData');
          setSignErr('Something went wrong, try again');
        }
      } else {
        setSignErr('Please sign to continue');
      }
    } catch (error) {
      console.log('sign error', error);
      setSignErr('Something went wrong, try again');
    }
  };

  const handleNext = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const handleStepClick = (index) => {
    setCurrentStep(index);
  };

  const handleFileChange = (e, setFile) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setFile(file);
    } else {
      alert('Please upload a valid image file.');
    }
  };

  return (
    <ContentLayout title="">
      <Breadcrumbs routes={routes} />
      <div className="p-4">
        <Stepper
          steps={[
            {
              title: 'Introduction',
              content: (
                <div className="p-6">
                  <h1 className="text-2xl font-semibold text-gray-800">Create Lender's Profile</h1>
                  <p className="mt-4 text-gray-600">
                    Creating your lender's profile is quick and easy! This profile will help
                    borrowers and other stakeholders understand your lending capabilities and
                    preferences.
                  </p>
                  <div className="mt-6">
                    <h2 className="text-lg font-medium text-gray-700">What you'll need:</h2>
                    <ul className="list-disc list-inside mt-3 space-y-2">
                      <li className="text-gray-600">
                        An identification document (e.g., National ID). You'll need to submit images
                        of the front and back.
                      </li>
                      <li className="text-gray-600">
                        Information about your lending criteria (e.g., past lending experience, loan
                        amounts, interest rates).
                      </li>
                      <li className="text-gray-600">
                        Your signature to confirm validity. You'll be required to sign online or
                        upload your signature.
                      </li>
                    </ul>
                  </div>
                  <div className="mt-8">
                    <h2 className="text-lg font-medium text-gray-700">
                      Your Privacy is Our Priority
                    </h2>
                    <div className="mt-3 p-4 bg-blue-50 border-l-4 border-blue-600 rounded-md">
                      <p className="text-gray-600">
                        We take your security seriously. Your personal data is encrypted and
                        securely stored, and we only share it with authorized parties when
                        necessary.
                      </p>
                      <p className="mt-2 text-gray-600">
                        For more details, please review our
                        <a
                          href="/general-policy"
                          className="text-secondary-100 hover:underline ml-1"
                        >
                          General Policy
                        </a>
                        .
                      </p>
                    </div>
                  </div>
                  <div className="mt-8 flex justify-between items-center">
                    <p className="text-sm text-gray-500">Step 1 of 6</p>
                    <button
                      type="button"
                      onClick={() => handleNext()}
                      className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:ring focus:ring-blue-300"
                    >
                      Start Profile Setup
                    </button>
                  </div>
                </div>
              ),
            },
            {
              title: 'Identification',
              content: (
                <Form
                  id="create-post"
                  onSubmit={async (values) => {
                    // console.log('national ids', values)
                    handleNext();
                  }}
                  schema={nationalIdSchema}
                >
                  {({ register, formState, watch, setValue }) => (
                    <>
                      <h2 className="text-lg md:text-2xl font-bold mb-6 text-center">
                        Identification
                      </h2>
                      <div className="text-base text-gray-700">
                        Please provide your identification details to verify your profile and ensure
                        security. This step helps us comply with legal requirements and protect your
                        account.
                      </div>

                      <div className="">
                        <div className="mb-4">
                          <UploadField
                            title=" Provide a Selfie Photo"
                            // error={formState.errors['avatar'].message}
                            caption="A selfie helps improve your credit rating and let's us identify you in our system."
                            onChange={(e) => {
                              setValue('avatar', 'file set');
                              const file = e.target.files[0];
                              if (file && file.type.startsWith('image/')) {
                                setAvatar(file);
                              }
                            }}
                          />
                        </div>
                        <div className="">
                          <InputField
                            label="Provide your Identification Number e.g National ID or Passport"
                            caption="We won't use or share your Identification Number for any actions without your concent."
                            error={formState.errors['nin']}
                            //   registration={register('work')}
                            onChange={(e) => {
                              console.log(e.target.value);
                              setNIN(e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="flex flex-col items-center">
                          <div className="mb-3 w-full">
                            <UploadField
                              title="Upload National ID / Passport (Front Face)"
                              // error={formState.errors['avatar'].message}
                              // caption="."

                              onChange={(e) => {
                                setValue('nationalIDFront', 'file set');
                                formState.errors['nationalIDFront'] = '';
                                setValue('nationalIDFront', JSON.stringify(e.target.files[0]));
                                handleFileChange(e, setNationalIDFront);
                              }}
                            />
                            {formState.errors['nationalIDFront']?.message && (
                              <div
                                role="alert"
                                aria-label={formState.errors['nationalIDFront'].message}
                                className="text-sm font-semibold text-red-500"
                              >
                                {formState.errors['nationalIDFront'].message}
                              </div>
                            )}
                          </div>
                          {/* {NINFront && (
                              <div className="mt-3">
                                <img
                                  src={URL.createObjectURL(NINFront)}
                                  alt="National ID Front"
                                  className="h-40 w-auto rounded-md shadow-sm"
                                />
                              </div>
                            )} */}
                        </div>
                        <div className="flex flex-col items-center">
                          <div className="mb-3 w-full">
                            <UploadField
                              title="Upload National ID / Passport (Back Face)"
                              // error={formState.errors['avatar'].message}
                              // caption="."
                              onChange={(e) => {
                                setValue('nationalIDBack', 'file set');
                                formState.errors['nationalIDBack'] = '';
                                setValue('nationalIDBack', JSON.stringify(e.target.files[0]));
                                handleFileChange(e, setNationalIDBack);
                              }}
                            />
                            {formState.errors['nationalIDBack']?.message && (
                              <div
                                role="alert"
                                aria-label={formState.errors['nationalIDBack'].message}
                                className="text-sm font-semibold text-red-500"
                              >
                                {formState.errors['nationalIDBack'].message}
                              </div>
                            )}
                          </div>
                          {/* {NINBack && (
                              <div className="mt-3">
                                <img
                                  src={URL.createObjectURL(NINBack)}
                                  alt="National ID Back"
                                  className="h-40 w-auto rounded-md shadow-sm"
                                />
                              </div>
                            )} */}
                        </div>
                      </div>
                      <div className="flex flex-row items-center space-x-2">
                        <Button size="sm" type="submit" variant="outline">
                          Next
                        </Button>
                      </div>
                    </>
                  )}
                </Form>
              ),
            },
            {
              title: 'Lending Preferences',
              content: (
                <Form
                  id="create-post"
                  onSubmit={async (values) => {
                    // console.log('handleNext()', values)
                    setlendingPreferences(values);
                    handleNext();
                  }}
                  schema={lendingPreferencesSchema}
                >
                  {({ register, formState, watch, setValue }) => (
                    <>
                      <h2 className="text-lg md:text-2xl font-bold mb-6 text-center">
                        Lending Preferences
                      </h2>
                      <div className="mx-0 md:mx-12 space-y-4 md:space-y-12">
                        <InputFieldWithInfo
                          type="number"
                          label="Past Lending Experience (In Years)"
                          registration={register('pastLendingExperience')}
                          error={formState.errors['pastLendingExperience']}
                          onChange={(e) => {
                            formState.errors['pastLendingExperience'] = '';
                            setValue('pastLendingExperience', parseInt(e.target.value));
                          }}
                        />
                        <ReactMultiSelectField
                          label="Sources of Funds"
                          registration={register('sourcesOfFunds')}
                          error={formState.errors['sourcesOfFunds']}
                          setValue={setValue}
                          editorContent={watch('sourcesOfFunds')}
                          isMulti={true}
                          name="sourcesOfFunds"
                          defaultValue={watch('sourcesOfFunds')}
                          options={[
                            { label: 'Salary', value: 'salary' },
                            { label: 'Business', value: 'business' },
                            { label: 'Investment', value: 'investment' },
                            { label: 'Others', value: 'others' },
                          ]}
                        />
                        <div className="">
                          <div>How much are you comfortable lending?</div>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <ReactNumberFormatInputField2
                              prefix="UGX "
                              name="amount"
                              label="Min Amount"
                              error={formState.errors['lendingAmountMin']}
                              registration={register('lendingAmountMin')}
                              onChange={(event) => {
                                //console.log('lendingAmountMin', event);
                                formState.errors['lendingAmountMin'] = '';
                                let amt = parseInt(event.value);
                                setValue('lendingAmountMin', amt);
                              }}
                              className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            />
                            <ReactNumberFormatInputField2
                              prefix="UGX "
                              name="amount"
                              label="Max Amount"
                              error={formState.errors['lendingAmountMax']}
                              registration={register('lendingAmountMax')}
                              onChange={(event) => {
                                //console.log('lendingAmountMax', event);
                                formState.errors['lendingAmountMax'] = '';
                                let amt = parseInt(event.value);
                                setValue('lendingAmountMax', amt);
                              }}
                              className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <div>
                          <div>For how long would you be comfortable lending?</div>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <InputFieldWithInfo
                              type="number"
                              label="Min (Months)"
                              registration={register('lendingDurationMin')}
                              error={formState.errors['lendingDurationMin']}
                              onChange={(e) => {
                                formState.errors['lendingDurationMin'] = '';
                                setValue('lendingDurationMin', parseInt(e.target.value));
                              }}
                            />
                            <InputFieldWithInfo
                              type="number"
                              label="Max (Months)"
                              registration={register('lendingDurationMax')}
                              error={formState.errors['lendingDurationMax']}
                              onChange={(e) => {
                                formState.errors['lendingDurationMax'] = '';
                                setValue('lendingDurationMax', parseInt(e.target.value));
                              }}
                            />
                          </div>
                        </div>
                        <div>
                          <div>What interest rate would you be comfortable lending at?</div>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <InputFieldWithInfo
                              type="number"
                              label="Min (%)"
                              registration={register('interestRateMin')}
                              error={formState.errors['interestRateMin']}
                              onChange={(e) => {
                                formState.errors['interestRateMin'] = '';
                                setValue('interestRateMin', parseInt(e.target.value));
                              }}
                            />
                            <InputFieldWithInfo
                              type="number"
                              label="Max (%)"
                              registration={register('interestRateMax')}
                              error={formState.errors['interestRateMax']}
                              onChange={(e) => {
                                formState.errors['interestRateMax'] = '';
                                setValue('interestRateMax', parseInt(e.target.value));
                              }}
                            />
                          </div>
                        </div>
                        <div className="flex flex-row justify-between">
                          <Button
                            disabled={currentStep === 0}
                            onClick={handleBack}
                            variant="secondary"
                            className="w-1/3"
                          >
                            Back
                          </Button>
                          <Button type="submit" className="w-1/3">
                            Next
                          </Button>
                        </div>
                      </div>
                    </>
                  )}
                </Form>
              ),
            },
            {
              title: 'Terms and Conditions',
              content: (
                <Form
                  id="create-post"
                  onSubmit={async (values) => {
                    handleNext();
                  }}
                  schema={termsSchema}
                >
                  {({ register, formState, watch, setValue }) => (
                    <>
                      <h2 className="text-lg md:text-2xl font-bold mb-3 text-center">
                        Lender Terms and Conditions
                      </h2>
                      <div
                        className="rounded-xl border border-gray-200 mb-4"
                        style={{ height: '300px', overflow: 'hidden', overflowY: 'scroll' }}
                      >
                        <LenderTermsAndConditions />
                        <div className="m-2">
                          <CheckboxField
                            label="Accept Terms and Conditions"
                            registration={register('acceptTerms')}
                            error={formState.errors['acceptTerms']}
                            values={acceptTerms}
                            name="acceptTerms"
                            onChange={(e) => {
                              setAcceptTerms(!acceptTerms);
                              setValue('acceptTerms', !acceptTerms);
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex flex-row justify-between">
                        <Button
                          disabled={currentStep === 0}
                          onClick={handleBack}
                          variant="secondary"
                          className="w-1/3"
                        >
                          Back
                        </Button>
                        <Button
                          type="submit"
                          disabled={currentStep === 3 && !acceptTerms}
                          className="w-1/3"
                        >
                          Next
                        </Button>
                      </div>
                    </>
                  )}
                </Form>
              ),
            },
            {
              title: 'Digital Signature',
              content: (
                <Form
                  id="create-post"
                  onSubmit={async (values) => {
                    if (!trimmedDataURL) {
                      setSignErr('Signature is required');
                      return;
                    }
                    const bodyFormData = new FormData();
                    bodyFormData.append('nationalIDFront', NINFront);
                    bodyFormData.append('nationalIDBack', NINBack);
                    if (lendingPreferences) {
                      bodyFormData.append(
                        'pastLendingExperience',
                        lendingPreferences.pastLendingExperience
                      );
                      bodyFormData.append('sourcesOfFunds', lendingPreferences.sourcesOfFunds);
                      bodyFormData.append(
                        'collateralCategory',
                        lendingPreferences.collateralCategory
                      );
                      bodyFormData.append('lendingAmountMin', lendingPreferences.lendingAmountMin);
                      bodyFormData.append('lendingAmountMax', lendingPreferences.lendingAmountMax);
                      bodyFormData.append(
                        'lendingDurationMin',
                        lendingPreferences.lendingDurationMin
                      );
                      bodyFormData.append(
                        'lendingDurationMax',
                        lendingPreferences.lendingDurationMax
                      );
                      bodyFormData.append('interestRateMin', lendingPreferences.interestRateMin);
                      bodyFormData.append('interestRateMax', lendingPreferences.interestRateMax);
                      bodyFormData.append('signature', trimmedDataURL);
                      bodyFormData.append('acceptLenderTermsAndConditions', acceptTerms);
                      bodyFormData.append('avatar', avatar);
                      bodyFormData.append('nin', nin);
                      bodyFormData.append('acceptLenderTermsAndConditions', acceptTerms);
                      bodyFormData.append('signatureConfirmed', true);
                    } else {
                      setSignErr('Lending Preferences in step 2 are required');
                      return;
                    }

                    await createLenderProfileMutation
                      .mutateAsync({ data: bodyFormData })
                      .then((data) => {
                        Swal.fire({
                          title: 'Success!',
                          text: 'Thank you for your submission. You will be notified once your lender profile has been activated.',
                          icon: 'success',
                          confirmButtonText: 'Ok',
                        }).then(() => {
                          navigate('/app');
                        });
                      })
                      .catch((err) => {
                        // console.log('login error', err);
                        setSignErr(`${err.message}. Try again.`);
                      });
                  }}
                >
                  {({ register, formState, watch, setValue }) => (
                    <>
                      <h2 className="text-lg md:text-2xl font-bold mb-6 text-center">
                        Digital Signature
                      </h2>
                      <div className="mb-4 text-start md:text-center">
                        Before you complete your lender profile, you're required to submit your
                        digital signature. Please sign in the box below.
                      </div>
                      <div className=" rounded-2xl bg-white mb-4 p-4">
                        <div>
                          {trimmedDataURL ? (
                            <>
                              <img
                                alt="signature"
                                src={trimmedDataURL}
                                className="mx-auto mb-3 h-[15vw]"
                              />
                              <Button
                                size="sm"
                                type="button"
                                className="mx-auto border-0 shadow-none"
                                variant="outline"
                                onClick={remove}
                              >
                                Clear signature and sign again
                              </Button>
                            </>
                          ) : (
                            <div className="flex flex-row items-center justify-center">
                              <SignatureCanvas
                                penColor="darkblue"
                                onEnd={() => {
                                  setisSigned(true);
                                  // trim()
                                }}
                                clearOnResize={false}
                                canvasProps={{
                                  // width: 200,
                                  // height: 200,
                                  className:
                                    'sigCanvas w-[30vw] h-[15vw] border border-gray-200 rounded-2xl shadow bg-white mb-4',
                                }}
                                ref={sigPadRef}
                              />
                            </div>
                          )}
                          {signErr && (
                            <div
                              role="alert"
                              aria-label={signErr}
                              className="text-sm font-semibold text-red-500"
                            >
                              {signErr}
                            </div>
                          )}
                        </div>
                        {!trimmedDataURL ? (
                          <div className="flex flex-row justify-center items-center space-x-2">
                            <Button size="sm" type="button" variant="outline" onClick={clear}>
                              Clear
                            </Button>
                            <Button type="button" size="sm" onClick={trim}>
                              Confirm Signature
                            </Button>
                          </div>
                        ) : (
                          <div className="mt-8 flex flex-col items-center justify-center">
                            <div className="mb-4 text-md bg-red-50 text-red-700 p-4 rounded-xl shadow-md">
                              By continuing, you confirm that the signature you provided is genuine
                              and will be used to finalize your agreements securely.
                            </div>

                            <div className="mt-4 flex items-center space-x-2 text-sm text-gray-600">
                              <MdWarning className="text-red-500" />
                              <span>
                                Protected under the Uganda Electronic Signatures Act, 2011.
                              </span>
                            </div>

                            <Button
                              isLoading={createLenderProfileMutation.isLoading}
                              size="lg"
                              type="submit"
                              variant="dark"
                              className="mt-6"
                            >
                              Confirm and Continue
                            </Button>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </Form>
              ),
            },
          ]}
          currentStep={currentStep}
          onStepClick={handleStepClick}
        />
      </div>
    </ContentLayout>
  );
};

import { Button, Spinner } from 'components/Elements';
import { Form, InputFieldWithInfo, RegularTextAreaField } from 'components/Form';
import { ReactSelectField2 } from 'components/Pigeon';
import { defaults } from 'features/assets/data/defaults';
import { televisionSchema } from 'features/assets/schemas';
import { useProducts } from 'features/products';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import storage from 'utils/storage';
import { COLLATERAL } from 'variables';

export const TelevisionAssetDetailsForm = ({ onSuccess }) => {
  const [televisionAssetData, setTelevisionAssetData] = useState({
    title: '',
    product: '',
    selectedProduct: '',
    brand: '',
    selectedBrand: '',
    specify_others: '',
    dimension: '',
    selecteddimension: '',
    smart_tv: '',
    selectedsmart_tv: '',
    description: '',
  });

  const productsQuery = useProducts();

  useEffect(() => {
    const getData = async () => {
      const assetCategory = localStorage.getItem('assetCategory');
      if (assetCategory) {
        if (assetCategory === COLLATERAL.CATEGORIES.TELEVISION) {
          const result = storage.getOtherValues('televisionAssetData');
          setTelevisionAssetData(result);
        }
      }
    };
    getData();
  }, []);

  if (productsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="md" />
      </div>
    );
  }

  if (!productsQuery?.data) return null;

  return (
    <Form
      id="create-post"
      onSubmit={async (values) => {
        localStorage.setItem('formStep', 'pictures');
        onSuccess();
      }}
      defaultValues={{
        title: televisionAssetData.title,
        product: televisionAssetData.product,
        brand: televisionAssetData.brand,
        specify_others: televisionAssetData.specify_others,
        dimension: televisionAssetData.dimension,
        smart_tv: televisionAssetData.smart_tv,
        description: televisionAssetData.description,
      }}
      schema={televisionSchema}
    >
      {({ register, formState, watch, setValue }) => (
        <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
          <div className="mb-4">Asset Details for Television</div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="space-y-5">
              <InputFieldWithInfo
                label="Title of Asset"
                error={formState.errors['title']}
                registration={register('title')}
                caption="Enter a concise title for your asset. This will help us identify it easily and efficiently."
                onChange={(e) => {
                  formState.errors['title'] = '';
                  setValue('title', e.target.value);
                  // console.log('title data', { ...vehicleAssetData, title: e.target.value })
                  const dt = { ...televisionAssetData, title: e.target.value };
                  setTelevisionAssetData(dt);
                  storage.setAnyFormValues('televisionAssetData', dt);
                }}
              />
              <ReactSelectField2
                label="Please select Product"
                type="text"
                editorContent={watch('product')}
                name="product"
                defaultValue={televisionAssetData.selectedProduct}
                error={formState.errors['product']}
                registration={register('product')}
                options={productsQuery.data?.map((p) => ({
                  label: p.product_title,
                  value: p._id,
                }))}
                onChange={async (e) => {
                  const selectedProduct = await productsQuery.data
                    ?.map((p) => ({
                      label: p.product_title,
                      value: p._id,
                    }))
                    ?.find((p) => p.value === e.value);
                  setValue('product', e.value);
                  formState.errors['product'] = '';
                  setTelevisionAssetData({
                    ...televisionAssetData,
                    product: e.value,
                    selectedProduct: selectedProduct,
                  });
                  storage.setAnyFormValues('televisionAssetData', {
                    product: e.value,
                    selectedProduct: selectedProduct,
                  });
                }}
              />
              <ReactSelectField2
                label="Select Television Brand"
                type="text"
                editorContent={watch('brand')}
                name="brand"
                defaultValue={televisionAssetData.selectedBrand}
                error={formState.errors['brand']}
                registration={register('brand')}
                options={defaults.tvBrand}
                onChange={async (e) => {
                  const selectedProduct = defaults.tvBrand.find((p) => p.value === e.value);
                  setValue('brand', e.value);
                  formState.errors['brand'] = '';
                  setTelevisionAssetData({
                    ...televisionAssetData,
                    brand: e.value,
                    selectedBrand: selectedProduct,
                  });
                  storage.setAnyFormValues('televisionAssetData', {
                    brand: e.value,
                    selectedBrand: selectedProduct,
                  });
                }}
              />
              {televisionAssetData.brand === 'OTHER' && (
                <InputFieldWithInfo
                  label="Specify Other Brand"
                  error={formState.errors['specify_others']}
                  registration={register('specify_others')}
                  caption="Specify a brand in case you can't find yours in the list"
                  onChange={(e) => {
                    formState.errors['specify_others'] = '';
                    setValue('specify_others', e.target.value);
                    // console.log('specify_others data', { ...vehicleAssetData, specify_others: e.target.value })
                    const dt = {
                      ...televisionAssetData,
                      specify_others: e.target.value,
                    };
                    setTelevisionAssetData(dt);
                    storage.setAnyFormValues('televisionAssetData', dt);
                  }}
                />
              )}
              <ReactSelectField2
                label="Dimensions"
                type="text"
                editorContent={watch('dimension')}
                name="dimension"
                defaultValue={televisionAssetData.selecteddimension}
                error={formState.errors['dimension']}
                registration={register('dimension')}
                options={defaults.tvDimensions}
                onChange={async (e) => {
                  const selectedProduct = defaults.tvDimensions.find((p) => p.value === e.value);
                  setValue('dimension', e.value);
                  formState.errors['dimension'] = '';
                  setTelevisionAssetData({
                    ...televisionAssetData,
                    dimension: e.value,
                    selecteddimension: selectedProduct,
                  });
                  storage.setAnyFormValues('televisionAssetData', {
                    dimension: e.value,
                    selecteddimension: selectedProduct,
                  });
                }}
              />
              <ReactSelectField2
                label="Smart TV?"
                type="text"
                editorContent={watch('smart_tv')}
                name="smart_tv"
                defaultValue={televisionAssetData.selectedsmart_tv}
                error={formState.errors['smart_tv']}
                registration={register('smart_tv')}
                options={defaults.isSmartTv}
                onChange={async (e) => {
                  const selectedProduct = defaults.isSmartTv.find((p) => p.value === e.value);
                  setValue('smart_tv', e.value);
                  formState.errors['smart_tv'] = '';
                  setTelevisionAssetData({
                    ...televisionAssetData,
                    smart_tv: e.value,
                    selectedsmart_tv: selectedProduct,
                  });
                  storage.setAnyFormValues('televisionAssetData', {
                    smart_tv: e.value,
                    selectedsmart_tv: selectedProduct,
                  });
                }}
              />
            </div>
            <div className="space-y-5">
              <RegularTextAreaField
                label="Asset Description"
                error={formState.errors['description']}
                registration={register('description')}
                caption="Any extra information you’d like us to know about your television. This will
                                guide us on serving you better."
                value={televisionAssetData.description}
                onChange={(e) => {
                  formState.errors['description'] = '';
                  setValue('description', e.target.value);
                  setTelevisionAssetData({
                    ...televisionAssetData,
                    description: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex flex-row space-x-2 justify-end mt-4">
            <Button type="submit">Next</Button>
          </div>
        </div>
      )}
    </Form>
  );
};

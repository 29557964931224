import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';

import { Button, Spinner } from 'components/Elements';
import { Link, useNavigate } from 'react-router-dom';
import { useLoans } from '../api/getLoans';
import { useEffect } from 'react';
import { useAuth } from 'lib/auth';
import { AddToWishlist } from 'features/wishlist';
import storage from 'utils/storage';

export const LendMoney = () => {
  const routes = [
    {
      name: 'Lend Money',
      path: '/app/lend-money',
    },
  ];
  const navigate = useNavigate();

  const { user } = useAuth();

  const loansQuery = useLoans();

  useEffect(() => {
    storage.clearRedirect();
  }, [user.role, navigate]);

  if (loansQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!loansQuery.data) return null;

  return (
    <ContentLayout
      title="Lend Money"
      end={
        <Button variant="secondary" onClick={() => navigate('/app')}>
          Back Home
        </Button>
      }
    >
      <Breadcrumbs routes={routes} />

      <div className="mt-4">
        <div className="md:mx-8 flex flex-row items-center space-x-2 mb-4">
          <div className="flex flex-row items-center space-x-2">
            <div className="bg-white border border-gray-900 rounded-full h-4 w-4"></div>
            <span>Pending Qualification</span>
          </div>
          <div className="flex flex-row items-center space-x-2">
            <div className="bg-secondary-100 border border-gray-900 rounded-full h-4 w-4"></div>
            <span>Qualified</span>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          {loansQuery.data.map((loan) => (
            <LoanItem
              key={loan?._id}
              name={loan?.client?.authentication?.username}
              duration={loan?.term}
              offers={loan?.offers?.length}
              isNew={loan?.status === 'EFL_APPROVED' && true}
              amount={loan?.amount?.loanRequired?.toLocaleString()}
              interestRate={loan?.interestRate}
              monthlyPayment={loan?.monthlyPayment?.toLocaleString()}
              _id={loan?._id}
            />
          ))}
        </div>
        {loansQuery.data.length <= 0 && (
          <div className="bg-white p-8 rounded-lg shadow-md text-gray-400 text-center text-sm">
            There are no loans to display at the moment. Check back later.
          </div>
        )}
      </div>
    </ContentLayout>
  );
};

const LoanItem = ({ _id, name, duration, amount, offers, interestRate, monthlyPayment, isNew }) => {
  const { user } = useAuth();
  return (
    <Link to={`/app/lend-money/${_id}`} className="rounded-2xl shadow">
      <div
        className={`${
          isNew ? 'bg-white' : 'bg-secondary-100'
        } rounded-t-2xl p-3 flex flex-row justify-between`}
      >
        <div className="">
          <div className={`font-bold ${user.role !== 'LENDER' ? 'blur-[5px]' : ''}`}>{name}</div>
          <div className="">Payable in {duration} months</div>
          <div className="">Offers: {offers ? offers : 'No offers yet'}</div>
        </div>
        <div className="">
          <AddToWishlist loanId={_id} lendMoney={true} />
        </div>
      </div>
      <div className="p-3 flex flex-row items-center justify-between">
        <div className="">UGX {amount.toLocaleString()}</div>
        <div className="">
          <div className="">{interestRate}% per month</div>
          <div className="text-xs">UGX {monthlyPayment.toLocaleString()} monthly</div>
        </div>
      </div>
    </Link>
  );
};

import { formatRelativeTime } from 'utils/formatRelativeTime';

export const AuditLog = ({ comments }) => {
  return (
    <>
      <div className="bg-white rounded-2xl shadow mt-8">
        <div className="p-2 mb-1">
          <div className="font-bold">Loan + Contract Auditlog</div>
          <div className="text-xs mb-1">
            All actions performed on the loan & contract are logged here.
          </div>
          <hr />
        </div>
        {comments.map((comment, index) => (
          <>
            <div className="p-2 text-sm">
              <div className="">
                <span className="">{comment.comment}</span>
                <span className="ml-1 text-gray-100 text-xs bg-gray-500 px-3 py-1">
                  {' '}
                  by {comment.username}
                </span>
                <span className=""> {formatRelativeTime(comment.date)}</span>
              </div>
            </div>
            <hr />
          </>
        ))}
      </div>
    </>
  );
};

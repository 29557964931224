import React, { useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { MapPin } from 'react-feather';

// Default location icon for Leaflet marker
const getCustomIcon = (iconUrl) =>
  new L.Icon({
    iconUrl:
      iconUrl || 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
    shadowSize: [41, 41],
  });

const center = {
  lat: 0.39394,
  lng: 32.63648,
};

export const LocationField = ({ label, onChange, error, caption }) => {
  const [selectedPlace, setSelectedPlace] = useState(center);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [customIconUrl, setCustomIconUrl] = useState('');
  const [customIcon, setCustomIcon] = useState(getCustomIcon());

  const MapClickHandler = () => {
    useMapEvents({
      click(e) {
        const { lat, lng } = e.latlng;
        const newPlace = { lat, lng };
        setSelectedPlace(newPlace);
      },
    });
    return null;
  };

  const handleMarkerDragEnd = (event) => {
    const newLat = event.target.getLatLng().lat;
    const newLng = event.target.getLatLng().lng;
    const newPlace = { lat: newLat, lng: newLng };
    setSelectedPlace(newPlace);
  };

  const handleUseLocation = () => {
    onChange(selectedPlace);
    setIsDialogOpen(false);
  };

  const handleCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const currentLocation = { lat: latitude, lng: longitude };
          setSelectedPlace(currentLocation);
        },
        (error) => {
          alert('Unable to fetch current location. Please try again.');
        }
      );
    } else {
      alert('Geolocation is not supported by your browser.');
    }
  };

  return (
    <div>
      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">{label}</label>
      {error && <p className="text-red-500 text-sm">{error}</p>}
      {caption && <p className="text-gray-500 text-sm">{caption}</p>}

      <button
        type="button"
        className="mt-2 px-4 py-3 w-full flex flex-row items-center space-x-2 text-gray-900 rounded-lg shadow-sm border border-gray-300"
        onClick={() => setIsDialogOpen(true)}
      >
        <MapPin size={20} />
        <em className="text-gray-400">Select your land location from map</em>
      </button>

      {/* {selectedPlace && (
        <div className="mt-4 text-gray-700 dark:text-gray-300">
          <p>Selected Location:</p>
          <p>Latitude: {selectedPlace.lat}</p>
          <p>Longitude: {selectedPlace.lng}</p>
        </div>
      )} */}

      <Transition appear show={isDialogOpen} as={Fragment}>
        <Dialog
          as="div"
          className={`relative z-10 ${isFullScreen ? 'w-screen h-screen fixed' : ''}`}
          onClose={() => setIsDialogOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div
              className={`flex ${
                isFullScreen ? '' : 'min-h-full'
              } items-center justify-center p-4 text-center`}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={`${
                    isFullScreen ? 'w-full h-full' : 'w-full max-w-4xl'
                  } transform overflow-hidden rounded-2xl bg-white p-6 shadow-xl transition-all dark:bg-gray-800`}
                >
                  <div className="flex justify-between items-center">
                    <Dialog.Title className="text-lg font-medium text-gray-900 dark:text-gray-200">
                      Select Your Location
                    </Dialog.Title>
                    <button
                      onClick={() => setIsFullScreen((prev) => !prev)}
                      className="text-sm px-3 py-1 bg-gray-300 rounded hover:bg-gray-400 dark:bg-gray-700 dark:hover:bg-gray-600"
                    >
                      {isFullScreen ? 'Exit Fullscreen' : 'Fullscreen'}
                    </button>
                  </div>

                  <div className="mt-4 w-full h-[400px] rounded-lg overflow-hidden">
                    <MapContainer
                      center={selectedPlace}
                      zoom={15}
                      style={{ width: '100%', height: '100%' }}
                      scrollWheelZoom={true}
                    >
                      <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                      />
                      <Marker
                        position={selectedPlace}
                        draggable={true}
                        eventHandlers={{
                          dragend: handleMarkerDragEnd,
                        }}
                        icon={customIcon}
                      />
                      <MapClickHandler />
                    </MapContainer>
                  </div>

                  <div className="mt-4 flex flex-col space-y-4">
                    <button
                      onClick={handleCurrentLocation}
                      className="px-4 py-2 bg-gray-100 text-gray-800 rounded-lg hover:bg-gray-200"
                    >
                      Use My Current Location
                    </button>
                  </div>

                  <div className="mt-4 flex justify-between">
                    <button
                      className="px-4 py-2 bg-gray-300 rounded-lg hover:bg-gray-400"
                      onClick={() => setIsDialogOpen(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                      onClick={handleUseLocation}
                    >
                      Use Location
                    </button>
                  </div>

                  <p className="mt-4 text-sm text-gray-500 dark:text-gray-400">
                    By selecting a location, you agree to our{' '}
                    <a href="#" className="text-blue-600 hover:underline">
                      Terms of Service
                    </a>{' '}
                    and{' '}
                    <a href="#" className="text-blue-600 hover:underline">
                      Privacy Policy
                    </a>
                    .
                  </p>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

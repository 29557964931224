import { Form, InputFieldWithInfo, LocationField, RegularTextAreaField } from 'components/Form';
import React, { useState } from 'react';
import { landSchema } from '../../schemas';
import storage from 'utils/storage';
import { ReactSelectField2 } from 'components/Pigeon';
import { Button, Spinner } from 'components/Elements';
import { useProducts } from 'features/products';
import { reverseGeocode } from 'utils/reverseGeocode';
import { defaults } from '../../data/defaults';
import { COLLATERAL } from 'variables';
import { useEffect } from 'react';

export const RealEstateAssetDetailsForm = ({ onSuccess }) => {
  const [realEstateAssetData, setRealEstateAssetData] = useState({
    title: '',
    product: '',
    selectedProduct: '',
    land_location: '',
    coordinates: '',
    type_of_title: '',
    selectedtype_of_title: '',
    size_of_land: '',
    selectedsize_of_land: '',
    plot_no: '',
    block_no: '',
    whats_on_land: '',
    selectedswhats_on_land: '',
    specify_others: '',
    description: '',
  });

  const productsQuery = useProducts();

  useEffect(() => {
    const getData = async () => {
      const assetCategory = localStorage.getItem('assetCategory');
      if (assetCategory) {
        if (assetCategory === COLLATERAL.CATEGORIES.REAL_ESTATE_WITH_LAND_TITLE) {
          const result = storage.getOtherValues('realEstateAssetData');
          setRealEstateAssetData(result);
        }
      }
    };
    getData();
  }, []);

  if (productsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="md" />
      </div>
    );
  }

  if (!productsQuery?.data) return null;

  const handleLocationChange = async (newLocation) => {
    console.log(newLocation);
    const addressLocation = await reverseGeocode(newLocation);
    console.log(addressLocation);
    setRealEstateAssetData({
      coordinates: newLocation,
      land_location: addressLocation,
      ...realEstateAssetData,
    });
  };

  return (
    <Form
      id="real-estate-with-land-title"
      onSubmit={async (values) => {
        console.log(values);
        values.land_location = realEstateAssetData.land_location;
        values.coordinates = realEstateAssetData.coordinates;
        localStorage.setItem('formStep', 'pictures');

        onSuccess();
      }}
      defaultValues={{
        title: realEstateAssetData.title,
        product: realEstateAssetData.product,
        land_location: realEstateAssetData.land_location,
        coordinates: realEstateAssetData.coordinates,
        type_of_title: realEstateAssetData.type_of_title,
        plot_no: realEstateAssetData.plot_no,
        block_no: realEstateAssetData.block_no,
        size_of_land: realEstateAssetData.size_of_land,
        whats_on_land: realEstateAssetData.whats_on_land,
        specify_others: realEstateAssetData.specify_others,
        description: realEstateAssetData.description,
      }}
      schema={landSchema}
    >
      {({ register, formState, watch, setValue }) => (
        <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
          <div className="mb-4">Asset Details for real estate</div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="space-y-5">
              <InputFieldWithInfo
                label="Title of Asset *"
                error={formState.errors['title']}
                registration={register('title')}
                value={realEstateAssetData.title}
                caption="Enter a concise title for your asset. This will help us identify it easily and efficiently."
                onChange={(event) => {
                  formState.errors['title'] = '';
                  setValue('title', event.target.value);
                  const dt = { ...realEstateAssetData, title: event.target.value };
                  setRealEstateAssetData(dt);
                  storage.setAnyFormValues('realEstateAssetData', dt);
                }}
              />
              <ReactSelectField2
                label="Please select Product *"
                type="text"
                editorContent={watch('product')}
                name="product"
                defaultValue={realEstateAssetData.selectedProduct}
                error={formState.errors['product']}
                registration={register('product')}
                options={productsQuery.data?.map((p) => ({
                  label: p.product_title,
                  value: p._id,
                }))}
                onChange={async (e) => {
                  const selectedProduct = await productsQuery.data
                    ?.map((p) => ({
                      label: p.product_title,
                      value: p._id,
                    }))
                    ?.find((p) => p.value === e.value);
                  setValue('product', e.value);
                  formState.errors['product'] = '';
                  const dt = {
                    ...realEstateAssetData,
                    product: e.value,
                    selectedProduct: selectedProduct,
                  };
                  setRealEstateAssetData(dt);
                  storage.setAnyFormValues('realEstateAssetData', dt);
                }}
              />
              <LocationField
                label="Land Location *"
                // caption="Please drag the pin to the exact location of the land"
                onChange={handleLocationChange}
                // error={landLocationError}
              />

              {realEstateAssetData.land_location && (
                <div className="bg-gray-100 p-4 rounded-md">
                  <span className="font-bold text-sm">Your land Location</span>
                  <br />
                  <span>{realEstateAssetData.land_location}</span>
                </div>
              )}

              <ReactSelectField2
                label="Type of Title (Title Tenure) *"
                type="text"
                editorContent={watch('type_of_title')}
                name="type_of_title"
                defaultValue={realEstateAssetData.selectedtype_of_title}
                error={formState.errors['type_of_title']}
                registration={register('type_of_title')}
                options={defaults.type_of_title}
                onChange={async (e) => {
                  const selectedProduct = defaults.type_of_title?.find((p) => p.value === e.value);
                  setValue('type_of_title', e.value);
                  formState.errors['type_of_title'] = '';
                  const dt = {
                    ...realEstateAssetData,
                    type_of_title: e.value,
                    selectedtype_of_title: selectedProduct,
                  };
                  setRealEstateAssetData(dt);
                  storage.setAnyFormValues('realEstateAssetData', dt);
                }}
              />
              <ReactSelectField2
                label="Size of Land *"
                type="text"
                editorContent={watch('size_of_land')}
                name="size_of_land"
                defaultValue={realEstateAssetData.selectedsize_of_land}
                error={formState.errors['size_of_land']}
                registration={register('size_of_land')}
                options={defaults.size_of_land}
                onChange={async (e) => {
                  const selectedProduct = defaults.size_of_land?.find((p) => p.value === e.value);
                  setValue('size_of_land', e.value);
                  formState.errors['size_of_land'] = '';
                  const dt = {
                    ...realEstateAssetData,
                    size_of_land: e.value,
                    selectedsize_of_land: selectedProduct,
                  };
                  setRealEstateAssetData(dt);
                  storage.setAnyFormValues('realEstateAssetData', dt);
                }}
              />
              <ReactSelectField2
                label="Development (What's on the Land) *"
                type="text"
                editorContent={watch('whats_on_land')}
                name="whats_on_land"
                defaultValue={realEstateAssetData.selectedswhats_on_land}
                error={formState.errors['whats_on_land']}
                registration={register('whats_on_land')}
                options={defaults.whats_on_land}
                onChange={async (e) => {
                  const selectedProduct = defaults.whats_on_land?.find((p) => p.value === e.value);
                  setValue('whats_on_land', e.value);
                  formState.errors['whats_on_land'] = '';
                  const dt = {
                    ...realEstateAssetData,
                    whats_on_land: e.value,
                    selectedswhats_on_land: selectedProduct,
                  };
                  setRealEstateAssetData(dt);
                  storage.setAnyFormValues('realEstateAssetData', dt);
                }}
              />
              <InputFieldWithInfo
                label="Plot Number"
                error={formState.errors['plot_no']}
                registration={register('plot_no')}
                value={realEstateAssetData.plot_no}
                onChange={(event) => {
                  formState.errors['plot_no'] = '';
                  setValue('plot_no', event.target.value);
                  const dt = { ...realEstateAssetData, plot_no: event.target.value };
                  setRealEstateAssetData(dt);
                  storage.setAnyFormValues('realEstateAssetData', dt);
                }}
              />
              <InputFieldWithInfo
                label="Block Number"
                error={formState.errors['block_no']}
                registration={register('block_no')}
                value={realEstateAssetData.block_no}
                onChange={(event) => {
                  formState.errors['block_no'] = '';
                  setValue('block_no', event.target.value);
                  const dt = { ...realEstateAssetData, block_no: event.target.value };
                  console.log(dt);
                  setRealEstateAssetData(dt);
                  storage.setAnyFormValues('realEstateAssetData', dt);
                }}
              />
              {realEstateAssetData.whats_on_land === 'OTHERS' && (
                <InputFieldWithInfo
                  label="Specify Others"
                  error={formState.errors['specify_others']}
                  registration={register('specify_others')}
                  name="specify_others"
                  value={realEstateAssetData.specify_others}
                  onChange={(event) => {
                    formState.errors['specify_others'] = '';
                    setValue('specify_others', event.target.value);
                    const dt = {
                      ...realEstateAssetData,
                      specify_others: event.target.value,
                    };
                    setRealEstateAssetData(dt);
                    storage.setAnyFormValues('realEstateAssetData', dt);
                  }}
                />
              )}
            </div>
            <div className="space-y-5">
              <RegularTextAreaField
                label="Asset Description *"
                error={formState.errors['description']}
                registration={register('description')}
                caption="Describe your land in detail. Share any unique features, history, or potential uses. This information will help us understand your property better and tailor our services to meet your needs."
                name="description"
                value={realEstateAssetData.description}
                onChange={(e) => {
                  formState.errors['description'] = '';
                  setValue('description', e.target.value);
                  const dt = { ...realEstateAssetData, description: e.target.value };
                  setRealEstateAssetData(dt);
                  storage.setAnyFormValues('realEstateAssetData', dt);
                }}
              />
            </div>
          </div>
          <div className="flex flex-row space-x-2 justify-end mt-4">
            <Button type="submit">Next</Button>
          </div>
        </div>
      )}
    </Form>
  );
};

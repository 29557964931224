import { Button, Spinner } from 'components/Elements';
import { Form, InputFieldWithInfo, RegularTextAreaField } from 'components/Form';
import { ReactSelectField2 } from 'components/Pigeon';
import { computersSchema } from 'features/assets/schemas';
import { useProducts } from 'features/products';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import storage from 'utils/storage';
import { COLLATERAL } from 'variables';
import { defaults } from 'features/assets/data/defaults';

export const ComputerAssetDetailsForm = ({ onSuccess }) => {
  const [computerAssetData, setComputerAssetData] = useState({
    title: '',
    product: '',
    selectedProduct: '',
    brand: '',
    selectedBrand: '',
    specify_others: '',
    ram_size: '',
    hard_disk_type: 'HDD',
    selectedram_size: '',
    processor_speed: '',
    selectedprocessor_speed: '',
    hard_disk_space: '',
    selectedhard_disk_space: '',
    selectedhard_disk_type: 'HDD',
    description: '',
  });

  const productsQuery = useProducts();

  useEffect(() => {
    const getData = async () => {
      const assetCategory = localStorage.getItem('assetCategory');
      if (assetCategory) {
        if (assetCategory === COLLATERAL.CATEGORIES.COMPUTERS) {
          const result = storage.getOtherValues('computerAssetData');
          setComputerAssetData(result);
        }
      }
    };
    getData();
  }, []);

  if (productsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="md" />
      </div>
    );
  }

  if (!productsQuery?.data) return null;

  return (
    <Form
      id="create-post"
      onSubmit={async (values) => {
        onSuccess();
      }}
      defaultValues={{
        title: computerAssetData.title,
        product: computerAssetData.product,
        brand: computerAssetData.brand,
        specify_others: computerAssetData.specify_others,
        ram_size: computerAssetData.ram_size,

        processor_speed: computerAssetData.processor_speed,
        hard_disk_space: computerAssetData.hard_disk_space,
        description: computerAssetData.description,
      }}
      schema={computersSchema}
    >
      {({ register, formState, watch, setValue }) => (
        <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
          <div className="mb-4">Asset Details for Computer</div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="space-y-5">
              <InputFieldWithInfo
                label="Title of Asset"
                error={formState.errors['title']}
                registration={register('title')}
                caption="Enter a concise title for your asset. This will help us identify it easily and efficiently."
                onChange={(e) => {
                  formState.errors['title'] = '';
                  setValue('title', e.target.value);
                  // console.log('title data', { ...vehicleAssetData, title: e.target.value })
                  const dt = { ...computerAssetData, title: e.target.value };
                  setComputerAssetData(dt);
                  storage.setAnyFormValues('computerAssetData', dt);
                }}
              />
              <ReactSelectField2
                label="Please select Product"
                type="text"
                editorContent={watch('product')}
                name="product"
                defaultValue={computerAssetData.selectedProduct}
                error={formState.errors['product']}
                registration={register('product')}
                options={productsQuery.data?.map((p) => ({
                  label: p.product_title,
                  value: p._id,
                }))}
                onChange={async (e) => {
                  const selectedProduct = await productsQuery.data
                    ?.map((p) => ({
                      label: p.product_title,
                      value: p._id,
                    }))
                    ?.find((p) => p.value === e.value);
                  setValue('product', e.value);
                  formState.errors['product'] = '';
                  setComputerAssetData({
                    ...computerAssetData,
                    product: e.value,
                    selectedProduct: selectedProduct,
                  });
                  storage.setAnyFormValues('computerAssetData', {
                    product: e.value,
                    selectedProduct: selectedProduct,
                  });
                }}
              />
              <ReactSelectField2
                label="Select Computer Brand"
                type="text"
                editorContent={watch('brand')}
                name="brand"
                defaultValue={computerAssetData.selectedBrand}
                error={formState.errors['brand']}
                registration={register('brand')}
                options={defaults.computerBrand}
                onChange={async (e) => {
                  const selectedProduct = defaults.computerBrand.find((p) => p.value === e.value);
                  setValue('brand', e.value);
                  formState.errors['brand'] = '';
                  setComputerAssetData({
                    ...computerAssetData,
                    brand: e.value,
                    selectedBrand: selectedProduct,
                  });
                  storage.setAnyFormValues('computerAssetData', {
                    brand: e.value,
                    selectedBrand: selectedProduct,
                  });
                }}
              />
              {computerAssetData.brand === 'OTHER' && (
                <InputFieldWithInfo
                  label="Specify Other Brand"
                  error={formState.errors['specify_others']}
                  registration={register('specify_others')}
                  caption="Specify a brand in case you can't find yours in the list"
                  onChange={(e) => {
                    formState.errors['specify_others'] = '';
                    setValue('specify_others', e.target.value);
                    // console.log('specify_others data', { ...vehicleAssetData, specify_others: e.target.value })
                    const dt = {
                      ...computerAssetData,
                      specify_others: e.target.value,
                    };
                    setComputerAssetData(dt);
                    storage.setAnyFormValues('computerAssetData', dt);
                  }}
                />
              )}
              <ReactSelectField2
                label="RAM Size"
                type="text"
                editorContent={watch('ram_size')}
                name="ram_size"
                defaultValue={computerAssetData.selectedram_size}
                error={formState.errors['ram_size']}
                registration={register('ram_size')}
                options={defaults.RAMSize}
                onChange={async (e) => {
                  const selectedProduct = defaults.RAMSize.find((p) => p.value === e.value);
                  setValue('ram_size', e.value);
                  formState.errors['ram_size'] = '';
                  setComputerAssetData({
                    ...computerAssetData,
                    ram_size: e.value,
                    selectedram_size: selectedProduct,
                  });
                  storage.setAnyFormValues('computerAssetData', {
                    ram_size: e.value,
                    selectedram_size: selectedProduct,
                  });
                }}
              />
              <ReactSelectField2
                label="Select Processor Speed"
                type="text"
                editorContent={watch('processor_speed')}
                name="processor_speed"
                defaultValue={computerAssetData.selectedprocessor_speed}
                error={formState.errors['processor_speed']}
                registration={register('processor_speed')}
                options={defaults.computerProcessor}
                onChange={async (e) => {
                  const selectedProduct = defaults.computerProcessor.find(
                    (p) => p.value === e.value
                  );
                  setValue('processor_speed', e.value);
                  formState.errors['processor_speed'] = '';
                  setComputerAssetData({
                    ...computerAssetData,
                    processor_speed: e.value,
                    selectedprocessor_speed: selectedProduct,
                  });
                  storage.setAnyFormValues('computerAssetData', {
                    processor_speed: e.value,
                    selectedprocessor_speed: selectedProduct,
                  });
                }}
              />
              <ReactSelectField2
                label="Hard Disk Space"
                type="text"
                editorContent={watch('hard_disk_space')}
                name="hard_disk_space"
                defaultValue={computerAssetData.selectedhard_disk_space}
                error={formState.errors['hard_disk_space']}
                registration={register('hard_disk_space')}
                options={defaults.computerHarddisk}
                onChange={async (e) => {
                  const selectedProduct = defaults.computerHarddisk.find(
                    (p) => p.value === e.value
                  );
                  setValue('hard_disk_space', e.value);
                  formState.errors['hard_disk_space'] = '';
                  setComputerAssetData({
                    ...computerAssetData,
                    hard_disk_space: e.value,
                    selectedhard_disk_space: selectedProduct,
                  });
                  storage.setAnyFormValues('computerAssetData', {
                    hard_disk_space: e.value,
                    selectedhard_disk_space: selectedProduct,
                  });
                }}
              />
              <ReactSelectField2
                label="Hard Disk Type"
                type="text"
                editorContent={watch('hard_disk_type')}
                name="hard_disk_type"
                defaultValue={computerAssetData.selectedhard_disk_type}
                error={formState.errors['hard_disk_type']}
                registration={register('hard_disk_type')}
                options={defaults.computerHardDiskType}
                onChange={async (e) => {
                  const selectedProduct = defaults.computerHardDiskType.find(
                    (p) => p.value === e.value
                  );
                  setValue('hard_disk_type', e.value);
                  formState.errors['hard_disk_type'] = '';
                  setComputerAssetData({
                    ...computerAssetData,
                    hard_disk_type: e.value,
                    selectedhard_disk_type: selectedProduct,
                  });
                  storage.setAnyFormValues('computerAssetData', {
                    hard_disk_type: e.value,
                    selectedhard_disk_type: selectedProduct,
                  });
                }}
              />
            </div>
            <div className="space-y-5">
              <RegularTextAreaField
                label="Asset Description"
                error={formState.errors['description']}
                registration={register('description')}
                caption="Any extra information you’d like us to know about your computer. This will
                guide us on serving you better."
                value={computerAssetData.description}
                onChange={(e) => {
                  formState.errors['description'] = '';
                  setValue('description', e.target.value);
                  setComputerAssetData({
                    ...computerAssetData,
                    description: e.target.value,
                  });
                  storage.setAnyFormValues('computerAssetData', {
                    description: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex flex-row space-x-2 justify-end mt-4">
            <Button type="submit">Next</Button>
          </div>
        </div>
      )}
    </Form>
  );
};

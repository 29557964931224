import React, { useEffect, useState } from 'react';
import * as z from 'zod';
import { SelectPaymentMethod } from '../components/steps/SelectPaymentMethod';
import { LoanDetails } from '../components/steps/LoanDetails';
import { Guarantor } from '../components/steps/Guarantor';
import { Collateral } from '../components/steps/Collateral';
import { TermsAndConditions } from '../components/steps/TermsAndConditions';
import { Signature } from '../components/steps/Signature';
import { useAuth } from 'lib/auth';
import { Form, InputField, UploadField } from 'components/Form';
import { useUploadFiles } from '../api/uploadFiles';
import { Button, Spinner } from 'components/Elements';
import Swal from 'sweetalert2';
import storage from 'utils/storage';
import { useNavigate } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import logo from 'assets/logo.svg';
import { useMyLoan } from 'features/myLoan/api/getMyLoan';
import { VideoCall } from '../components/steps/VideoCall';

const schema = z.object({
  avatar: z.string({
    required_error: 'Selfie is required',
    invalid_type_error: 'Selfie must be a string',
  }),
  pigeonLoansNationalIDFront: z.string({
    required_error: 'National ID Front is required',
    invalid_type_error: 'National ID Front must be a string',
  }),
  pigeonLoansNationalIDBack: z.string({
    required_error: 'National ID Back is required',
    invalid_type_error: 'National ID Back must be a string',
  }),
});
export const PigeonLoans = () => {
  const [step, setStep] = useState(1);
  const [avatar, setAvatar] = useState();
  const [preview, setPreview] = useState();
  const [nationalIDBack, setNationalIDBack] = useState();
  const [nationalIDFront, setNationalIDFront] = useState();
  const [canProceed, setCanProceed] = useState(false);
  const [work, setWork] = useState('');

  const { user, isLoading } = useAuth();

  const uploadFilesMutation = useUploadFiles();

  const myLoanQuery = useMyLoan();

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!user) {
      return navigate('/auth/login');
    }
    storage.clearRedirect();
    const getData = async () => {
      const currentLoanStep = localStorage.getItem('pigeonLoansCurrentStep');
      if (currentLoanStep) {
        console.log('current loan step', currentLoanStep);
        if (currentLoanStep !== '1') {
          setStep(parseInt(currentLoanStep));
        }
      }
    };
    getData();
    switch (step) {
      case 1:
        setCanProceed(!!avatar && !!nationalIDFront && !!nationalIDBack); // Example condition for step 1
        break;
      // Add conditions for other steps as needed
      default:
        setCanProceed(true);
    }
  }, [step, avatar, nationalIDFront, nationalIDBack]);

  const goToStep = (stepNumber) => {
    if (stepNumber < step || canProceed) {
      setStep(stepNumber);
    }
  };

  if (isLoading || myLoanQuery.isLoading) {
    return (
      <div className="flex items-center justify-center w-screen h-screen">
        <Spinner size="xl" />
      </div>
    );
  }

  if (myLoanQuery.data && myLoanQuery.data?.status) {
    Swal.fire({
      icon: 'warning',
      title: 'Finablr Loans',
      text: 'Seems like you already have a loan application in progess! Go to my loan on your dashboard to check out the progress.',
    });
    return navigate('/app');
  }

  const steps = [
    'Payment Method',
    'Loan Details',
    'Guarantor',
    'Collateral Assets',
    'Introductory Video Call',
    'Terms and Conditions',
    'Signature',
  ];

  return (
    <div className="">
      <div className="bg-gray-100 rounded-full mx-8 md:mx-32 mt-8 flex flex-row items-center justify-between">
        <div className="mx-4 flex flex-row items-center space-x-2">
          <img className="h-8" src={logo} alt="Workflow" />
          <div className="hidden md:block text-md md:text-xl font-semibold ml-4">
            Secure Your Loan Today
          </div>
          <div className="md:hidden text-md md:text-xl font-semibold ml-4">Secure Your Loan</div>
        </div>
        <Button
          variant="dark"
          onClick={() => {
            Swal.fire({
              title: 'Are you sure you want to close?',
              text: "If you close this window you'll lose your progress with your loan application.",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#FEB11B',
              cancelButtonColor: '#181818',
              confirmButtonText: 'Continue',
            }).then((result) => {
              if (result.isConfirmed) {
                storage.clearRedirect();
                navigate('/app');
              }
            });
          }}
          startIcon={<FaHome />}
        >
          Home
        </Button>
      </div>
      <div className="bg-white rounded-bl-2xl p-4 md:px-80">
        <>
          <div className="flex justify-center space-x-4 mb-4">
            {steps.map((label, index) => (
              <div key={index} className="flex flex-col items-center space-y-2">
                <button
                  type="button"
                  className={`flex items-center justify-center h-8 w-8 rounded-full ${
                    step === index + 1 ? 'bg-primary-100 text-white' : 'bg-gray-300 text-gray-500'
                  }`}
                  onClick={() => {
                    const nextStep = index + 1;

                    // Show the Swal alert if the user is clicking the first step and they are not currently on it
                    if (nextStep === 1 && step !== 1) {
                      Swal.fire({
                        title: 'Are you sure?',
                        text: "If you change the payment method, you'll lose your current progress!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, change it!',
                      }).then((result) => {
                        if (result.isConfirmed) {
                          // Proceed to reset the form and move to step 1
                          localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                          setStep(nextStep);
                        }
                      });
                    } else if (canProceed || nextStep < step) {
                      // Normal behavior for other steps
                      localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                      setStep(nextStep);
                    }
                  }}
                  disabled={!canProceed && index + 1 > step}
                >
                  {index + 1}
                </button>
                <div className="whitespace-nowrap text-xs hidden md:block">{label}</div>
              </div>
            ))}
          </div>
          {step === 1 && (
            <SelectPaymentMethod
              nextStep={() => {
                const nextStep = step + 1;
                localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                setStep(nextStep);
              }}
            />
          )}
          {step === 2 && (
            <LoanDetails
              nextStep={() => {
                const nextStep = step + 1;
                localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                setStep(nextStep);
              }}
              prevStep={() => {
                const nextStep = step - 1;
                localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                setStep(nextStep);
              }}
            />
          )}
          {step === 3 && (
            <Guarantor
              nextStep={() => {
                const nextStep = step + 1;
                localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                setStep(nextStep);
              }}
              prevStep={() => {
                const nextStep = step - 1;
                localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                setStep(nextStep);
              }}
            />
          )}

          {step === 4 && (
            <Collateral
              nextStep={() => {
                const nextStep = step + 1;
                localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                setStep(nextStep);
              }}
              prevStep={() => {
                const nextStep = step - 1;
                localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                setStep(nextStep);
              }}
            />
          )}
          {step === 5 && (
            <VideoCall
              nextStep={() => {
                const nextStep = step + 1;
                localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                setStep(nextStep);
              }}
              prevStep={() => {
                const nextStep = step - 1;
                localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                setStep(nextStep);
              }}
            />
          )}
          {step === 6 && (
            <TermsAndConditions
              nextStep={() => {
                const nextStep = step + 1;
                localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                setStep(nextStep);
              }}
              prevStep={() => {
                const nextStep = step - 1;
                localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                setStep(nextStep);
              }}
            />
          )}
          {step === 7 && (
            <Signature
              prevStep={() => {
                const nextStep = step - 1;
                localStorage.setItem('pigeonLoansCurrentStep', `${nextStep}`);
                setStep(nextStep);
              }}
            />
          )}
        </>
        {/* {!user.basicInformation?.avatar || !user.documentVerification?.nationalID?.front ? (
         
        ) : (
        )} */}
      </div>
    </div>
  );
};

import PropTypes from 'prop-types';
import * as React from 'react';

import { Head } from '../Head';

export const ContentLayout = ({ children, title, subtitle, end }) => {
  return (
    <>
      <Head title={title} />
      <div className="py-6">
        {title && (
          <div
            className={`max-w-7xl mx-auto px-4 sm:px-6 md:px-8 flex md:flex-row items-center ${
              end ? 'justify-between' : 'justify-center'
            }`}
          >
            <div className="text-start w-full text-gray-900 ">
              <div className="text-md md:text-xl font-semibold ">{title}</div>
              <div className="text-xs md:text-sm">{subtitle}</div>
            </div>
            <div>{end}</div>
          </div>
        )}
        <div className="max-w-7xl mx-auto px-4 mt-4">{children}</div>
      </div>
    </>
  );
};

ContentLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  end: PropTypes.node,
};

import { Button, Dialog, DialogDescription, DialogTitle, Spinner } from 'components/Elements';
import React, { useState } from 'react';
import PigeonLogo from 'assets/pigeon.svg';
import EflLogo from 'assets/efl.png';
import { useNavigate } from 'react-router-dom';
import { useMyAssets } from 'features/assets';
import BorrowMoneyImg from 'assets/borrow-money.svg';
import { useMyLoan } from 'features/lendMoney';
import Swal from 'sweetalert2';
import { useAuth } from 'lib/auth';
import { UpdateAvatarIcon } from 'features/users';
import { getNextPayment } from 'utils/getNextPayment';

export function OpenPigeon() {
  const [show, setShow] = React.useState(false);
  const [showAssetsModel, setShowAssetsModel] = React.useState(false);
  const [updateFiles, setUpdateFiles] = React.useState(false);
  const myLoanQuery = useMyLoan();

  const { user } = useAuth();

  const assetsQuery = useMyAssets();
  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
    setShowAssetsModel(false);
  };

  const nextPayment = getNextPayment(myLoanQuery?.data?.payments);

  const handleOpen = () => {
    if (myLoanQuery.data?.amount?.loanRequired && nextPayment?.status !== 'COMPLETE') {
      Swal.fire({
        title: 'Oops!',
        text: 'You already have a loan application in progress. Please wait for the current application to be processed before applying for a new loan.',
        icon: 'info',
        confirmButtonText: 'Ok',
      });
    } else {
      navigate('/app/pigeon-loans');
    }
  };

  if (assetsQuery.isLoading) {
    return <></>;
  }

  if (myLoanQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!assetsQuery.data) return null;

  return (
    <div>
      <UpdateAvatarIcon requireUpdate={updateFiles} />
      <button
        onClick={handleOpen}
        disabled={user.status === 'ACTIVE' ? false : true}
        className={`flex flex-col items-center bg-white justify-center space-y-1 rounded-2xl shadow-lg p-12 w-full`}
      >
        <img
          src={BorrowMoneyImg}
          alt="borrow money"
          className={`h-16 mb-3 ${user.status === 'ACTIVE' ? `` : `grayscale`}`}
        />
        <div
          className={`${
            user.status === 'ACTIVE' ? `text-primary-100` : `text-gray-300`
          } text-xl font-bold `}
        >
          Borrow Money
        </div>
        <div className={`text-center ${user.status === 'ACTIVE' ? `` : `text-gray-300`}`}>
          Create a loan request with a few steps.
        </div>
      </button>
      <Dialog isOpen={show} onClose={handleClose} initialFocus={null}>
        <div className="inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <DialogTitle className="text-xl text-center font-bold mb-4">
            New Loan Application
          </DialogTitle>
          <>
            <div className="">
              You are now being redirected to Pigeon Loans Gateway which simplifies your loan
              application process.
            </div>
            <div className="mx-auto">
              <div className="flex flex-row items-center space-x-12 my-6">
                <img src={PigeonLogo} alt="Pigeon Loans" className="h-8 md:h-12" />
                <img src={EflLogo} alt="EFL" className="h-8 md:h-12" />
              </div>
            </div>
          </>
          <div className="flex flex-row items-center space-x-2">
            <button onClick={handleClose} className="mt-4 p-2 px-4 bg-gray-100 w-full rounded-2xl">
              Cancel
            </button>
            <button
              onClick={() => navigate('/app/pigeon-loans')}
              className="mt-4 p-2 px-4 w-full rounded-2xl text-white bg-primary-100"
            >
              Proceed
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog isOpen={showAssetsModel} onClose={handleClose} initialFocus={null}>
        <div className="inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <DialogTitle className="text-xl text-center font-bold mb-4">
            Collateral Assets Required
          </DialogTitle>
          <>
            <div className="">
              You are required to add some collateral assets before applying for a loan.
            </div>
          </>
          <div className="flex flex-row items-center space-x-2">
            <button onClick={handleClose} className="mt-4 p-2 px-4 bg-gray-100 w-full rounded-2xl">
              Cancel
            </button>
            <button
              onClick={() => navigate('/app/assets/create')}
              className="mt-4 p-2 px-4 bg-gray-100 w-full rounded-2xl text-white bg-primary-100"
            >
              Proceed
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

import { Link } from 'components/Elements';
import { Head } from 'components/Head';
import PropTypes from 'prop-types';
import OverLay from 'assets/layout/overlay.svg';
import LendMoneyImg from 'assets/lend_money.png';
import MyPortfolioImg from 'assets/my_portfolio.png';
import BorrowMoneyImg from 'assets/borrow_money.png';
import logo from 'assets/logo.svg';

export const Layout = ({ children, title }) => {
  return (
    <>
      <Head title={title} />
      <div className="relative min-h-screen flex flex-col sm:flex-row">
        {/* Background Animation */}
        <div className="fixed inset-0 z-0 bg-gradient animate-gradientMotion"></div>
        <div className="absolute inset-0 z-0">
          <div className="motion-objects"></div>
        </div>

        {/* Left Panel */}
        <div className="fixed z-10 sm:w-2/4 h-screen hidden sm:block rounded-rb-2xl mx-12 px-20 py-12">
          <img src={OverLay} alt="Finablr" className="h-64" />
          <div className="mb-4 text-2xl font-semibold text-gray-700">
            Join the largest Network of borrowers and lenders in Uganda today!
          </div>
          <div className="mb-8">
            <div className="flex flex-col items-start">
              <img src={BorrowMoneyImg} alt="Borrow Money" className="h-8 w-8" />
              <div className="text-sm font-bold">Borrow Money</div>
            </div>
            <div className="text-sm">
              Make loan requests in less than 5 minutes, and wait for lenders to make offers.
            </div>
          </div>
          <div className="mb-8">
            <div className="flex flex-col items-start">
              <img src={LendMoneyImg} alt="Borrow Money" className="h-8 w-8" />
              <div className="text-sm font-bold">Lend Money</div>
            </div>
            <div className="text-sm">
              Earn your set interest on loan returns. Create a lenders profile and start making loan
              offers.
            </div>
          </div>
          <div className="mb-8">
            <div className="flex flex-col items-start">
              <img src={MyPortfolioImg} alt="Borrow Money" className="h-8 w-8" />
              <div className="text-sm font-bold">My Loan</div>
            </div>
            <div className="text-sm">
              Check out your loan current status. Keep up with your loan progress with my loan.
            </div>
          </div>
        </div>

        {/* Right Panel */}
        <div className="relative z-20 md:absolute md:right-8 flex flex-col justify-center py-4 sm:px-6 lg:px-8 md:mr-8">
          <div className=" sm:mx-auto sm:w-full sm:max-w-md">
            <div className="sm:mx-auto sm:w-full sm:max-w-lg mb-2">
              <div className="flex justify-center md:mx-8">
                <Link className="flex items-center text-white" to="/">
                  <img className="h-16 w-auto" src={logo} alt="finablr" />
                </Link>
              </div>
              <h2 className="mt-3 text-center text-3xl md:mx-8 font-semibold text-gray-700">
                {title}
              </h2>
            </div>
            <div className="bg-white mx-4 md:mx-0 shadow py-8 px-4 md:shadow-lg border border-gray-200 mb-3 rounded-none sm:rounded-2xl sm:px-10">
              <div className="">{children}</div>
            </div>

            <div className="bg-white p-8 shadow md:shadow-lg border border-gray-200 rounded-none sm:rounded-2xl mb-3">
              <div className="font-semibold text-md text-primary-100">SECURITY ALERT</div>

              <div className="text-xs mb-4 text-gray-500">
                Finablr will never send emails requesting confidential information such as your
                account login details, access codes, or PIN codes. If you receive any email asking
                for this kind of personal information, consider it suspicious. In case of doubt,
                please contact us immediately through our customer support at{' '}
                <span className="text-primary-100 font-semibold">operations@finablr.app </span>
                or call us at{' '}
                <span className="text-primary-100 font-semibold">+256 706 400 057</span>
              </div>

              <div className="text-xs mb-4 text-gray-500">
                Access to Finablr's platform is restricted to users who have successfully completed
                the registration and verification process.
              </div>

              <div className="text-xs text-gray-500">
                Stay safe and protect your financial data.
              </div>
            </div>

            <div className="bg-white text-xs p-4 text-gray-600 shadow md:shadow-lg border border-gray-200 rounded-none sm:rounded-xl">
              Finablr © 2024 - A product of Enabling Finance Limited -{' '}
              {/* <a href="" className="text-primary-100 hover:text-secondary-100">
                Financial Institutions (Amendment) ACT, 2016
              </a> */}
              <Link className="text-primary-100 hover:text-secondary-100" to="/general-policy">
                General Policy
              </Link>{' '}
              -{' '}
              <Link
                className="text-primary-100 hover:text-secondary-100"
                to="/terms-and-conditions"
              >
                Terms and Conditions
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Styles */}
      <style jsx>{`
        /* Gradient Background */
        .bg-gradient {
          background: linear-gradient(135deg, #fffaf3 30%, #f6fdff 70%);
          animation: gradientMotion 5s ease-in-out infinite alternate;
        }

        @keyframes gradientMotion {
          0% {
            background-position: 0% 50%;
          }
          100% {
            background-position: 100% 50%;
          }
        }

        /* Motion Objects */
        .motion-objects {
          position: absolute;
          width: 100%;
          height: 100%;
          overflow: hidden;
        }

        .motion-objects::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: repeating-linear-gradient(
              0deg,
              transparent,
              transparent 5px,
              rgba(255, 255, 255, 0.1) 6px
            ),
            repeating-linear-gradient(
              90deg,
              transparent,
              transparent 5px,
              rgba(255, 255, 255, 0.1) 6px
            );
          animation: motionEffect 15s linear infinite;
        }

        @keyframes motionEffect {
          0% {
            transform: translate(0, 0);
          }
          100% {
            transform: translate(50px, 50px);
          }
        }
      `}</style>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

import { Button } from 'components/Elements';
import React from 'react';
import { MdClose } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

export const ServiceNotice = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="bg-secondary-100 p-3 flex flex-row justify-between md:px-12">
        <div className="text-white font-semibold text-md md:text-xl">Service Notification</div>
        <button>
          <MdClose className="text-white" height={8} />
        </button>
      </div>
      <div className="p-3 border-b border-gray-200">
        Please read the information below carefully.
      </div>
      <div className="p-3">
        <div className="font-bold mb-3">ANTI-FRAUD TIP</div>
        <div className="text-sm">
          Please note that all deposits must be made to the account below. If you're contacted by
          any person claiming that you make your loan deposit to a different account, IMMEDIATELY
          call us on <span className="text-primary-100">+256706400057</span> to confirm any
          deposits.
        </div>
      </div>
      <div className="p-3">
        <Button onClick={() => navigate('/app')} variant="secondary" className="w-full md:w-fit">
          Close
        </Button>
      </div>
    </div>
  );
};

import { Button, Spinner } from 'components/Elements';
import { Notifications } from 'components/Notifications/Notifications';
import { AuthProvider } from 'lib/auth';
import { queryClient } from 'lib/react-query';
import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import BrokenLampLight from 'assets/broken_lamplight.svg';
import { useEffect } from 'react';

const ErrorFallback = () => {
  const handleReportIssue = () => {
    const subject = encodeURIComponent('Issue Report');
    const body = encodeURIComponent(
      `Hello Jonah,\n\nI would like to report the following issue:\n\n[Provide details about the issue here]\n\nBest regards,\n[Your Name]`
    );
    const email = 'jonahgeek@gmail.com';

    window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
  };

  return (
    <div className="px-4 w-screen h-screen flex flex-col justify-center items-center" role="alert">
      <img src={BrokenLampLight} alt="broken lamp" className="w-40 h-40 mb-4" />
      <h2 className="text-xl md:text-2xl font-semibold mb-1">Sorry, something went wrong </h2>
      <div className="text-sm md:text-md mb-3">Please try again or report an issue to support</div>
      <div className="flex flex-row items-center justify-center space-x-4">
        <Button
          onClick={() => {
            localStorage.clear();
            window.location.assign(window.location.origin);
          }}
        >
          Try again
        </Button>
        <Button variant="outline" onClick={handleReportIssue}>
          Report an issue
        </Button>
      </div>
    </div>
  );
};

export const AppProvider = ({ children }) => {
  // if (process.env.REACT_APP_NODE_ENV === 'production') {
  //   console.log = function () {};
  //   console.warn = function () {};
  //   console.error = function () {};
  // }

  // useEffect(() => {
  //   const preventRightClick = (e) => {
  //     e.preventDefault();
  //   };

  //   // Disable right-click
  //   window.addEventListener('contextmenu', preventRightClick);

  //   return () => {
  //     window.removeEventListener('contextmenu', preventRightClick);
  //   };
  // }, []);

  // useEffect(() => {
  //   const blockDevToolsShortcuts = (e) => {
  //     if (e.key === 'F12' || (e.ctrlKey && e.shiftKey && e.key === 'I')) {
  //       e.preventDefault();
  //       Swal.fire({
  //         title: 'Oops',
  //         text: 'Sorry, developer tools are disabled by system admin.',
  //         timer: 4000,
  //       });
  //     }
  //   };

  //   window.addEventListener('keydown', blockDevToolsShortcuts);

  //   return () => {
  //     window.removeEventListener('keydown', blockDevToolsShortcuts);
  //   };
  // }, []);

  return (
    <React.Suspense
      fallback={
        <div className="flex items-center justify-center w-screen h-screen">
          <Spinner size="xl" />
        </div>
      }
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools />}
            <Notifications />
            <AuthProvider>
              <Router>{children}</Router>
            </AuthProvider>
          </QueryClientProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
};

import { Navigate, Route, Routes } from 'react-router-dom';

import { PigeonLoans } from './PigeonLoans';
import { Success } from './Success';
import { KYCRequirements } from './KYCRequirements';

export const PigeonLoansRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<PigeonLoans />} />
      <Route path="success" element={<Success />} />
      <Route path="kyc-info-required" element={<KYCRequirements />} />

      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import GoogleAuthImg from 'assets/Google_Authenticator.png';
import Swal from 'sweetalert2';
import { loginWithGoogleAuth, useAuth } from 'lib/auth';

export const GoogleRedirect = () => {
  const { isLoading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const handleGoogleInAuth = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');

      if (!code) {
        Swal.fire({
          title: 'Oops',
          text: 'Google authentication code is required.',
        });
        console.error('No Google code found in the URL.');
        navigate('/auth/login');
        return;
      }

      try {
        const data = { code };
        await loginWithGoogleAuth(data);
        console.log('Login successful. Redirecting to /app...');
        window.location.href = '/app';
      } catch (err) {
        console.error('Login error:', err);
        Swal.fire({
          title: 'Oops',
          text: err.message || 'An error occurred during login.',
        });
        navigate('/auth/login');
      }
    };

    handleGoogleInAuth();
  }, [loginWithGoogleAuth, navigate]);

  return (
    <div className="p-4">
      <img src={GoogleAuthImg} alt="" className="h-8" />
      <p className="text-md">{isLoading ? 'Signing in with Google...' : 'Redirecting...'}</p>
    </div>
  );
};
